import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AppModule } from 'app/core';
import { CommonService } from '@services';
import { KeenSliderOptions } from 'keen-slider';
import { SliderDirective } from '@directives';
import { RouterModule } from '@angular/router';
import { CourseRoute } from '@types';
import { ImmersionsService } from '@features/immersions/services';

@Component({
  selector: 'cfa-carousel-courses',
  standalone: true,
  imports: [
    AppModule,
    RouterModule,
    SliderDirective,
  ],
  providers: [
    CommonService,
  ],
  templateUrl: './carousel-courses.component.html',
  styleUrl: './carousel-courses.component.scss'
})
export class CarouselCoursesComponent implements OnInit {

  @Input() config_carousel?: []

  public course_list: CourseRoute[] = [];

  constructor(
    private CommonService: CommonService,
    private ImmersionsService: ImmersionsService
  ) { }

  public getSliderConfig(): KeenSliderOptions {
    if (this.config_carousel) {
      return this.config_carousel;
    } else {
      return {
        mode: "free-snap",
        breakpoints: {
          "(max-width: 1200px)": {
            slides: {
              perView: 2,
              spacing: 24
            },
          },
          "(max-width: 768px)": {
            slides: {
              perView: 1,
              spacing: 24
            },
          },
          "(min-width: 1200px)": {
            slides: {
              perView: 3,
              spacing: 24
            },
          }
        }
      }
    }
  }

  ngOnInit(): void {
    this.course_list = this.ImmersionsService.course_list
  }

}