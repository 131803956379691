
import { AfterViewInit, Component, ViewChild, TemplateRef, inject } from '@angular/core';
import { CommonService, HeaderService } from '@services';
import { AppModule } from '@core'
import { Router, RouterModule } from '@angular/router';
import { Menu } from '@types';
import { BtnLinkComponent, BtnComponent } from "@components";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-header',
  standalone: true,
  providers: [CommonService],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  imports: [
    BtnComponent,
    AppModule,
    RouterModule,
    BtnLinkComponent
  ]
})

export class HeaderComponent implements AfterViewInit {

  @ViewChild('modalMobile') __modalMobile!: TemplateRef<any>;
  private modalService = inject(NgbModal);


  public scroll_op_header: number = 120;
  public scroll_position: number = 0;
  public linksMenu: Menu[] = [];
  public linkModal: Menu[] = [];
  public mobile_links: Menu[] = [];
  public dropdown_mobile: boolean = false;
  public url_match: string = '/';

  closeResult = '';

  constructor(
    private CommonService: CommonService,
    private HeaderService: HeaderService,
    private router: Router
  ) { }

  private getLinks() {
    this.linksMenu = this.HeaderService.menu
  }

  public goTop(nameLink: string) {
    if (nameLink === 'Home') {
      window?.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      })
    }
  }

  public openModalLinks(template: TemplateRef<any>, link: any): void {
    this.linkModal = link.dropLinks;
    this.modalService.open(template, { backdrop: true, centered: false, size: 'md' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  public openModalMobile(template: TemplateRef<any>): void {
    this.modalService.open(template, { backdrop: true, centered: false, size: 'md' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    )
  }

  private getDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        return 'by pressing ESC';
      case ModalDismissReasons.BACKDROP_CLICK:
        return 'by clicking on a backdrop';
      default:
        return `with: ${reason}`;
    }
  }

  public dropLinksMobile(link: any) {
    this.mobile_links = link;
    this.dropdown_mobile = !this.dropdown_mobile;
  }

  private getUrl(url: any) {
    this.url_match = url[1] === '#' ? '/' : url;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const MAIN = document.querySelector('.main-container')
      MAIN?.addEventListener('scroll', () => {
        this.scroll_position = MAIN.scrollTop;
      })
    }, 0)
  }

  ngOnInit() {
    this.getLinks();
    const rotaAtual = this.router.routerState.snapshot.url;
    this.getUrl(rotaAtual);
  }

}