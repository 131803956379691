<div class="faq-card border-gradient border-tertiary-primary bg-main bg-opacity-40 rounded-3 " [ngClass]="{'open':!faq.collapsed}" (click)="faq.collapsed = !faq.collapsed">
  <div class="d-flex justify-content-between gap-2 p-4">
    <div class="text-container flex-grow-1">
      <div class="question">
        <div class="fs-7 fs-md-6 text-opacity-75" [innerHTML]="faq.question"></div>
      </div>
      <div class="answer pt-3">
        <div class="fs-7 fs-md-6" >{{faq.answer}}</div>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-start w-30px">
        <i class="icon text-secondary-primary-bright fa-solid fa-chevron-down"></i>
    </div>
  </div>
</div>