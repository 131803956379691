<div class="border-gradient border-secondary-bt-primary-bt rounded-3">
  <div class="bg-white-blur p-3 p-lg-4 rounded-3">
    <div class="d-flex justify-content-center align-items-center gap-2 fs-sm-5 fs-lg-2">
      <div class="text-center"><span class="text-nowrap">> Seja ele, </span>
        <span class="text-tertiary-to-primary-bt fw-500">{{letters}}
          <span class="letter-bar bg-tertiary-to-primary-bt"></span>
        </span>
      </div>
    </div>
  </div>
</div>