import { Component } from '@angular/core';

@Component({
  selector: 'feature-valeria-baptista',
  standalone: true,
  imports: [],
  templateUrl: './valeria-baptista.component.html',
  styleUrl: './valeria-baptista.component.scss'
})
export class ValeriaBaptistaComponent {

}