import { CommonService } from '@services';
import { Component, TemplateRef } from '@angular/core';
import { AppModule } from '@core';
import { BtnComponent } from "@components";
import { KeenSliderOptions } from 'keen-slider';
import { SliderDirective } from '@directives';

@Component({
  selector: 'feature-class',
  standalone: true,
  imports: [
    BtnComponent,
    AppModule,
    SliderDirective
  ],
  providers: [
    CommonService
  ],
  templateUrl: './class.component.html',
  styleUrl: './class.component.scss'
})
export class ClassComponent {

  public player_link?: string;

  public depoimments = [
    {
      name: "Diego Nardoni",
      link: "https://www.youtube.com/embed/LonWlRyu9CU",
      image: "assets/images/class/diego-nardoni.png",
    },
    {
      name: "Ludne de Morais",
      link: "https://www.youtube.com/embed/8SwoqcJBwDI",
      image: "assets/images/class/ludne-de-morais-machado.png",
    },
    {
      name: "João Victor Krumpos",
      link: "https://www.youtube.com/embed/1uJ4kQo3thA",
      image: "assets/images/class/joao-victor-krumpos-do-carmo.png",
    },
    {
      name: "Leandro Guimaraes",
      link: "https://www.youtube.com/embed/1_m1Pr7wyM8",
      image: "assets/images/class/leandro-guimaraes.png",
    },
    {
      name: "Guilherme Ribeiro",
      link: "https://www.youtube.com/embed/QzPmDjQ2p9c",
      image: "assets/images/class/guilherme-ribeiro-santos.png",
    },
    {
      name: "Anderson Lira",
      link: "https://www.youtube.com/embed/_RR5LgDgD0c",
      image: "assets/images/class/anderson-lira-da-silva.png",
    },
    {
      name: "Bruno Cunha",
      link: "https://www.youtube.com/embed/iKaSjn9Qvnw",
      image: "assets/images/class/bruno-cunha.png",
    },
    {
      name: "Raphael Lage",
      link: "https://www.youtube.com/embed/OtAy0ekeQoo",
      image: "assets/images/class/raphael-lage.png",
    },
    {
      name: "Jean Pierre",
      link: "https://www.youtube.com/embed/2Lmr_rfoa3U",
      image: "assets/images/class/jean-pierre.png",
    },
    {
      name: "Thais Apparecida Pereira",
      link: "https://www.youtube.com/embed/YPDAkFJ67Ho",
      image: "assets/images/class/thais-apparecida-pereira.png",
    },
    {
      name: "Rosana Santos",
      link: "https://www.youtube.com/embed/58UF3Ptf-pc",
      image: "assets/images/class/rosana-santos.png",
    },
    {
      name: "Thiago Lima",
      link: "https://www.youtube.com/embed/8XExopWPr8I",
      image: "assets/images/class/thiago-lima.png",
    },
    {
      name: "Vandisney Dutra",
      link: "https://www.youtube.com/embed/LNWxYGbyROM",
      image: "assets/images/class/vandisney-dutra.png",
    },
  ]

  constructor(
    private CommonService: CommonService
  ) { }

  public getSliderDepoimmentConfig(): KeenSliderOptions {
    return {
      mode: "free-snap",
      breakpoints: {
        "(max-width: 1200px)": {
          slides: {
            perView: 2.1,
            spacing: 24
          },
        },
        "(max-width: 450px)": {
          slides: {
            perView: 1.1,
            spacing: 24
          },
        },
        "(min-width: 1200px)": {
          slides: {
            perView: 3.1,
            spacing: 24
          },
        }
      }
    }
  }

  public openPlayerModal(template: TemplateRef<any>, link: string) {
    this.player_link = link;
    this.CommonService.openModal(template, () => { }, { fullscreen: true });
  }
  public openModal(template: TemplateRef<any>): void {
    this.CommonService.openModal(template, () => { }, { size: 'lg' })
  }
}