import { Component, Input } from '@angular/core';
import { FAQ } from 'app/types';
import { AppModule } from '@core'

@Component({
  selector: 'app-faq-card',
  standalone: true,
  imports: [
    AppModule
  ],
  templateUrl: './faq-card.component.html',
  styleUrl: './faq-card.component.scss'
})
export class FaqCardComponent {

  @Input() faq!: FAQ;

}
