import { Component } from '@angular/core';

import { AppModule } from '@core';

import {
  FooterComponent,
  HeaderComponent,
  BackComponent,
  BtnComponent
} from "@components"
import { PrivacyPolicyService } from '@features/privacy-policy/services';

@Component({
  selector: 'cfa-privacy-policy',
  standalone: true,
  imports: [
    AppModule,
    BackComponent,
    FooterComponent,
    HeaderComponent,
    BtnComponent
  ],
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss'
})
export class PrivacyPolicyComponent {

  public policy: any;

  constructor(
    private PrivacyPolicy: PrivacyPolicyService
  ) { }

  private getPolicy() {
    this.policy = this.PrivacyPolicy.policy
  }

  public goHome() {
    window.location.href = '/';
  }

  ngOnInit() {
    window?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
    this.getPolicy();
  }

}