<app-header></app-header>
<div id="home" class="home" #home>
	<div class="radius-bg-primary-top"></div>
	<div class="content position-relative">
		<div class="container">
			<div class="d-flex flex-column gap-3 gap-lg-5">
				<div class="d-flex flex-column flex-lg-row justify-content-center align-items-center gap-4">
					<div class="w-150px">
						<cfa-group-students></cfa-group-students>
					</div>
					<div>
						<span class="fs-lg-5">
							Junte-se a
							<b class="fw-700">+1000</b>
							alunos e alunas
						</span>
					</div>
				</div>
				<div class="text-center">
					<h1 class="fs-0 fw-400">
						Nosso objetivo
						<b class="fw-700">
							é te fazer
							<br class="d-none d-md-block">
							alcançar
						</b>
						o seu próximo objetivo
					</h1>
				</div>

			</div>
		</div>
		<div class="d-flex justify-content-center pt-4">
			<div class="d-flex justify-content-center sign">
				<cfa-sign></cfa-sign>
			</div>
		</div>
		<div class="mt-4 mt-lg-0">
			<div class="arrow-down-md-center"></div>
		</div>
	</div>
</div>
<div class="session-mt-sm"></div>
<div id="label" class="label" #label>
	<div class="d-none d-lg-block radius-bg-primary-start"></div>
	<div class="content">
		<div class="d-flex justify-content-center align-items-center label-container">
			<div class="bg-tertiary-to-primary-bt py-3">
				<div class="d-flex gap-2">
					<ng-container *ngFor="let item of [].constructor(6);let last = last">
						<ng-container *ngFor="let ltt of labelItems;let last = last">
							<span>{{ltt}}</span>
							<ng-container *ngIf="!last">
								<span>-</span>
							</ng-container>
						</ng-container>
						<ng-container *ngIf="!last">
							<span>-</span>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="session-mt-lg"></div>
<div id="training" class="training position-relative" #training style="z-index: 5;">
	<div class="container">
		<div class="d-flex flex-column gap-4 gap-lg-5">
			<div class="d-flex justify-content-between">
				<div class="text-center text-lg-start w-100 lh-sm align-self-center">
					<span class="fs-1">
						Explore nossos
						<br class="d-lg-none">
						<span class="text-tertiary-to-primary-bt fw-700">Treinamentos</span>
					</span>
				</div>
				<!-- <div class="align-self-center">
					<cfa-filter [filterSelect]="trainings"></cfa-filter>
				</div> -->
			</div>
			<div class="d-lg-none">
				<div class="d-flex align-items-center justify-content-center justify-content-lg-start gap-2">
					<span class="fs-4">Arraste para o lado</span>
					<i class="fs-4 text-tertiary-to-primary-bt fa-solid fa-arrow-right"></i>
				</div>
			</div>
			<div class="carousel-training">
				<div cfaSlider [config]="getSliderTrainingConfig()">
					<ng-container *ngFor="let training of trainings">
						<div slide>
							<cfa-training-card [config]="training"></cfa-training-card>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="session-mt-md"></div>
<div class="radius-bg-primary-end"></div>
<div id="immersion" class="immersion position-relative" #immersion style="z-index: 5;">
	<div class="container">
		<div class="d-flex flex-column gap-4 pb-4 pb-md-0">
			<div class="d-flex justify-content-between">
				<div class="text-center text-lg-start w-100 lh-sm">
					<span class="fs-1">
						Explore nossas
						<br class="d-lg-none">
						<span class="text-tertiary-to-primary-bt fw-700">Imersões</span>
					</span>
				</div>
			</div>
			<div class="d-flex align-items-center justify-content-center justify-content-lg-start gap-2">
				<span class="fs-4">Arraste para o lado</span>
				<i class="fs-4 text-tertiary-to-primary-bt fa-solid fa-arrow-right"></i>
			</div>
			<div class="carousel-immersion">
				<div cfaSlider [config]="getSliderTrainingConfig()">
					<ng-container *ngFor="let immersion of immersions">
						<div slide>
							<cfa-training-card [config]="immersion"></cfa-training-card>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>
<div id="practice" class="practice position-relative" #practice style="z-index: 5;">
	<div>&nbsp;
		<div class="session-mt-md"></div>
	</div>
	<div class="container">
		<div class="d-flex flex-column gap-4">
			<div class="d-flex justify-content-between">
				<div class="text-center text-lg-start lh-sm w-100">
					<span class="fs-1">
						Explore nossos
						<br class="d-lg-none">
						<span class="text-tertiary-to-primary-bt fw-700">Simulados</span>
					</span>
				</div>
			</div>
			<div class="d-flex align-items-center justify-content-center justify-content-lg-start gap-2">
				<span class="fs-4">Arraste para o lado</span>
				<i class="fs-4 text-tertiary-to-primary-bt fa-solid fa-arrow-right"></i>
			</div>
			<div class="carousel-practice">
				<div cfaSlider [config]="getSliderPracticeConfig()">
					<ng-container *ngFor="let practice of practices">
						<div slide>
							<cfa-practice-card [config]="practice"></cfa-practice-card>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="session-mt-lg"></div>
<div class="radius-bg-primary-start"></div>
<div id="theory" class="theory position-relative" #theory style="z-index: 5;">
	<div class="background"></div>
	<div class="container py-md-4 py-xxl-5">
		<div class="row">
			<div class="col-12 col-lg-8 pb-4">
				<div class="d-flex flex-column gap-5">
					<div class="row">
						<div class="col-12 col-lg-10 pb-lg-4 text-center text-lg-start">
							<span class="fs-5 fs-lg-3 fw-300">
								<b>Não fique só</b>
								na teoria, aplique seus conhecimentos em nossos
								<b>laboratórios</b>
							</span>
						</div>
					</div>
					<div
						class="lab-example bg-secondary-bt-to-primary-bt rounded-3 overflow-hidden p-5 d-none d-lg-block">
						<div class="d-flex justify-content-center align-items-center w-100">
							<div
								class="lab-init-example bg-main-bright rounded-3 border-gradient border-tertiary-to-primary-bt p-4_5">
								<div class="d-flex flex-column gap-4">
									<div>
										<span class="fs-5 fs-lg-3">Detalhes:</span>
									</div>
									<div>
										<div class="d-flex gap-4_5">
											<div class>
												<div class="d-flex align-items-center gap-2 gap-md-3">
													<div
														class="info-icon d-flex justify-content-center align-items-center bg-secondary-bt-to-primary-bt rounded-3 ">
														<i class="fs-5 fs-md-2 text-white fa-regular fa-clock"></i>
													</div>
													<div class="d-flex flex-column">
														<span class="fs-6 fs-md-4">01h</span>
														<span class="fs-7 fs-md-5 fw-300 opacity-75 ">Tempo</span>
													</div>
												</div>
											</div>
											<div class>
												<div class="d-flex align-items-center gap-2 gap-md-3">
													<div
														class="info-icon d-flex justify-content-center align-items-center bg-secondary-bt-to-primary-bt rounded-3 ">
														<i class="fs-5 fs-md-2 text-white fa-solid fa-bolt"></i>
													</div>
													<div class="d-flex flex-column">
														<span class="fs-6 fs-md-4">01</span>
														<span class="fs-7 fs-md-5 fw-300 opacity-75">Tentativas</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="border border-white border-opacity-10 w-100"></div>
									<div>
										<div
											class="d-flex justify-content-center bg-white bg-opacity-5 py-4 rounded-3 w-100">
											<span class="fs-6 fs-md-5">Iniciar laboratório</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="border-gradient border-secondary-bt-primary-bt rounded-3 w-100">
						<div class="bg-white-blur p-4 rounded-3">
							<div class="row justify-content-center">
								<div class="col-6 col-md-4 align-items-center text-center">
									<span class="fs-1 fs-lg-0 text-secondary-bt-to-primary-bt">+100</span>
									<br>
									<span class="fs-6 fs-lg-5 fw-300 opacity-75">HORAS DE LAB</span>
								</div>
								<div class="col-6 col-md-4 align-items-center text-center">
									<span class="fs-1 fs-lg-0 text-secondary-bt-to-primary-bt">+5000</span>
									<br>
									<span class="fs-6 fs-lg-5 fw-300 opacity-75">EXECUÇÕES</span>
								</div>
								<div class="col-12 col-md-4 align-items-center text-center">
									<span class="fs-1 fs-lg-0 text-secondary-bt-to-primary-bt">+5000</span>
									<br>
									<span class="fs-6 fs-lg-5 fw-300 opacity-75 ">EC2 GERENCIADAS</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div id="lab" class="lab position-relative" #lab style="z-index: 5;">
	<div>&nbsp;
		<div class="session-mt-md"></div>
	</div>
	<div class="container">
		<div class="d-flex flex-column gap-4 gap-lg-5">
			<div class="d-flex justify-content-between">
				<div class="text-center text-lg-start w-100">
					<span class="fs-1 lh-sm">
						Explore nossos
						<br class="d-lg-none">
						<span class="text-tertiary-to-primary-bt fw-700">Laboratórios</span>
					</span>
				</div>
			</div>
			<div class="d-flex flex-column gap-3">
				<ng-container *ngFor="let lab of labs">
					<cfa-labs-card [config]="lab"></cfa-labs-card>
				</ng-container>
			</div>
		</div>
	</div>
</div>
<div class="d-flex justify-content-center">
	<div class="d-none d-md-block w-90">
		<div class="session-mt-sm"></div>
		<div class="arrow-down-md-center"></div>
		<!-- <div class="radius-bg-primary index-0"></div> -->
		<div class="session-mt-sm"></div>
	</div>
	<div class="d-block d-md-none w-100">
		<div class="session-mt-md"></div>
		<div class="arrow-down-md-center"></div>
		<div class="session-mt-md"></div>
	</div>
</div>
<div id="depoimment" class="depoimment position-relative" #depoimment style="z-index: 5;">
	<div class="container">
		<div class="d-flex flex-column">
			<div
				class="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between gap-4 text-center text-lg-start">
				<div>
					<span class="fs-1 lh-sm">
						Pessoas que ajudamos a
						<br class="d-none d-lg-block">
						conquistar
						<span class="text-tertiary-to-primary-bt fw-500">seus objetivos</span>
					</span>
				</div>
				<div class="d-flex align-items-center justify-content-center gap-2">
					<span class="fs-4">Arraste para o lado</span>
					<i class="fs-4 text-tertiary-to-primary-bt fa-solid fa-arrow-right"></i>
				</div>
			</div>
			<div class="session-mt-sm"></div>
		</div>
	</div>
	<feature-class></feature-class>
</div>
<div class="session-mt-md"></div>
<cfa-faq-feature [faqs]="faqs" class="position-relative" style="z-index: 5;"></cfa-faq-feature>
<div class="session-mt-lg"></div>
<feature-back class="position-relative" style="z-index: 5;"></feature-back>
<div class="session-mt-sm"></div>
<cfa-footer></cfa-footer>