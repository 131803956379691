export class Mentoring {
  text!: string;
  image!: string;
  icon!: string;
  constructor(config:any){
    if(config){
      this.text = config.text !== undefined ? config.text : this.text;
      this.image = config.image !== undefined ? config.image : this.image;
      this.icon = config.icon !== undefined ? config.icon : this.icon;
    }
  }
}