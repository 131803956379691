import { Component } from '@angular/core';

@Component({
  selector: 'feature-dan',
  standalone: true,
  imports: [],
  templateUrl: './dan.component.html',
  styleUrl: './dan.component.scss'
})
export class DanComponent {

}