<ng-template #playerModal let-modal>
	<div class="container h-100">
		<div class="d-flex flex-column w-100 h-100 py-5">
			<div class="d-flex justify-content-between">
				<div></div>
				<cfa-btn [classes]="'w-60px h-60px'" [styles]="'padding:0'" [variant]="'flat'" (click)="modal.close()">
					<div class="d-flex justify-content-center align-items-center">
						<i class="icon fa-solid fa-times d-flex text-white opacity-75 fa-2x"></i>
					</div>
				</cfa-btn>
			</div>
			<div class="d-flex align-items-center justify-content-center h-100 w-100 flex-grow-1">
				<div class="row justify-content-center w-100">
					<div class="col-11 col-md-9 col-lg-8">
						<iframe class="w-100" [src]="player_link | safeUrl" style="aspect-ratio: 560/315;"
							title="YouTube video player" frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowfullscreen></iframe>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>
<div id="class" class="class" #class>
	<div class="d-flex flex-column gap-5">
		<div class="container">
			<div class="carousel-depoimment">
				<div cfaSlider [config]="getSliderDepoimmentConfig()">
					<ng-container *ngFor="let depoimment of depoimments">
						<div slide>
							<div class="card-class border-gradient border-secondary-primary-bright overflow-hidden"
								(click)="openPlayerModal(playerModal, depoimment.link)">
								<picture class="image">
									<source [srcset]="depoimment.image" type="image/png">
									<img class="w-100 img-fluid" [src]="depoimment.image" width="388" height="300"
										[alt]="depoimment.name">
								</picture>
								<div class="text text-center position-absolute w-100">
									<small>
										Clique para Assistir
									</small>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
		<!-- <div class="d-none d-md-block radius-bg-primary" style="z-index: -1;">
			<div class="content"></div>
		</div> -->
	</div>
</div>