import { Component, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonService, HttpRequestsService } from '@services';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import {
  FaqFeatureComponent,
  BtnComponent,
  BtnLinkComponent,
  BackComponent,
  FooterComponent,
  CertificationsValeriaComponent,
  ValeriaBaptistaComponent,
  HeaderComponent,
  InputTextComponent
} from "@components";
import { CourseRoute } from '@app/types/courses';
import { KeenSliderOptions } from 'keen-slider';
import { SliderDirective } from '@directives';
import { FAQ, Mentoring, Topic } from '@types';
import { Subscription } from 'rxjs';
import { CoursesService } from '@features/courses/services';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cfa-jpa',
  standalone: true,
  imports: [
    BtnComponent,
    SliderDirective,
    CommonModule,
    FooterComponent,
    FaqFeatureComponent,
    BackComponent,
    CertificationsValeriaComponent,
    BtnLinkComponent,
    HeaderComponent,
    ValeriaBaptistaComponent,
    FormsModule,
    ReactiveFormsModule,
    InputTextComponent,
  ],
  providers: [
    CommonService,
    HttpRequestsService
  ],
  templateUrl: './jpa.component.html',
  styleUrl: './jpa.component.scss'
})
export class JpaComponent implements OnInit {

  private _subscriptions: Subscription[] = [];
  @ViewChild('modalForm') __modalForm!: TemplateRef<any>;
  private modalService = inject(NgbModal);

  public course_list: CourseRoute[] = [];
  public faqs: FAQ[] = [];
  public topic_list: Topic[] = [];
  public ementa_link: string = 'https://assets.cloudfaster.academy/valeriabatista/ementa-jpa.pdf';
  public course_name: string = 'Jornada Profissional Azure';

  private _campaing_id: string = 'LCMJPAJAN24-VENDAS-LP-INSCRICAO-V1'
  public error: boolean = false;
  public erro_msg?: string;
  public object: any = {};
  public object_content: any = {};
  public params: any = {};
  public loading: boolean = false;

  closeResult = '';

  constructor(
    private CoursesService: CoursesService,
    private HttpRequestsService: HttpRequestsService,
  ) { }

  public getSliderMentoring(): KeenSliderOptions {
    return {
      mode: "free",
      slides: {
        perView: 1.8,
        spacing: 20,
        origin: "center"
      }
    }
  }

  private getFaqs() {
    this.faqs = this.CoursesService.jpa_faqs;
  }

  public onSubmit(f: NgForm) {
    if (f.valid) {
      this.loading = true
      let payload: any = {
        name: this.object.name,
        email: this.object.email,
        phone: this.object.phone,
        campaign: this._campaing_id,
        source: 'matricula-jpa'
      }
      //! Adicionar utm ao payload
      if (Object.keys(this.params).length) {
        payload['utmsource'] = this.params['utm_source']
        payload['utmmedium'] = this.params['utm_medium']
        payload['utmcampaign'] = this.params['utm_campaign']
        payload['utmterm'] = this.params['utm_term']
        payload['utmcontent'] = this.params['utm_content']
      }

      this._subscriptions.push(
        this.HttpRequestsService.requestData('POST', ':productor/ac/receive', payload).subscribe({
          next: (data: any) => {
            window.location.href = `https://pay.kiwify.com.br/VC4nMN5?utm_source=${payload.utm_source}&utm_medium=${payload.utm_medium}&utm_campaign=${payload.utm_campaign}&utm_term=${payload.utm_term}&utm_content=${payload.utm_content}&email=${payload.email}&name=${payload.name}&phone=${payload.phone}`;
            // this.openModal(this.__modalForm);
            // f.resetForm();
            // this.object = '';
            // this.loading = false
            this.error = false;
          },
          error: (err: any) => {
            console.log(err.error.message)
            this.erro_msg = err.status < 500 ? err.error.message : undefined;
            console.error(err)
            this.loading = false;
            this.error = true;
          }
        })
      )
    }
  }

  public openModal(template: TemplateRef<any>): void {
    this.modalService.open(template, { backdrop: true, centered: false, size: 'lg' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        return 'by pressing ESC';
      case ModalDismissReasons.BACKDROP_CLICK:
        return 'by clicking on a backdrop';
      default:
        return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    window?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    })
    this.getFaqs();
    // setTimeout(() => {
    //   this.openModal(this.__modalForm);
    // }, 2000);
  }
}