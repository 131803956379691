import { Component, Input, OnInit } from '@angular/core';
import { AppModule } from 'app/core';
import { CommonService } from '@services';
import { Banner } from '@types';
import { BtnLinkComponent } from '@components';
import { CarouselCoursesComponent } from '../../components/carousel-courses/carousel-courses.component';

@Component({
  selector: 'cfa-banner',
  standalone: true,
  imports: [
    AppModule,
    CarouselCoursesComponent,
    BtnLinkComponent
  ],
  providers: [
    CommonService,
  ],
  templateUrl: './banner.component.html',
  styleUrl: './banner.component.scss'
})
export class BannerComponent implements OnInit {

  @Input() config?: Banner;

  constructor(
    private CommonService: CommonService,
  ) { }


  ngOnInit(): void {
  }

}