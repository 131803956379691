<app-header></app-header>


<div class="session-mt-sm d-none d-md-block"></div>
<div class="session-mt-md d-md-none"></div>

<div class="container">
	<div [innerHTML]="policy"></div>
	<div class="session-mt-sm"></div>
	<div class="row justify-content-between">
		<div class="col d-none d-md-block">

		</div>
		<div class="col-12 col-sm text-center">
			<cfa-btn (click)="goHome()">
				<span>
					<i class="fa-solid fa-angles-left"></i>&nbsp;
				</span> Voltar
			</cfa-btn>
		</div>
		<div class="col-12 col-sm text-center text-sm-start">
			<feature-back></feature-back>
		</div>
	</div>
	<div class="session-mt-sm"></div>
</div>

<cfa-footer></cfa-footer>