import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Instructor, Training } from '@app/types/training';
import {
  BtnLinkComponent,
  BtnRouteComponent
} from '@components'
@Component({
  selector: 'cfa-training-card',
  standalone: true,
  imports: [
    BtnRouteComponent,
    BtnLinkComponent,
    CommonModule
  ],
  templateUrl: './training-card.component.html',
  styleUrl: './training-card.component.scss'
})
export class TrainingCardComponent {
  @Input() config!:Training
}