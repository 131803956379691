export class FAQ {
  question!: string;
  answer!: string;
  collapsed?: boolean = true;
  constructor(config:any){
    if(config){
      this.question = config.question !== undefined ? config.question : this.question;
      this.answer = config.answer !== undefined ? config.answer : this.answer;
      this.collapsed = config.collapsed !== undefined ? config.collapsed : this.collapsed;
    }
  }
}
