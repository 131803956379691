<ng-template #modalForm let-modal>
	<div class="border-gradient rounded-3 bg-main-bright border-tertiary-to-primary-bt p-4 p-lg-5 modal-form w-100">
		<div class="text-center fs-5 pb-4 d-lg-none">
			Parabéns!<br>Seu cadastro na <span class="fw-500">lista de espera</span> foi concluído!
		</div>
		<div class="text-center fs-3 pb-3 d-none d-lg-block">
			Parabéns!<br>Seu cadastro na <span class="fw-500">lista de espera</span> foi concluído!
		</div>
		<div class="text-center">
			<img src="assets/images/icons/check-color.png" alt="check" class="img-fluid">
		</div>
	</div>
</ng-template>
<app-header></app-header>
<div id="home" class="home" #home>
	<div class="d-none d-lg-block radius-bg-primary-start" style="top: 50rem;"></div>
	<div class="container">
		<div class="row align-items-center">
			<div class="col-12 col-md-6 d-flex align-items-center text-center order-lg-2">
				<img src="assets/images/advc/background/bg-dan.png" alt="Dan" class="img-fluid">
			</div>
			<div id="form" #form class="col-12 col-lg-6 d-flex align-items-center order-lg-1">
				<div class="row">
					<div class="col-12 col-lg-10 text-center text-lg-start">
						<div class="lh-sm px-4">
							<span class="fs-0 text-center text-lg-start">
								Cadastre-se na<br class="d-lg-none"> Lista
								<span class="text-tertiary-to-primary-bt fw-500">
									de Espera
								</span>
							</span>
						</div>
					</div>
					<div class="col-12 col-lg-10 text-center text-lg-start">
						<div class="p-4">
							<span class="fs-4 text-center text-lg-start">
								O passo a passo para conquistar seu objetivo com AWS
							</span>
						</div>
					</div>
					<div class="col-12">
						<form class="d-flex flex-column gap-3 p-4" #formHeader="ngForm"
							(ngSubmit)="onSubmit(formHeader)">
							<div class="col-12">
								<cfa-input-text [label]="'Insira seu nome'" [classes]="'bg-main-bright bg-opacity-80'"
									[size]="'md'" [placeholder]="'Nome e sobrenome'" [prefixIcon]="'fa-regular fa-user'"
									[ngForm]="formHeader" [disabled]="loading" [(value)]="object.name"></cfa-input-text>
							</div>
							<div class="col-12">
								<cfa-input-text [label]="'Insira seu e-mail'" [classes]="'bg-main-bright bg-opacity-80'"
									[name]="'email'" [size]="'md'" [type]="'email'"
									pattern="[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
									[placeholder]="'seuemail@exemplo.com'" [prefixIcon]="'fa-regular fa-envelope'"
									[ngForm]="formHeader" [disabled]="loading"
									[(value)]="object.email"></cfa-input-text>
							</div>
							<div class="col-12">
								<cfa-input-text [label]="'Insira seu WhatsApp'"
									[classes]="'bg-main-bright bg-opacity-80'" [size]="'md'"
									[placeholder]="'(00) 0 0000-0000'" [mask]="'(00) 0000-0000||(00) 0 0000-0000'"
									[prefixIcon]="'fa-solid fa-phone'" [ngForm]="formHeader" [disabled]="loading"
									[(value)]="object.phone"></cfa-input-text>
							</div>
							<div class="col-12 mt-3">
								<cfa-btn [classes]="'w-100'" [type]="'submit'" [disabled]="loading">
									<ng-container *ngIf="!loading">
										Confirmar cadastro!
									</ng-container>
									<ng-container *ngIf="loading">
										Carregando
										<div class="d-inline-block opacity-animation">
											<span class="animated animated-0">.</span>
											<span class="animated animated-1">.</span>
											<span class="animated animated-2">.</span>
										</div>
									</ng-container>
								</cfa-btn>
							</div>
						</form>
						<ng-container *ngIf="error">
							<div class="row justify-content-center">
								<div class="col-12 col-md-6">
									<div class="bg-main mb-3 rounded-3 overflow-hidden w-100">
										<div class="bg-danger bg-opacity-10 px-4 py-3">
											<div class="d-flex flex-column gap-1">
												<div>
													<span class="text-danger fw-bold">Ocorreu um erro</span>
												</div>
												<div class="d-flex flex-column gap-2">
													<div class="">
														<ng-container *ngIf="!erro_msg">
															<small>Desculpe! Tente novamente...</small>
														</ng-container>
														<ng-container *ngIf="erro_msg">
															<small>{{erro_msg}}</small>
														</ng-container>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
		<div class="session-mt-sm"></div>
	</div>
	<div class="d-none d-lg-block radius-bg-primary-end" style="bottom: 20rem;"></div>
</div>
<div id="ementa" class="ementa" #ementa>
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 text-center">
				<img src="assets/images/advc/content/cards-ementa.png" alt="Ementa" class="img-fluid">
			</div>
			<div class="col-12 position-relative" style="bottom: 5rem;">
				<div class="row justify-content-center">
					<div class="col-12 col-lg-8 col-xl-6 text-center position-relative">
						<span class="fs-3">
							Sabemos o quanto é importante para o profissional de TI ter uma
							<span class="text-tertiary-to-primary-bt">ementa completamente detalhada</span>
						</span>
					</div>
					<div class="col-12">
						<div class="py-3 text-center">
							<span class="fs-4">
								Clique aqui para baixar a ementa completa do Método ADVC
							</span>
						</div>
					</div>
					<div class="col-12 col-lg-7 text-center">
						<cfa-btn-link [href]="ementa_link" [classes]="'w-100'" [target]="'_blank'">
							Baixar Ementa
						</cfa-btn-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div id="mentoring" class="mentoring" #mentoring>
	<div class="session-mt-sm"></div>
	<div class="d-none d-lg-block radius-bg-primary-end"></div>
	<div class="container">
		<div class="text-center">
			<span class="fs-1">Mentoria <span class="text-tertiary-to-primary-bt">ADVC</span></span>
		</div>
		<div class="pt-3 text-center">
			<div class="fs-4 opacity-75">O caminho mais curto rumo aos seus objetivos</div>
		</div>
		<div class="session-mt-sm"></div>
		<div class="row justify-content-center">
			<div class="col-12 col-xl-8 d-none d-md-block">
				<div class="row justify-content-center gy-4">
					<ng-container *ngFor="let item of course_mentoring">
						<div class="col-12 col-md-4 d-flex justify-content-center">
							<div class="border-gradient rounded-3 w-100 border-tertiary-to-primary-bt mentoring-card"
								[style]="'background-image: url(' + item.image + ')'">
								<div class="d-flex flex-column h-100 justify-content-end gap-3 pb-5">
									<div class="text-center px-3">
										<span class="fs-5" [innerHTML]="item.text"></span>
									</div>
									<div class="text-center">
										<img [src]="item.icon" alt="Icon" class="">
									</div>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
	<div class="d-none d-lg-block radius-bg-primary-start"></div>
	<div class="d-md-none">
		<div class="d-flex align-items-center justify-content-center gap-2 text-center pb-4">
			<span>Arraste para o lado</span>
			<i class="text-secondary-bt-to-primary-bt fa-solid fa-arrow-right"></i>
		</div>
		<div cfaSlider [config]="getSliderMentoring()">
			<ng-container *ngFor="let item of course_mentoring">
				<div slide>
					<div class="col-12 col-md-4 d-flex justify-content-center">
						<div class="border-gradient rounded-3 w-100 border-tertiary-to-primary-bt mentoring-card"
							[style]="'background-image: url(' + item.image + ')'">
							<div class="d-flex flex-column h-100 justify-content-end gap-3 pb-5">
								<div class="text-center px-3">
									<span class="fs-5" [innerHTML]="item.text"></span>
								</div>
								<div class="text-center">
									<img [src]="item.icon" alt="Icon" class="">
								</div>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
	<div class="session-mt-md"></div>
	<div class="container">
		<div class="text-center">
			<span class="fs-1">Você será preparado para planejar,<br class="d-none d-md-block"> executar e documentar
				<span class="text-tertiary-to-primary-bt">qualquer<br class="d-none d-md-block">
					arquitetura</span></span>
		</div>
		<div class="session-mt-sm"></div>
		<div class="row justify-content-center">
			<div class="col-12 col-xl">
				<div class="d-flex flex-column align-items-center gap-3">
					<div
						class="icon-topic d-flex justify-content-center align-items-center bg-secondary-bt-to-primary-bt rounded-3">
						<img src="assets/images/icons/cpu.png" alt="cpu" width="50" height="50">
					</div>
					<div class="text-center">
						<span class="fs-5 opacity-75">Tradicional com EC2</span>
					</div>
				</div>
			</div>
			<div class="col-12 col-xl py-4 py-xl-0">
				<div class="d-none d-xl-block arrow-right-md-center"></div>
				<div class="d-xl-none arrow-down-md-center"></div>
			</div>
			<div class="col-12 col-xl">
				<div class="d-flex flex-column align-items-center gap-3">
					<div
						class="icon-topic d-flex justify-content-center align-items-center bg-secondary-bt-to-primary-bt rounded-3">
						<img src="assets/images/icons/copy.png" alt="copy" width="50" height="50">
					</div>
					<div class="text-center">
						<span class="fs-5 opacity-75">Docker com ECS</span>
					</div>
				</div>
			</div>
			<div class="col-12 col-xl py-4 py-xl-0">
				<div class="d-none d-xl-block arrow-right-md-center"></div>
				<div class="d-xl-none arrow-down-md-center"></div>
			</div>
			<div class="col-12 col-xl">
				<div class="d-flex flex-column align-items-center gap-3">
					<div
						class="icon-topic d-flex justify-content-center align-items-center bg-secondary-bt-to-primary-bt rounded-3">
						<img src="assets/images/icons/lambda.png" alt="lambda" width="50" height="50">
					</div>
					<div class="text-center">
						<span class="fs-5 opacity-75">Serverless com Lambda</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="d-none d-xl-block">
	<div class="radius-bg-primary-top"></div>
	<div class="arrow-down-md-center"></div>
</div>
<div id="course" class="course" #course>
	<div class="session-mt-sm"></div>
	<div class="container">
		<div class="d-flex flex-column gap-3 text-center">
			<span class="fs-0">
				Isso não é um
				<span class="text-tertiary-to-primary-bt fw-700">curso!</span>
			</span>
			<div>
				<span class="fs-4 opacity-75">
					<div class="fw-700">É uma jornada de 12 meses</div> com passo a passo para seu objetivo
				</span>
			</div>
		</div>
	</div>
	<div class="session-mt-sm"></div>
	<div class="d-none d-lg-block radius-bg-primary-start" style="top: 50rem;"></div>
	<div class="d-none d-lg-block radius-bg-primary-end" style="top: 100rem;"></div>
	<div class="d-none d-lg-block radius-bg-primary-start" style="top: 150rem;"></div>
	<div class="d-none d-lg-block radius-bg-primary-end" style="top: 200rem;"></div>
	<div class="d-none d-lg-block radius-bg-primary-start" style="top: 250rem;"></div>
	<div class="container">

		<div class="session-mt-sm"></div>
		<div class="row justify-content-center align-items-center">
			<div class="col-12 col-md-6 col-lg-5 d-flex flex-column gap-3">
				<div class="text-center text-md-start">
					<i class="fs-1 text-secondary-bt-to-primary-bt fa-regular fa-circle-check"></i>
				</div>
				<div class="text-center text-md-start">
					<span class="fs-3 ls-sm">
						<span class="fw-500">Aprenda na prática</span>
						<br class="d-none d-md-block">
						usando Laboratórios em
						<br class="d-none d-md-block">
						contas reais da AWS
					</span>
				</div>
				<div class="mt-xxl-4 d-none d-md-block">
					<div class="d-none d-md-block arrow-down-md-start"></div>
				</div>
			</div>
			<div class="col-12 col-md-6 pt-4 pt-md-0">
				<div class="main-content">
					<img src="assets/images/advc/example/notebook.png" alt="notebook"
						class="img-content d-none d-md-block">
					<img src="assets/images/advc/example/notebook-sm.png" alt="notebook" class="img-content d-md-none">
				</div>
				<div class="d-block d-md-none py-4">
					<div class="arrow-down-md-center"></div>
				</div>
			</div>
			<div class="col-md-1 d-none d-lg-block order-md-1"></div>
		</div>

		<div class="session-mt-sm"></div>

		<div class="row justify-content-center align-items-center">
			<div class="col-12 col-md-6 col-lg-5 d-flex flex-column gap-3 order-md-2">
				<div class="text-center text-md-start">
					<i class="fs-1 text-secondary-bt-to-primary-bt fa-regular fa-circle-check"></i>
				</div>
				<div class="text-center text-md-start">
					<span class="fs-3 ls-sm">
						Compartilhe e troque
						<br class="d-none d-md-block">
						experiências de
						<br class="d-none d-md-block">
						aprendizado com
						<br class="d-none d-md-block">
						nossos alunos em nossa
						<br class="d-none d-md-block">
						<span class="fw-500">comunidade do Discord</span>
					</span>
				</div>
				<div class="mt-xxl-4 d-none d-md-block">
					<div class="d-none d-md-block arrow-down-md-start"></div>
				</div>
			</div>
			<div class="col-8 col-md-6 pt-4 pt-md-0 order-md-1">
				<div class="border-gradient border-secondary-bt-to-primary-bt rounded-4">
					<div class="bg-white-blur rounded-4 p-5">
						<div class="d-none d-md-block session-mt-sm"></div>
						<div class="d-flex flex-column align-items-center gap-3">
							<div class="w-75 text-center">
								<img src="assets/images/logos/discord.png" alt="discord" class="img-fluid">
							</div>
							<div class="w-75 text-center">
								<img src="assets/images/logos/dan-rezende.png" alt="Dan Rezende" class="img-fluid">
							</div>
						</div>
						<div class="d-none d-md-block session-mt-sm"></div>
					</div>
				</div>
				<div class="d-block d-md-none py-4">
					<div class="arrow-down-md-center"></div>
				</div>
			</div>
			<div class="col-md-1 d-none d-lg-block order-md-1"></div>
		</div>

		<div class="session-mt-sm"></div>

		<div class="row justify-content-center align-items-center">
			<div class="col-12 col-md-6 d-flex flex-column gap-3">
				<div class="text-center text-md-start">
					<i class="fs-1 text-secondary-bt-to-primary-bt fa-regular fa-circle-check"></i>
				</div>
				<div class="text-center text-md-start">
					<span class="fs-3 ls-sm">
						Aulas de Revisão para
						<br class="d-none d-md-block">
						Diversas Certificações
					</span>
				</div>
				<div class="mt-xxl-4 d-none d-md-block">
					<div class="d-none d-md-block arrow-down-md-start"></div>
				</div>
			</div>
			<div class="col-5 pt-4 pt-md-0">
				<img src="assets/images/advc/example/phone.png" alt="phone" class="img-fluid">
				<div class="d-block d-md-none py-4">
					<div class="arrow-down-md-center"></div>
				</div>
			</div>
		</div>

		<div class="session-mt-sm"></div>

		<div class="row justify-content-center align-items-center">
			<div class="col-12 col-md-6 col-lg-5 d-flex flex-column order-md-2 gap-3">
				<div class="text-center text-md-start">
					<i class="fs-1 text-secondary-bt-to-primary-bt fa-regular fa-circle-check"></i>
				</div>
				<div class="text-center text-md-start">
					<span class="fs-3 ls-sm">
						Desafios por Conjunto
						<br class="d-none d-md-block">
						de Serviços e Cenários
						<br class="d-none d-md-block">
						Reais em Ambiente AWS
					</span>
				</div>
				<div class="mt-xxl-4 d-none d-md-block">
					<div class="d-none d-md-block arrow-down-md-start"></div>
				</div>
			</div>
			<div class="col-12 col-md-6 pt-4 pt-md-0 order-md-1">
				<div class="main-content-notebook">
					<img src="assets/images/advc/example/notebook-desafio.png" alt="notebook"
						class="img-content d-none d-md-block">
					<img src="assets/images/advc/example/notebook-desafio-sm.png" alt="notebook"
						class="img-content d-md-none">
				</div>
				<div class="d-block d-md-none py-4">
					<div class="arrow-down-md-center"></div>
				</div>
			</div>
			<div class="col-md-1 d-none d-lg-block order-md-1"></div>
		</div>

		<div class="session-mt-sm"></div>

		<div class="row justify-content-center align-items-center">
			<div class="col-12 col-md-6 d-flex flex-column gap-3">
				<div class="text-center text-md-start">
					<i class="fs-1 text-secondary-bt-to-primary-bt fa-regular fa-circle-check"></i>
				</div>
				<div class="text-center text-md-start">
					<span class="fs-3 ls-sm">
						Correção dos Desafios
						<br class="d-none d-md-block">
						por um Time de
						<br class="d-none d-md-block">
						Especialistas
					</span>
				</div>
				<div class="mt-xxl-4 d-none d-md-block">
					<div class="d-none d-md-block arrow-down-md-start"></div>

				</div>
			</div>
			<div class="col-5 pt-4 pt-md-0">
				<img src="assets/images/ccp/content/caderno.png" alt="caderno" class="img-fluid">
			</div>
		</div>

		<div class="session-mt-md"></div>
		<div class="border-gradient border-secondary-bt-to-primary-bt rounded-3">
			<div class="bg-white-blur rounded-3 p-5">
				<div class="row justify-content-center align-items-center">
					<div class="col-12 col-md-5 d-flex flex-column gap-3">
						<div class="text-center">
							<img src="assets/images/advc/badge/advc.png" alt="ADVC" class="img-fluid">
						</div>
						<div class="text-center">
							<span class="fs-5 ls-sm fw-700">
								Formas de Pagamento
							</span>
						</div>
						<div class="text-center">
							<img src="assets/images/ccp/content/pay-options.png" alt="pagamento" class="img-fluid">
						</div>
						<div class="text-center">
							<span class="fs-5 ls-sm">
								<i class="fs-4 text-secondary-bt-to-primary-bt fa-regular fa-circle-check"></i>&nbsp;
								Compra 100% segura
							</span>
						</div>
					</div>
					<div class="col-12 col-md-7 d-flex flex-column gap-3 pt-4 pt-md-0 ps-0 ps-md-4">
						<div class="text-center text-md-start">
							<span class="fs-2 ls-sm">
								Método <span class="text-tertiary-to-primary-bt">
									ADVC
								</span>
							</span>
						</div>
						<div>
							<div class="fs-6 ls-sm mb-1 d-flex align-items-center">
								<span class="text-tertiary-to-primary-bt">
									>/&nbsp;
								</span>
								Acesso à Comunidade Privada no Discord
							</div>
							<div class="fs-6 ls-sm mb-1 d-flex align-items-center">
								<span class="text-tertiary-to-primary-bt">
									>/&nbsp;
								</span>
								Acesso à Simulados + Revisão
							</div>
							<div class="fs-6 ls-sm mb-1 d-flex align-items-center">
								<span class="text-tertiary-to-primary-bt">
									>/&nbsp;
								</span>
								Liberação de Badges e Insígnias Exclusivas
							</div>
							<div class="fs-6 ls-sm mb-1 d-flex align-items-center">
								<span class="text-tertiary-to-primary-bt">
									>/&nbsp;
								</span>
								PDF com Dicas de Estudos Funcionais
							</div>
							<div class="fs-6 ls-sm mb-1 d-flex align-items-center">
								<span class="text-tertiary-to-primary-bt">
									>/&nbsp;
								</span>
								Incluso Certificado de Participação
							</div>
							<div class="fs-6 ls-sm mb-1 d-flex align-items-center">
								<span class="text-tertiary-to-primary-bt">
									>/&nbsp;
								</span>
								+100 Laboratórios práticos com contas reais da AWS para praticar
							</div>
						</div>
						<div class="text-center text-md-start">
							<span class="fs-6 ls-sm opacity-60 mb-1">
								por apenas
							</span>
							<div class="text-center text-md-start">
								<span class="fs-0 ls-sm fw-300 d-none d-md-block">
									R$ 2.997,00
								</span>
								<span class="fs-1 ls-sm fw-300 d-md-none">
									R$ 2.997,00
								</span>
							</div>
						</div>
						<div class="text-center d-md-none">
							<cfa-btn-link [classes]="'w-300px'" [href]="'/curso/advc#form'" [target]="'_self'">
								<div class="py-2">
									<span class="fs-5 fw-300">
										Inscrever-se Agora
									</span>
								</div>
							</cfa-btn-link>
						</div>
						<div class="text-start d-none d-md-block">
							<cfa-btn-link [classes]="'w-300px'" [href]="'/curso/advc#form'" [target]="'_self'">
								<div class="py-2">
									<span class="fs-5 fw-300">
										Inscrever-se Agora
									</span>
								</div>
							</cfa-btn-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="d-none d-lg-block radius-bg-primary-top"></div>
	</div>
</div>
<div class="session-mt-lg"></div>




<div id="contentform" class="contentform" #contentform>
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-lg-6">
				<div class="d-flex flex-column gap-4">
					<div class="text-center">
						<span class="fs-1">
							O Método <span class="text-tertiary-to-primary-bt">ADVC</span> é<br>composto por:
						</span>
					</div>
					<div class="row gx-4 gy-4">
						<div class="col-12">
							<div class="bg-main-bright bg-opacity-70 p-4 rounded-1 w-100">
								<div class="d-flex justify-content-between align-items-center">
									<div class="d-flex align-itens-center gap-3">
										<div>
											<span class="count d-none d-lg-inline text-tertiary-to-primary-bt">01</span>
											<span class="count d-lg-none text-tertiary-to-primary-bt">01</span>
										</div>
										<div class="d-flex align-items-center">
											<span class="fw-300">Aulas Gravadas</span>
										</div>
									</div>
									<div class="d-flex aling-items-center h-100">
										<img src="assets/images/icons/video-color.png" alt="video" class="img-fluid">
									</div>
								</div>
							</div>
						</div>
						<div class="col-12">
							<div class="bg-main-bright bg-opacity-70 p-4 rounded-1 w-100">
								<div class="d-flex justify-content-between align-items-center">
									<div class="d-flex align-itens-center gap-3">
										<div>
											<span class="count d-none d-lg-inline text-tertiary-to-primary-bt">02</span>
											<span class="count d-lg-none text-tertiary-to-primary-bt">02</span>
										</div>
										<div class="d-flex align-items-center">
											<span class="fw-300">Quiz</span>
										</div>
									</div>
									<div class="d-flex aling-items-center h-100">
										<img src="assets/images/icons/message-square-color.png" alt="Message"
											class="img-fluid">
									</div>
								</div>
							</div>
						</div>
						<div class="col-12">
							<div class="bg-main-bright bg-opacity-70 p-4 rounded-1 w-100">
								<div class="d-flex justify-content-between align-items-center">
									<div class="d-flex align-itens-center gap-3">
										<div>
											<span
												class="count d-none d-lg-inline text-tertiary-to-primary-bt">03</span><span
												class="count d-lg-none text-tertiary-to-primary-bt">03</span>
										</div>
										<div class="d-flex align-items-center">
											<span class="fw-300">Demonstração</span>
										</div>
									</div>
									<div class="d-flex aling-items-center h-100">
										<img src="assets/images/icons/monitor-color.png" alt="Monitor"
											class="img-fluid">
									</div>
								</div>
							</div>
						</div>
						<div class="col-12">
							<div class="bg-main-bright bg-opacity-70 p-4 rounded-1 w-100">
								<div class="d-flex justify-content-between align-items-center">
									<div class="d-flex align-itens-center gap-3">
										<div>
											<span
												class="count d-none d-lg-inline text-tertiary-to-primary-bt">04</span><span
												class="count d-lg-none text-tertiary-to-primary-bt">04</span>
										</div>
										<div class="d-flex align-items-center">
											<span class="fw-300">Laboratórios</span>
										</div>
									</div>
									<div class="d-flex aling-items-center h-100">
										<img src="assets/images/icons/command-color.png" alt="Command"
											class="img-fluid">
									</div>
								</div>
							</div>
						</div>
						<div class="col-12">
							<div class="bg-main-bright bg-opacity-70 p-4 rounded-1 w-100">
								<div class="d-flex justify-content-between align-items-center">
									<div class="d-flex align-itens-center gap-3">
										<div>
											<span
												class="count d-none d-lg-inline text-tertiary-to-primary-bt">05</span><span
												class="count d-lg-none text-tertiary-to-primary-bt">05</span>
										</div>
										<div class="d-flex align-items-center">
											<span class="fw-300">Desafios</span>
										</div>
									</div>
									<div class="d-flex aling-items-center h-100">
										<img src="assets/images/icons/flag-color.png" alt="Flag" class="img-fluid">
									</div>
								</div>
							</div>
						</div>
						<div class="col-12">
							<div class="bg-main-bright bg-opacity-70 p-4 rounded-1 w-100">
								<div class="d-flex justify-content-between align-items-center">
									<div class="d-flex align-itens-center gap-3">
										<div>
											<span
												class="count d-none d-lg-inline text-tertiary-to-primary-bt">06</span><span
												class="count d-lg-none text-tertiary-to-primary-bt">06</span>
										</div>
										<div class="d-flex align-items-center">
											<span class="fw-300">Trilhas para Certificações</span>
										</div>
									</div>
									<div class="d-flex aling-items-center h-100">
										<img src="assets/images/icons/award-color.png" alt="Award" class="img-fluid">
									</div>
								</div>
							</div>
						</div>
						<div class="col-12">
							<div class="bg-main-bright bg-opacity-70 p-4 rounded-1 w-100">
								<div class="d-flex justify-content-between align-items-center">
									<div class="d-flex align-itens-center gap-3">
										<div>
											<span
												class="count d-none d-lg-inline text-tertiary-to-primary-bt">07</span><span
												class="count d-lg-none text-tertiary-to-primary-bt">07</span>
										</div>
										<div class="d-flex align-items-center">
											<span class="fw-300">Simulados Práticos</span>
										</div>
									</div>
									<div class="d-flex aling-items-center h-100">
										<img src="assets/images/icons/box-color.png" alt="Box" class="img-fluid">
									</div>
								</div>
							</div>
						</div>
						<div class="col-12">
							<div class="bg-main-bright bg-opacity-70 p-4 rounded-1 w-100">
								<div class="d-flex justify-content-between align-items-center">
									<div class="d-flex align-itens-center gap-3">
										<div>
											<span
												class="count d-none d-lg-inline text-tertiary-to-primary-bt">08</span><span
												class="count d-lg-none text-tertiary-to-primary-bt">08</span>
										</div>
										<div class="d-flex align-items-center">
											<span class="fw-300">Grupo de Networking</span>
										</div>
									</div>
									<div class="d-flex aling-items-center h-100">
										<img src="assets/images/icons/briefcase-color.png" alt="Briefcase"
											class="img-fluid">
									</div>
								</div>
							</div>
						</div>
						<div class="col-12">
							<div class="bg-main-bright bg-opacity-70 p-4 rounded-1 w-100">
								<div class="d-flex justify-content-between align-items-center">
									<div class="d-flex align-itens-center gap-3">
										<div>
											<span
												class="count d-none d-lg-inline text-tertiary-to-primary-bt">09</span><span
												class="count d-lg-none text-tertiary-to-primary-bt">09</span>
										</div>
										<div class="d-flex align-items-center">
											<span class="fw-300">Aulas Extras Ao Vivo - Revisão</span>
										</div>
									</div>
									<div class="d-flex aling-items-center h-100">
										<img src="assets/images/icons/maximize-color.png" alt="Maximize"
											class="img-fluid">
									</div>
								</div>
							</div>
						</div>
						<div class="col-12">
							<div class="bg-main-bright bg-opacity-70 p-4 rounded-1 w-100">
								<div class="d-flex justify-content-between align-items-center">
									<div class="d-flex align-itens-center gap-3">
										<div>
											<span
												class="count d-none d-lg-inline text-tertiary-to-primary-bt">10</span><span
												class="count d-lg-none text-tertiary-to-primary-bt">10</span>
										</div>
										<div class="d-flex align-items-center">
											<span class="fw-300">Quick Notes</span>
										</div>
									</div>
									<div class="d-flex aling-items-center h-100">
										<img src="assets/images/icons/clipboard-color.png" alt="Clipboard"
											class="img-fluid">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-6">
				<div class="d-lg-none session-mt-sm"></div>
				<div class="d-flex flex-column gap-4" style="position: sticky; top: 7rem;">
					<div class="text-center">
						<span class="fs-1">
							O passo a passo para<br class="d-none d-xl-block">conquistar seu <span
								class="text-tertiary-to-primary-bt">objetivo <br class="d-none d-xl-block">com
								AWS</span>
						</span>
					</div>
					<div class="text-center">
						<span class="fs-2">
							Cadastre-se na Lista de Espera
						</span>
					</div>
					<div>
						<form class="d-flex flex-column gap-3 p-4" #formContent="ngForm"
							(ngSubmit)="onSubmit(formContent)">
							<div class="col-12">
								<cfa-input-text [label]="'Insira seu nome'" [classes]="'bg-main-bright bg-opacity-80'"
									[size]="'md'" [placeholder]="'Nome e sobrenome'" [prefixIcon]="'fa-regular fa-user'"
									[ngForm]="formContent" [disabled]="loading" [name]="'name'"
									[(value)]="object_content.name">
								</cfa-input-text>
							</div>
							<div class="col-12">
								<cfa-input-text [label]="'Insira seu e-mail'" [classes]="'bg-main-bright bg-opacity-80'"
									[name]="'seu-email'" [size]="'md'" [type]="'email'"
									pattern="[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
									[placeholder]="'seuemail@exemplo.com'" [prefixIcon]="'fa-regular fa-envelope'"
									[ngForm]="formContent" [disabled]="loading" [(value)]="object_content.email">
								</cfa-input-text>
							</div>
							<div class="col-12">
								<cfa-input-text [label]="'Insira seu WhatsApp'"
									[classes]="'bg-main-bright bg-opacity-80'" [size]="'md'" [name]="'whatsapp'"
									[placeholder]="'(00) 0 0000-0000'" [mask]="'(00) 0000-0000||(00) 0 0000-0000'"
									[prefixIcon]="'fa-solid fa-phone'" [ngForm]="formContent" [disabled]="loading"
									[(value)]="object_content.phone">
								</cfa-input-text>
							</div>
							<div class="col-12 mt-3">
								<cfa-btn [classes]="'w-100'" [type]="'submit'" [disabled]="loading">
									<ng-container *ngIf="!loading">
										Confirmar cadastro!
									</ng-container>
									<ng-container *ngIf="loading">
										Carregando
										<div class="d-inline-block opacity-animation">
											<span class="animated animated-0">.</span>
											<span class="animated animated-1">.</span>
											<span class="animated animated-2">.</span>
										</div>
									</ng-container>
								</cfa-btn>
							</div>
						</form>
						<ng-container *ngIf="error">
							<div class="row justify-content-center">
								<div class="col-12 col-md-6">
									<div class="bg-main mb-3 rounded-3 overflow-hidden w-100">
										<div class="bg-danger bg-opacity-10 px-4 py-3">
											<div class="d-flex flex-column gap-1">
												<div>
													<span class="text-danger fw-bold">Ocorreu um erro</span>
												</div>
												<div class="d-flex flex-column gap-2">
													<div class="">
														<ng-container *ngIf="!erro_msg">
															<small>Desculpe! Tente novamente...</small>
														</ng-container>
														<ng-container *ngIf="erro_msg">
															<small>{{erro_msg}}</small>
														</ng-container>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="session-mt-lg"></div>
<div class="">
	<div class="container">
		<div class="border-gradient border-secondary-bt-to-primary-bt rounded-3">
			<div class="bg-white-blur rounded-3 p-5">
				<div class="session-mt-sm"></div>
				<div class="row justify-content-center align-items-center">
					<div class="col-12 col-md-5 d-flex flex-column gap-3">
						<div class="text-center"><img src="assets/images/common/garantia.png" alt="Garantia"
								class="img-fluid">
						</div>
					</div>
					<div class="col-12 col-md-5 d-flex flex-column gap-3 pt-4 pt-md-0 ps-0 ps-md-4">
						<div class="text-center text-md-start">
							<span class="fs-2 ls-sm"> Garantia de
								<span class="text-tertiary-to-primary-bt"> 7 Dias</span>
							</span>
						</div>
						<div class="text-center text-md-start">
							<span class="fs-5 ls-sm opacity-60 mb-1"> Se você acessar o curso e não gostar da jornada
								proposta, você tem até 7 dias para pedir reembolso integral do valor investido.</span>
						</div>
					</div>
				</div>
				<div class="session-mt-sm"></div>
			</div>
		</div>
	</div>
	<div class="d-none d-lg-block radius-bg-primary-end" style="bottom: 10rem;"></div>
</div>





<div class="session-mt-lg"></div>
<feature-dan></feature-dan>
<div class="session-mt-lg"></div>
<div class="session-mt-lg d-md-none"></div>
<feature-certifications></feature-certifications>
<div class="session-mt-lg"></div>
<cfa-faq-feature [faqs]="faqs"></cfa-faq-feature>
<div class="session-mt-sm"></div>
<feature-back></feature-back>
<div class="session-mt-sm"></div>
<cfa-footer></cfa-footer>