import { Lab, Practice } from './../../../app/types/training';
import { Injectable } from '@angular/core';
import { FAQ } from '@app/types';
import { Instructor, Training } from '@app/types/training';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor() { }



  public trainings: Training[] = [
    new Training({
      type: "Treinamento",
      cloud: "AWS",
      bg: "assets/images/home/shape/tec.png",
      badge: "assets/images/home/badge/advc.png",
      title: "Método ADVC",
      route: "/curso/advc",
      external: false,
      instructor: new Instructor({
        name: "Dan Rezende",
        photo: "assets/images/home/instructor/dan-rezende.jpg",
        description: "Ex AWS 12x Certified"
      })
    }),
    // new Training({
    //   type: "Treinamento",
    //   cloud: "Azure",
    //   bg: "assets/images/home/shape/tec.png",
    //   badge: "assets/images/home/badge/jpa.png",
    //   title: "Jornada Profissional Azure",
    //   route: "/curso/jpa",
    //   external: false,
    //   instructor: new Instructor({
    //     name: "Valéria Baptista",
    //     photo: "assets/images/home/instructor/valeria-baptista.png",
    //     description: "Ex Microsoft 10x Certified"
    //   })
    // }),
  ]

  public immersions: Training[] = [
    new Training({
      type: "Imersão",
      cloud: "AWS",
      bg: "assets/images/home/shape/tec.png",
      badge: "assets/images/dan/certifications/cloud-practitioner.png",
      title: "Cloud Practitioner",
      route: "/imersao/ccp",
      external: false,
      instructor: new Instructor({
        name: "Dan Rezende",
        photo: "assets/images/home/instructor/dan-rezende.jpg",
        description: "Ex AWS 12x Certified"
      })
    }),
    new Training({
      type: "Imersão",
      cloud: "AWS",
      bg: "assets/images/home/shape/tec.png",
      badge: "assets/images/dan/certifications/solutions-architect-associate.png",
      title: "Solutions Architect",
      route: "/imersao/saa",
      external: false,
      instructor: new Instructor({
        name: "Dan Rezende",
        photo: "assets/images/home/instructor/dan-rezende.jpg",
        description: "Ex AWS 12x Certified"
      })
    }),
    new Training({
      type: "Imersão",
      cloud: "AWS",
      bg: "assets/images/home/shape/tec.png",
      badge: "assets/images/dan/certifications/developer-associate.png",
      title: "Developer Associate",
      route: "/imersao/cda",
      instructor: new Instructor({
        name: "Dan Rezende",
        photo: "assets/images/home/instructor/dan-rezende.jpg",
        description: "Ex AWS 12x Certified"
      })
    }),
    // new Training({
    //   type: "Imersão",
    //   cloud: "Azure",
    //   bg: "assets/images/home/shape/tec.png",
    //   badge: "assets/images/valeria-baptista/certifications/az-900.png",
    //   title: "AZ-900",
    //   route: "/imersao/az-900",
    //   external: false,
    //   instructor: new Instructor({
    //     name: "Valéria Baptista",
    //     photo: "assets/images/home/instructor/valeria-baptista.png",
    //     description: "Ex Microsoft 10x Certified"
    //   })
    // })
  ]

  public practices: Practice[] = [
    new Practice({
      cloud: "AWS",
      bg: "assets/images/home/shape/cloud.png",
      badge: "assets/images/home/badge-test/ccp.png",
      title: "Simulado AWS Cloud Practitioner",
      route: "https://api.whatsapp.com/send/?phone=5562991227374",
      external: true,
      time: 5400,
      qtdQuestions: 162
    }),
    new Practice({
      cloud: "AWS",
      bg: "assets/images/home/shape/cloud.png",
      badge: "assets/images/home/badge-test/saa.png",
      title: "Simulado AWS Solutions Architect Associate",
      route: "https://api.whatsapp.com/send/?phone=5562991227374",
      external: true,
      time: 7800,
      qtdQuestions: 130
    }),
    new Practice({
      cloud: "AWS",
      bg: "assets/images/home/shape/cloud.png",
      badge: "assets/images/home/badge-test/cda.png",
      title: "Simulado AWS Developer",
      route: "https://api.whatsapp.com/send/?phone=5562991227374",
      external: true,
      time: 7800,
      qtdQuestions: 65
    }),
    // new Practice({
    //   cloud: "Azure",
    //   bg: "assets/images/home/shape/cloud.png",
    //   badge: "assets/images/home/badge/jpa.png",
    //   title: "Simulado Jornada Profissional Azure - JPA",
    //   route: "https://api.whatsapp.com/send/?phone=5562991227374",
    //   external: true,
    //   time: 3600,
    //   qtdQuestions: 45
    // }),
    // new Practice({
    //   cloud: "Azure",
    //   bg: "assets/images/home/shape/cloud.png",
    //   badge: "assets/images/home/badge/az-900.png",
    //   title: "Simulado AZ-900 Microsoft Azure",
    //   route: "https://api.whatsapp.com/send/?phone=5562991227374",
    //   external: true,
    //   time: 3600,
    //   qtdQuestions: 136
    // }),
  ]

  public labs: Lab[] = [
    new Lab({
      cloud: "AWS",
      title: "Amazon S3 - Criando seu primeiro bucket",
      time: 3600,
    }),
    new Lab({
      cloud: "AWS",
      title: "Amazon EBS - Adicionando um novo volume EBS na Amazon EC2",
      time: 3600,
    }),
    new Lab({
      cloud: "AWS",
      title: "Amazon VPC - Conhecendo e configurando Security Group e Network ACL",
      time: 3600,
    }),
    new Lab({
      cloud: "AWS",
      title: "AWS Lambda - Interagindo back-end com DynamoDB",
      time: 3600,
    }),
    new Lab({
      cloud: "AWS",
      title: "Amazon CloudFront - Provisionando um site Web estático com S3, ACM, CloudFront e Route53",
      time: 5400,
    }),
  ]

  public faqs: FAQ[] = [
    new FAQ({
      question: 'Após ter feito a compra, como eu vou receber acesso no produto?',
      answer: 'O acesso à plataforma é enviado imediatamente.'
    }),
    new FAQ({
      question: 'As aulas são online, quantas vezes posso assistir?',
      answer: 'Quantas vezes quiser.'
    }),
    new FAQ({
      question: 'A renovação é automática?',
      answer: 'Não.'
    }),
    new FAQ({
      question: 'Posso dividir em dois cartões?',
      answer: 'Sim.'
    }),
    new FAQ({
      question: 'Se eu não gostar, posso cancelar a compra?',
      answer: 'Sim. Se você não gostar da jornada proposta, você pode solicitar o reembolso em até 7 dias.'
    }),
    new FAQ({
      question: 'Para acompanhar a mentoria, preciso saber inglês?',
      answer: 'Não. Toda mentoria é utilizada a língua portuguesa, apenas os serviços contém nomes em inglês.'
    }),
  ]
}