import { Component } from '@angular/core';

import {
  BtnComponent
} from "@components"

import {
  SliderDirective
} from "@directives"
import { KeenSliderOptions } from 'keen-slider';

@Component({
  selector: 'feature-certifications-valeria',
  standalone: true,
  imports: [
    BtnComponent,
    SliderDirective
  ],
  templateUrl: './certifications-valeria.component.html',
  styleUrl: './certifications-valeria.component.scss'
})
export class CertificationsValeriaComponent {


  public getSliderConfig(): KeenSliderOptions {
    return {
      mode: "free",
      slides: {
        perView: 5,
        spacing: 24
      },
      breakpoints: {
        '(max-width: 1400px)': {
          slides: {
            perView: 5,
            spacing: 24
          },
        },
        '(max-width: 990px)': {
          slides: {
            perView: 4.5,
            spacing: 24

          },
        },
        '(max-width: 767px)': {
          slides: {
            perView: 3,
            spacing: 24
          },
        },
        '(max-width: 500px)': {
          slides: {
            perView: 2.5,
            spacing: 24
          },
        },
      },
    }
  }
}
