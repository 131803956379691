<app-header></app-header>
<div id="home" class="home" #home>
	<div class="container">
		<div class="d-flex flex-column align-items-center gap-5">
			<div class="badge-img"></div>
			<div class="d-flex flex-column gap-4 w-100">
				<div class="lh-sm text-center">
					<span class="fs-0">
						O primeiro passo na sua jornada para se tornar um
						<span class="text-tertiary-to-primary-bt fw-500">
							Arquiteto de Valor Cloud
						</span>
					</span>
				</div>
				<div class="text-center">
					<span class="fs-4 text-opacity-50">
						Essa imersão pode te destravar rumo a
					</span>
					<span class="fs-4 text-opacity-80 fw-500">
						certificação
					</span>
				</div>
			</div>
		</div>
		<div class="session-mt-sm"></div>
	</div>
	<div class="d-none d-lg-block radius-bg-primary-start"></div>
	<div class="d-none d-lg-block radius-bg-primary-end" style="bottom: 20rem;"></div>
</div>
<div class="container">
	<div class="row justify-content-center ">
		<div class="col-auto bg-white-blur border border-white border-opacity-10 p-4_5">
			<div class="row align-items-center gap-5">
				<div class="col-12 col-lg-auto text-center">
					<cfa-btn-link [classes]="'w-100'" [href]="kiwify_link" [target]="'_blank'">
						<div class="py-3">
							<span class="fs-4 fw-300">
								Quero Assinar Agora! >
							</span>
						</div>
					</cfa-btn-link>
				</div>
				<div class="col-auto p-0 border border-left border-white border-opacity-10 h-60px d-none d-lg-block">
				</div>
				<div class="col-12 col-lg-auto d-flex align-items-center gap-3 justify-content-center">
					<div>
						<i class="fs-3 fa-brands fa-whatsapp text-success"></i>
					</div>
					<div class="d-flex flex-column">
						<div>
							<span class="fs-6 opacity-60">Tem alguma dúvida?</span>
						</div>
						<div>
							<span class="fs-5">Fale com nosso time</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row justify-content-center">
		<div class="col-12 col-lg-9 col-xl-8 pt-4">
			<div class="row justify-content-center align-items-center">
				<div class="col-12 col-md-6 text-center text-md-start">
					<span class="fs-5 opacity-75">
						Explore nossos Cursos e Imersões
					</span>
				</div>
				<div
					class="col-12 col-md-6 d-flex align-items-center justify-content-md-end justify-content-center gap-2 text-center text-md-end">
					<span>Arraste para o lado</span>
					<i class="text-secondary-bt-to-primary-bt fa-solid fa-arrow-right"></i>
				</div>
			</div>
		</div>
		<div class="col-12 pt-4">
			<cfa-carousel-courses></cfa-carousel-courses>
		</div>
	</div>
</div>
<div class="session-mt-sm"></div>
<div id="immersion" class="immersion" #immersion>
	<div class="d-flex flex-column gap-5">
		<div class="container">
			<div class="d-flex flex-column gap-3 text-center">
				<span class="fs-0">
					Imersão
					<span class="text-tertiary-to-primary-bt fw-500">{{ course_name }}</span>
				</span>
				<div>
					<span class="fs-4 opacity-75">
						As melhores dicas para você ser aprovado no exame de certificação
					</span>
				</div>
			</div>
		</div>
		<div class="immersion-video">
			<div class="container">
				<div class="video bg-main border border-main rounded-2">
					<iframe src="https://www.youtube.com/embed/VPhNs_vEdWI" title="YouTube video player" frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen></iframe>
				</div>
			</div>
		</div>
	</div>
	<div class="bg-main-bright">
		<div class="container">
			<div class="session-pt-sm"></div>
			<div class="row justify-content-center">
				<div class="col-12 col-md-10 col-lg-6 row align-items-center">
					<div class="col-auto">
						<div
							class="col-auto d-flex justify-content-center align-items-center bg-secondary-bt-to-primary-bt rounded-3 min-w-100px min-h-100px">
							<!-- <img src="assets/images/icons/calendar-check-line.png" alt="Calendario" width="46"
								height="46"> -->
							<i class="fa-solid fa-video fs-2"></i>
						</div>
					</div>
					<div class="col">
						<div>
							<span class="fs-5 fw-500">
								Tenha acesso a gravação da imersão!
							</span>
						</div>
						<!-- <div>
							<span class="fs-5 opacity-75">
								Dia 07/10 9hrs + Sessão extra de dúvidas
							</span>
						</div> -->
					</div>
				</div>
				<div class="col-12 col-md-10 col-lg-6 pt-3 pt-lg-0 row align-items-center">
					<div class="col-auto">
						<div
							class="col-auto d-flex justify-content-center align-items-center bg-secondary-bt-to-primary-bt rounded-3 min-w-100px min-h-100px">
							<img src="assets/images/icons/cloud-academy.png" alt="cloud" width="60" height="29">
						</div>
					</div>
					<div class="col">
						<div>
							<span class="fs-5 fw-500">Onde irá acontecer:</span>
						</div>
						<div>
							<span class="fs-5 opacity-75">
								Plataforma Exclusiva da CloudFaster Academy
							</span>
						</div>
					</div>
				</div>
				<div class="session-pt-sm"></div>
			</div>
		</div>
	</div>
</div>
<div id="topic" class="topic" #topic>
	<div class="session-mt-lg"></div>
	<div class="d-none d-lg-block radius-bg-primary" style="top: 30rem;"></div>

	<div class="container">

		<div class="row justify-content-center">
			<div class="col-12 col-lg-8">
				<div class="row gap-3 text-center text-lg-start" style="position: sticky; top: 7rem;">
					<div class="col-12">
						<span class="fs-1 lh-sm">
							Nessa imersão falaremos
							<br class="d-none d-lg-block">
							sobre os
							<span class="text-tertiary-to-primary-bt fw-500">seguintes assuntos</span>
						</span>
					</div>
					<div class="col-12">
						<span class="fs-4 opacity-75">
							Foco total na preparação do Exame
						</span>
					</div>
					<div class="col-12">

						<div class="session-mt-sm"></div>
						<div class="d-none d-lg-block arrow-down-lg-center"></div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-4">
				<div class="row gap-3">
					<div class="col-12">
						<div class="border-gradient rounded-3 border-tertiary-to-primary-bt">
							<div class="bg-white-blur rounded-3 p-5">
								<div class="d-flex flex-column align-items-center gap-3">
									<div
										class="icon-topic d-flex justify-content-center align-items-center bg-secondary-bt-to-primary-bt rounded-3">
										<img src="assets/images/icons/check-blocks.png" alt="check" width="50"
											height="50">
									</div>
									<div>
										<span class="fs-4 opacity-75">
											Revisão detalhada do guia do exame com dicas de otimização na preparação
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12">
						<div class="border-gradient rounded-3 border-tertiary-to-primary-bt">
							<div class="bg-white-blur rounded-3 p-5">
								<div class="d-flex flex-column align-items-center gap-3">
									<div
										class="icon-topic d-flex justify-content-center align-items-center bg-secondary-bt-to-primary-bt rounded-3">
										<img src="assets/images/icons/check-blocks.png" alt="check" width="50"
											height="50">
									</div>
									<div>
										<span class="fs-4 opacity-75">
											Revisão rápida e dicas dos principais serviços cobrados no exame, como VPC,
											EC2, RDS, S3, ELB, Auto Scaling
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="session-mt-sm"></div>
		<div class="row justify-content-center align-items-center d-none d-md-flex h-100">
			<ng-container *ngFor="let topic of topic_list">
				<div class="col-4 d-flex align-items-top" style="aspect-ratio: 4/3;">
					<div class="row justify-content-center align-items-top">
						<div
							class="col-12 icon-topic d-flex justify-content-center align-items-center bg-secondary-bt-to-primary-bt rounded-3">
							<img [src]="topic.image" alt="Topic" width="50" height="50">
						</div>
						<div class="text-center">
							<span class="fs-4 opacity-75" [innerHTML]="topic.text">
							</span>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
	<div class="d-md-none">
		<div class="d-flex align-items-center justify-content-center gap-2 text-center pb-4">
			<span>Arraste para o lado</span>
			<i class="text-secondary-bt-to-primary-bt fa-solid fa-arrow-right"></i>
		</div>
		<div cfaSlider [config]="getSliderTopicsConfig()">
			<ng-container *ngFor="let topic of topic_list">
				<div slide>
					<ng-container *ngIf="topic.select"></ng-container>
					<ng-container *ngIf="!topic.select">
						<div class="d-flex flex-column align-items-center gap-3">
							<div
								class="icon-topic d-flex justify-content-center align-items-center bg-secondary-bt-to-primary-bt rounded-3">
								<img [src]="topic.image" alt="topic" width="50" height="50">
							</div>
							<div class="text-center">
								<span class="fs-5" [innerHTML]="topic.text">
								</span>
							</div>
						</div>
					</ng-container>
				</div>
			</ng-container>
		</div>
	</div>
</div>
<div class="session-mt-md"></div>
<div id="solutions" class="solutions" #solutions>
	<div class="container">
		<div class="border-gradient rounded-3 border-tertiary-to-primary-bt">
			<div class="bg-white-blur rounded-3 p-5">
				<div class="row gx-5 justify-content-center">
					<div class="col-12 col-md-4">
						<div class="d-flex justify-content-center w-100">
							<div class="badge-cert"></div>
						</div>
					</div>
					<div class="col-12 col-md-6">
						<div class="d-flex flex-column justify-content-between gap-4 h-100">
							<div class="fs-3">
								<span class="opacity-75">
									Tudo o que você precisa fazer é garantir sua vaga para a&nbsp;
									<br class="d-none d-md-block">
								</span>
								<span>
									Imersão
									<span class="text-tertiary">{{ course_name }}</span>
								</span>
							</div>
							<div class="d-flex flex-column">
								<div>
									<span class="opacity-75">por apenas</span>
								</div>
								<div>
									<span class="fs-0">R$ 147,00</span>
								</div>
							</div>
							<div class="d-md-none">
								<cfa-btn-link [classes]="'w-100'" [href]="kiwify_link" [target]="'_blank'">
									<div class="py-2">
										<span class="fs-5 fw-300">
											Inscrever-se Agora
										</span>
									</div>
								</cfa-btn-link>
							</div>
							<div class="d-none d-md-block">
								<cfa-btn-link [classes]="'w-300px'" [href]="kiwify_link" [target]="'_blank'">
									<div class="py-2">
										<span class="fs-5 fw-300">
											Inscrever-se Agora
										</span>
									</div>
								</cfa-btn-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="session-mt-lg"></div>
<div id="content" class="content" #content>
	<div class="container">
		<div class="d-flex flex-column gap-3 text-center">
			<span class="fs-1 lh-sm">
				<b>Se inscrevendo agora</b> na
				<br class="d-none d-md-block">
				Imersão <span class="text-tertiary-to-primary-bt fw-500">{{ course_name }}</span>
				<br class="d-none d-md-block">
				você terá acesso a
			</span>
		</div>
	</div>
	<div class="session-mt-sm"></div>
	<div class="d-none d-lg-block radius-bg-primary-start" style="top: 50rem;"></div>
	<div class="d-none d-lg-block radius-bg-primary-end" style="top: 100rem;"></div>
	<div class="d-none d-lg-block radius-bg-primary-start" style="top: 150rem;"></div>
	<div class="d-none d-lg-block radius-bg-primary-end" style="top: 200rem;"></div>
	<div class="d-none d-lg-block radius-bg-primary-start" style="top: 250rem;"></div>
	<div class="container">
		<div class="row justify-content-center align-items-center">
			<div class="col-12 col-md-6 d-flex flex-column gap-3">
				<div class="text-center text-md-start">
					<i class="fs-1 text-secondary-bt-to-primary-bt fa-regular fa-circle-check"></i>
				</div>
				<div class="text-center text-md-start">
					<span class="fs-3 ls-sm">
						PDF com dicas de
						<br class="d-none d-md-block">
						estudo focado no
						<br class="d-none d-md-block">
						Exame de certificação
						<br class="d-none d-md-block">
						AWS
					</span>
				</div>
				<div class="text-center text-md-start">
					<span class="fs-3 ls-sm">
						Metodologia usada por
						<br class="d-none d-md-block">
						Dan Rezende para
						<br class="d-none d-md-block">
						<span class="text-tertiary-to-primary-bt fw-500">
							alcançar as 12
							<br class="d-none d-md-block">
							certificações
						</span>
					</span>
				</div>
				<div class="mt-xxl-4 d-none d-md-block">
					<div class="d-none d-md-block arrow-down-md-start"></div>

				</div>
			</div>
			<div class="col-5 pt-4 pt-md-0">
				<img src="assets/images/ccp/content/caderno.png" alt="caderno" class="img-fluid">
				<div class="d-block d-md-none py-4">
					<div class="arrow-down-md-center"></div>
				</div>
			</div>
		</div>
		<div class="session-mt-sm"></div>
		<div class="row justify-content-center align-items-center">
			<div class="col-12 col-md-6 col-lg-5 d-flex flex-column gap-3 order-md-2">
				<div class="text-center text-md-start">
					<i class="fs-1 text-secondary-bt-to-primary-bt fa-regular fa-circle-check"></i>
				</div>
				<div class="text-center text-md-start">
					<span class="fs-3 ls-sm">
						<b>Acesso a comunidade</b>
						<br class="d-none d-md-block">
						<b>privada do Discord</b>
						<br class="d-none d-md-block">
						focada em tirar dúvidas
						<br class="d-none d-md-block">
						da certificação
					</span>
				</div>
				<div class="mt-xxl-4 d-none d-md-block">
					<div class="d-none d-md-block arrow-down-md-start"></div>
				</div>
			</div>
			<div class="col-8 col-md-6 pt-4 pt-md-0 order-md-1">
				<div class="border-gradient border-secondary-bt-to-primary-bt rounded-4">
					<div class="bg-white-blur rounded-4 p-5">
						<div class="d-none d-md-block session-mt-sm"></div>
						<div class="d-flex flex-column align-items-center gap-3">
							<div class="w-75 text-center">
								<img src="assets/images/logos/discord.png" alt="discord" class="img-fluid">
							</div>
							<div class="w-75 text-center">
								<img src="assets/images/logos/dan-rezende.png" alt="Dan Rezende" class="img-fluid">
							</div>
						</div>
						<div class="d-none d-md-block session-mt-sm"></div>
					</div>
				</div>
				<div class="d-block d-md-none py-4">
					<div class="arrow-down-md-center"></div>
				</div>
			</div>
			<div class="col-md-1 d-none d-lg-block order-md-1"></div>
		</div>
		<div class="session-mt-sm"></div>
		<div class="row justify-content-center align-items-center">
			<div class="col-12 col-md-6 col-lg-5 d-flex flex-column gap-3 order-md-2">
				<div class="text-center text-md-start">
					<i class="fs-1 text-secondary-bt-to-primary-bt fa-regular fa-circle-check"></i>
				</div>
				<div class="text-center text-md-start">
					<span class="fs-3 ls-sm">
						Simulado prático
						<br class="d-none d-md-block">
						do Exame + Revisão
						<br class="d-none d-md-block">
						comentada das
						<br class="d-none d-md-block">
						questões
					</span>
				</div>
				<div class="text-center text-md-start">
					<span class="fs-3 ls-sm">
						<span class="text-tertiary-to-primary-bt fw-700">
							Acesso por 1 ano
						</span>
					</span>
				</div>
				<div class="mt-xxl-4 d-none d-md-block">
					<div class="d-none d-md-block arrow-down-md-start"></div>
				</div>
			</div>
			<div class="col-12 col-md-6 pt-4 pt-md-0 order-md-1">
				<div class="main-content text-center">
					<img src="assets/images/cda/example/notebook-cda.png" alt="notebook"
						class="img-content d-none d-md-block">
					<img src="assets/images/cda/example/notebook-cda-sm.png" alt="notebook"
						class="img-fluid d-md-none">
				</div>
				<div class="d-block d-md-none py-4">
					<div class="arrow-down-md-center"></div>
				</div>
			</div>
			<div class="col-md-1 d-none d-lg-block order-md-1"></div>
		</div>
		<div class="session-mt-sm"></div>
		<div class="row justify-content-center align-items-center">
			<div class="col-12 col-md-6 d-flex flex-column gap-3">
				<div class="text-center text-md-start">
					<i class="fs-1 text-secondary-bt-to-primary-bt fa-regular fa-circle-check"></i>
				</div>
				<div class="text-center text-md-start">
					<span class="fs-3 ls-sm">
						Plataforma exclusiva
						<br class="d-none d-md-block">
						de alunos
					</span>
				</div>
				<div class="text-center text-md-start">
					<span class="fs-3 ls-sm">
						Acesso a gravação da
						<br class="d-none d-md-block">
						Imersão para rever
						<br class="d-none d-md-block">
						quando quiser por
						<br class="d-none d-md-block">
						<span class="text-tertiary-to-primary-bt fw-500">
							12 meses
						</span>
					</span>
				</div>
				<div class="mt-xxl-4 d-none d-md-block">
					<div class="d-none d-md-block arrow-down-md-start"></div>

				</div>
			</div>
			<div class="col-5 pt-4 pt-md-0">
				<img src="assets/images/cda/example/phone-cda.png" alt="phone" class="img-fluid">
				<div class="d-block d-md-none py-4">
					<div class="arrow-down-md-center"></div>
				</div>
			</div>
		</div>
		<div class="session-mt-md"></div>
		<div class="border-gradient border-secondary-bt-to-primary-bt rounded-3">
			<div class="bg-white-blur rounded-3 p-5">
				<div class="session-mt-sm"></div>
				<div class="row justify-content-center align-items-center">
					<div class="col-12 col-md-5 d-flex flex-column gap-3">
						<div class="text-center">
							<img src="assets/images/common/garantia.png" alt="Garantia" class="img-fluid">
						</div>
					</div>
					<div class="col-12 col-md-5 d-flex flex-column gap-3 pt-4 pt-md-0 ps-0 ps-md-4">
						<div class="text-center text-md-start">
							<span class="fs-2 ls-sm">
								Garantia de <span class="text-tertiary-to-primary-bt">
									7 Dias
								</span>
							</span>
						</div>
						<div class="text-center text-md-start">
							<span class="fs-5 ls-sm opacity-60 mb-1">
								Se você acessar o curso e não gostar da jornada proposta, você tem até 7 dias para pedir
								reembolso integral do valor investido.
							</span>
						</div>
						<div class="text-center d-md-none">
							<span class="fs-5 ls-sm fw-700">
								Formas de Pagamento
							</span>
						</div>
						<div class="text-center d-md-none">
							<img src="assets/images/common/pay-options.png" alt="pagamento" class="img-fluid">
						</div>
						<div class="text-center d-md-none">
							<span class="fs-5 ls-sm">
								<i class="fs-4 text-secondary-bt-to-primary-bt fa-regular fa-circle-check"></i>&nbsp;
								Compra 100% segura
							</span>
						</div>
						<div class="text-start d-md-none">
							<cfa-btn-link [classes]="'w-100'" [href]="kiwify_link" [target]="'_blank'">
								<div class="py-2">
									<span class="fs-5 fw-300">
										Inscrever-se Agora
									</span>
								</div>
							</cfa-btn-link>
						</div>
					</div>
				</div>
				<div class="session-mt-sm"></div>
			</div>
		</div>
		<div class="session-mt-sm d-none d-md-block"></div>
		<div class="d-none d-md-block">
			<div class="row justify-content-center align-items-center">
				<div class="col text-center">
					<span class="fs-5 ls-sm fw-700">
						Formas de Pagamento
					</span>
				</div>
				<div class="col text-center">
					<img src="assets/images/common/pay-options.png" alt="pagamento" class="img-fluid">
				</div>
				<div class="col text-center">
					<span class="fs-5 ls-sm">
						<i class="fs-4 text-secondary-bt-to-primary-bt fa-regular fa-circle-check"></i>&nbsp;
						Compra 100% segura
					</span>
				</div>
				<div class="col-12 text-center">
					<div class="session-mt-sm"></div>
					<cfa-btn-link [classes]="'w-100'" [href]="kiwify_link" [target]="'_blank'">
						<div class="py-2">
							<span class="fs-5 fw-300">
								Inscrever-se Agora
							</span>
						</div>
					</cfa-btn-link>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="session-mt-lg"></div>
<feature-dan></feature-dan>
<div class="session-mt-lg"></div>
<div class="session-mt-lg d-md-none"></div>
<feature-certifications></feature-certifications>
<div class="session-mt-lg"></div>
<cfa-faq-feature [faqs]="faqs"></cfa-faq-feature>
<div class="session-mt-sm"></div>
<feature-back></feature-back>
<div class="session-mt-sm"></div>
<cfa-footer></cfa-footer>