export class Banner {
  title?: string;
  subtitle?: string;
  image?: string;
  background?: string;
  kiwifyLink?: string;

  constructor(config?: any) {
    if (config) {
      this.title = config.title !== undefined ? config.title : this.title;
      this.subtitle = config.subtitle !== undefined ? config.subtitle : this.subtitle;
      this.image = config.image !== undefined ? config.image : this.image;
      this.background = config.background !== undefined ? config.background : this.background;
      this.kiwifyLink = config.kiwifyLink !== undefined ? config.kiwifyLink : this.kiwifyLink;
    }
  }
}