<ng-template #dropModal let-modal>
  <div class="container h-100 shadow">
    <div class="w-100 h-100 p-4">
      <div class="header-link">
        <ng-container *ngFor="let link of linkModal">
          <div class="py-2">
            <ng-container *ngIf="link.isExternal">
              <a class="text-center" [ngClass]="{'selected' : link.link === url_match}" [href]="link.link" target="_blank"
                (click)="modal.close()">
                <span class=""><i class="fa-solid fa-chevron-right arrow fs-6"></i> &nbsp;</span>
                {{ link.name }}
              </a>
            </ng-container>
            <ng-container *ngIf="!link.isExternal">
              <ng-container *ngIf="link.isAnchor">
                <a class="text-center" [ngClass]="{'selected' : link.link === url_match}" [href]="link.link"
                  (click)="modal.close()">
                  <span class=""><i class="fa-solid fa-chevron-right arrow fs-6"></i> &nbsp;</span>
                  {{ link.name }}
                </a>
              </ng-container>
              <ng-container *ngIf="!link.isAnchor">
                <a class="text-center" [ngClass]="{'selected' : link.link === url_match}" [routerLink]="link.link"
                  (click)="modal.close()">
                  <span class=""><i class="fa-solid fa-chevron-right arrow fs-6"></i> &nbsp;</span>
                  {{ link.name }}
                </a>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modalMobile let-modal>
  <div class="container shadow">
    <div class="h-100 p-4">

      <ng-container *ngIf="!dropdown_mobile">
        <div class="header-link">
          <ng-container *ngFor="let link of linksMenu">
            <div class="py-2">
              <ng-container *ngIf="!link.dropLinks">
                <ng-container *ngIf="link.isExternal">
                  <a class="text-center" [ngClass]="{'selected' : link.link === url_match}" [href]="link.link" target="_blank"
                    (click)="modal.close()">
                    {{ link.name }}
                  </a>
                </ng-container>
                <ng-container *ngIf="!link.isExternal">
                  <ng-container *ngIf="link.isAnchor">
                    <a class="text-center" [ngClass]="{'selected' : link.link === url_match}" [href]="link.link"
                      (click)="modal.close()">
                      {{ link.name }}
                    </a>
                  </ng-container>
                  <ng-container *ngIf="!link.isAnchor">
                    <a class="text-center" [ngClass]="{'selected' : link.link === url_match}" [routerLink]="link.link"
                      (click)="goTop(link.name!); modal.close()">
                      {{ link.name }}
                    </a>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="link.dropLinks">
                <div class="drop-links" [ngClass]="{'selected' : link.link === url_match.split('/')[1]}" (click)="dropLinksMobile(link.dropLinks)">{{ link.name }}
                  <span class="">
                    <i class="fa-solid fa-chevron-down fs-6 arrow"></i>
                  </span>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="dropdown_mobile">
        <div class="pb-2">
          <cfa-btn (click)="dropLinksMobile('')" [variant]="'flat'" [classes]="'w-100 bg-white-blur'">
            <span>
              <i class="fa-solid fa-arrow-left"></i>&nbsp;
            </span> Voltar
          </cfa-btn>
        </div>
        <div class="header-link">
          <ng-container *ngFor="let link of mobile_links">
            <div class="py-2">
              <ng-container *ngIf="link.isExternal">
                <a class="text-center" [ngClass]="{'selected' : link.link === url_match}" [href]="link.link" target="_blank"
                  (click)="modal.close()">
                  <span class=""><i class="fa-solid fa-chevron-right arrow fs-6"></i> &nbsp;</span>
                  {{ link.name }}
                </a>
              </ng-container>
              <ng-container *ngIf="!link.isExternal">
                <ng-container *ngIf="link.isAnchor">
                  <a class="text-center" [ngClass]="{'selected' : link.link === url_match}" [href]="link.link"
                    (click)="modal.close()">
                    <span class=""><i class="fa-solid fa-chevron-right arrow fs-6"></i> &nbsp;</span>
                    {{ link.name }}
                  </a>
                </ng-container>
                <ng-container *ngIf="!link.isAnchor">
                  <a class="text-center" [ngClass]="{'selected' : link.link === url_match}" [routerLink]="link.link"
                    (click)="goTop(link.name!); modal.close()">
                    <span class=""><i class="fa-solid fa-chevron-right arrow fs-6"></i> &nbsp;</span>
                    {{ link.name }}
                  </a>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <div class="pt-2">
        <cfa-btn-link [href]="'https://plataforma.cloudfaster.academy/'" [target]="'_self'" [classes]="'w-100'">
          <span>
            <i class="fa-regular fa-user"></i>&nbsp;
          </span> Área do Aluno
        </cfa-btn-link>
      </div>
    </div>
  </div>
</ng-template>

<div class="header" [ngClass]="{'down':scroll_position > scroll_op_header}">
  <div class="background">
    <div class="container position-relative">
      <div class="d-flex justify-content-between align-items-center gap-5 py-3 py-lg-4">

        <div>
          <a [routerLink]="'/'" (click)="goTop('Home')">
            <img class="img-fluid" src="assets/images/logos/cloudfaster-academy.png" width="164" height="32"
              alt="CloudFaster">
          </a>
        </div>

        <div class="d-none d-lg-flex">
          <ng-container *ngFor="let link of linksMenu; let index = index">
            <div class="px-2 header-link">
              <ng-container *ngIf="!link.dropLinks">
                <ng-container *ngIf="link.isExternal">
                  <a class="text-center" [ngClass]="{'selected' : link.link === url_match}" [href]="link.link" target="_blank">
                    <span class="opacity-80">{{ link.name }}</span>
                  </a>
                </ng-container>
                <ng-container *ngIf="!link.isExternal">
                  <ng-container *ngIf="link.isAnchor">
                    <a class="text-center" [ngClass]="{'selected' : link.link === url_match}" [href]="link.link">
                      <span class="opacity-80">{{ link.name }}</span>
                    </a>
                  </ng-container>
                  <ng-container *ngIf="!link.isAnchor">
                    <a class="text-center" [ngClass]="{'selected' : link.link === url_match}" [routerLink]="link.link"
                      (click)="goTop(link.name!)">
                      <span class="opacity-80">{{ link.name }}</span>
                    </a>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="link.dropLinks">
                <div class="drop-links opacity-80" [ngClass]="{'selected' : link.link === url_match.split('/')[1]}" (click)="openModalLinks(dropModal, link)">{{ link.name }}
                  <span class="">
                    <i class="fa-solid fa-chevron-down fs-6"></i>
                  </span>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>

        <div class="d-none d-lg-block">
          <cfa-btn-link [href]="'https://plataforma.cloudfaster.academy/'" [target]="'_self'">
            <span>
              <i class="fa-regular fa-user"></i>&nbsp;
            </span> Área do Aluno
          </cfa-btn-link>
        </div>

        <div class="d-lg-none">
          <cfa-btn (click)="openModalMobile(modalMobile)">
            <i class="fa-solid fa-bars"></i>
          </cfa-btn>
        </div>

      </div>
    </div>
  </div>
</div>