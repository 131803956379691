export class Training {

  type?: "Treinamento" | "Mentoria" = "Treinamento";
  cloud?: "Azure" | "AWS" = "AWS"
  bg?: string
  badge?: string
  title?: string
  instructor?: Instructor
  route?: string
  external: boolean = false
  constructor(config?: any) {
    if (config) {
      this.type = config.type !== undefined ? config.type : this.type
      this.cloud = config.cloud !== undefined ? config.cloud : this.cloud
      this.bg = config.bg !== undefined ? config.bg : this.bg
      this.title = config.title !== undefined ? config.title : this.title
      this.instructor = config.instructor !== undefined ? new Instructor(config.instructor) : this.instructor
      this.route = config.route !== undefined ? config.route : this.route
      this.badge = config.badge !== undefined ? config.badge : this.badge
      this.external = config.external !== undefined ? config.external : this.external
    }
  }
}

export class Instructor {
  name?: string
  photo?: string
  description?: string
  constructor(config?: any) {
    if (config) {
      this.name = config.name !== undefined ? config.name : this.name
      this.photo = config.photo !== undefined ? config.photo : this.photo
      this.description = config.description !== undefined ? config.description : this.description
    }
  }
}


export class Practice {
  cloud?: "Azure" | "AWS" = "AWS"
  bg?: string
  badge?: string
  title?: string
  route?: string
  time: number = 0
  qtdQuestions: number = 0
  external: boolean = false
  constructor(config?: any) {
    if (config) {
      this.cloud = config.cloud !== undefined ? config.cloud : this.cloud
      this.bg = config.bg !== undefined ? config.bg : this.bg
      this.title = config.title !== undefined ? config.title : this.title
      this.route = config.route !== undefined ? config.route : this.route
      this.time = config.time !== undefined ? config.time : this.time
      this.qtdQuestions = config.qtdQuestions !== undefined ? config.qtdQuestions : this.qtdQuestions
      this.badge = config.badge !== undefined ? config.badge : this.badge
      this.external = config.external !== undefined ? config.external : this.external
    }
  }

  public get timeToString() {
    const TOTAL_MIN = Math.floor(this.time / 60)
    const HR = Math.floor(TOTAL_MIN / 60)
    const MIN = Math.floor(TOTAL_MIN % 60)
    if (MIN && HR) {
      return `${HR}h${MIN}`
    } else if (HR && !MIN) {
      return `${HR}h`
    } else {
      return `${MIN}min`
    }
  }
}
export class Lab {
  cloud?: "Azure" | "AWS" = "AWS"
  title?: string
  time: number = 0

  constructor(config?: any) {
    if (config) {
      this.cloud = config.cloud !== undefined ? config.cloud : this.cloud
      this.title = config.title !== undefined ? config.title : this.title
      this.time = config.time !== undefined ? config.time : this.time
    }
  }

  public get timeToString() {
    const TOTAL_MIN = Math.floor(this.time / 60)
    const HR = Math.floor(TOTAL_MIN / 60)
    const MIN = Math.floor(TOTAL_MIN % 60)
    if (MIN && HR) {
      return `${HR}h${MIN}`
    } else if (HR && !MIN) {
      return `${HR}h`
    } else {
      return `${MIN}min`
    }
  }
}
