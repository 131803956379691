import { Lab, Practice } from '@types';
import { Component } from '@angular/core';

import { AppModule } from '@core';
import { HomeService } from '@features/home/services';
import { SliderDirective } from '@directives';
import { BackComponent } from '../../../../app/shared/components/back/back.component';
import {
  TrainingCardComponent,
  GroupStudentsComponent,
  SignComponent,
  PracticeCardComponent,
  LabsCardComponent,
  ClassComponent,
  
} from '../../components'
import {
  FaqFeatureComponent, FooterComponent, HeaderComponent, FilterComponent
} from "@components"

import { Training } from '@app/types/training';
import { KeenSliderOptions } from 'keen-slider';
import { FAQ } from '@app/types';
import { NgbDropdown, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cfa-home',
  standalone: true,
  imports: [
    AppModule,
    TrainingCardComponent,
    GroupStudentsComponent,
    SignComponent,
    PracticeCardComponent,
    SliderDirective,
    LabsCardComponent,
    FaqFeatureComponent,
    BackComponent,
    ClassComponent,
    FooterComponent,
    HeaderComponent,
    FilterComponent,
    NgbDropdown
  ],
  providers: [
    NgbDropdownModule,
    NgbDropdown
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {

  public labelItems: string[] = ["Laboratórios", "Treinamentos", "Imersões", "Simulados"]
  public trainings: Training[] = []
  public immersions: Training[] = []
  public practices: Practice[] = []
  public labs: Lab[] = []
  public faqs: FAQ[] = []
  constructor(
    private HomeService: HomeService
  ) { }

  private getTrainings() {
    this.trainings = this.HomeService.trainings
  }
  private getImersions() {
    this.immersions = this.HomeService.immersions
  }
  private getPractices() {
    this.practices = this.HomeService.practices
  }
  private getLabs() {
    this.labs = this.HomeService.labs
  }
  private geFaqs() {
    this.faqs = this.HomeService.faqs
  }
  public getSliderTrainingConfig(): KeenSliderOptions {
    return {
      mode: "free-snap",
      breakpoints: {
        "(max-width: 990px)": {
          slides: {
            perView: 1.25,
            spacing: 24
          },
        },
        "(max-width: 450px)": {
          slides: {
            perView: 1.1,
            spacing: 24
          },
        },
        "(min-width: 990px)": {
          slides: {
            perView: 2.8,
            spacing: 24
          },
        }
      }
    }
  }
  public getSliderPracticeConfig(): KeenSliderOptions {
    return {
      mode: "free-snap",
      breakpoints: {
        "(max-width: 990px)": {
          slides: {
            perView: 1.25,
            spacing: 24
          },
        },
        "(max-width: 450px)": {
          slides: {
            perView: 1.1,
            spacing: 24
          },
        },
        "(min-width: 990px)": {
          slides: {
            perView: 1.35,
            spacing: 24
          },
        }
      }
    }
  }
  public getSliderDepoimmentConfig(): KeenSliderOptions {
    return {
      mode: "free-snap",
      breakpoints: {
        "(max-width: 990px)": {
          slides: {
            perView: 2,
            spacing: 24
          },
        },
        "(max-width: 450px)": {
          slides: {
            perView: 1.1,
            spacing: 24
          },
        },
        "(min-width: 990px)": {
          slides: {
            perView: 2.45,
            spacing: 24
          },
        }
      }
    }
  }
  ngOnInit() {
    window?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    })
    this.getTrainings();
    this.getImersions();
    this.getPractices();
    this.getLabs();
    this.geFaqs();
  }

}