import { Injectable } from '@angular/core';
import { FAQ } from 'app/types';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public faqs: FAQ[] = [
    new FAQ({
      question: 'Após ter feito a compra, como eu vou receber acesso no produto?',
      answer: 'O acesso à plataforma é enviado imediatamente.'
    }),
    new FAQ({
      question: 'Sou iniciante em Cloud, a Mentoria serve pra mim?',
      answer: 'Sim. A mentoria inicia com os pilares básicos de Cloud.'
    }),
    new FAQ({
      question: 'As aulas são online, quantas vezes posso assistir?',
      answer: 'Quantas vezes quiser.'
    }),
    new FAQ({
      question: 'Quanto tempo de acesso a mentoria?',
      answer: 'Sua jornada é de 12 meses.'
    }),
    new FAQ({
      question: 'Qual é o tipo de suporte que teria na mentoria?',
      answer: 'Você pode tirar dúvidas sobre cada aula em nossa plataforma, por email, discord ou ao vivo nas aulas periódicas com o Dan.'
    }),
    new FAQ({
      question: 'A renovação é automática?',
      answer: 'Não.'
    }),
    new FAQ({
      question: 'Adquirindo o treinamento, todas as aulas já estarão liberadas?',
      answer: 'Não. Esse é um programa de mentoria acompanhada, as aulas são liberadas gradativamente conforme o aluno avança na jornada de aprendizado e desbloqueia os próximos níveis de aprendizado.'
    }),
    new FAQ({
      question: 'Em quanto tempo vou ter resultados?',
      answer: 'A jornada foi criada com pequenas conquistas e resultados para que você possa acompanhar a sua evolução de forma gradativa.'
    }),
    new FAQ({
      question: 'Posso dividir em dois cartões?',
      answer: 'Sim.'
    }),
    new FAQ({
      question: 'Se eu não gostar, posso cancelar a compra?',
      answer: 'Sim. Se você não gostar da jornada proposta, você pode solicitar o reembolso em até 7 dias.'
    }),
    new FAQ({
      question: 'Vou demorar muito para ter resultado?',
      answer: 'Não. A jornada foi criada com pequenas conquistas e resultados para que você possa acompanhar a sua evolução de forma gradativa.'
    }),
    new FAQ({
      question: 'Para acompanhar a mentoria, preciso saber inglês?',
      answer: 'Não. Toda mentoria é utilizada a língua portuguesa, apenas os serviços contém nomes em inglês.'
    }),
    new FAQ({
      question: 'Preciso ter conceitos DevOPS para fazer a mentoria?',
      answer: 'Não. Ter conceitos DevOPS é um diferencial para atuação no mercado.'
    }),
    new FAQ({
      question: 'Estão disponíveis + de 100 laboratórios. Posso realizar quantas vezes cada?',
      answer: 'Vários laboratórios você pode repetir quantas vezes desejar, alguns laboratórios específicos que envolvem vários recursos, você poderá realizar 3x.'
    })
  ]

  constructor() { }
}
