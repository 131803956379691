import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FAQ } from '@app/types';

import {
  BtnLinkComponent,
  FaqCardComponent
} from '@components'

@Component({
  selector: 'cfa-faq-feature',
  standalone: true,
  imports: [
    CommonModule,
    BtnLinkComponent,
    FaqCardComponent
  ],
  templateUrl: './faq-feature.component.html',
  styleUrl: './faq-feature.component.scss'
})
export class FaqFeatureComponent {
  @Input() faqs: FAQ[] = []
}