import { Routes } from '@angular/router';

import * as Screens from './screens'

export const IMMERSIONS_ROUTES: Routes = [
  {
    path: 'ccp',
    component: Screens.CcpComponent
  },
  {
    path: 'saa',
    component: Screens.SaaComponent
  },
  {
    path: 'cda',
    component: Screens.CdaComponent
  },
  // {
  //   path: 'az-900',
  //   component: Screens.Az900Component
  // }
];