import { Injectable } from '@angular/core';
import { Menu } from '@app/types';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor() { }

  public menu: Menu[] = [
    new Menu({
      name: 'Home',
      link: '/',
      isExternal: false,
    }),
    new Menu({
      name: 'Cursos e Imersões',
      link: 'curso',
      dropLinks: [
        new Menu({
          name: 'Método ADVC',
          link: '/curso/advc',
          isExternal: false
        }),
        new Menu({
          name: 'Imersão Cloud Practitioner',
          link: '/imersao/ccp',
          isExternal: false
        }),
        new Menu({
          name: 'Imersão Solutions Architect',
          link: '/imersao/saa',
          isExternal: false
        }),
        new Menu({
          name: 'Imersão Developer Associate',
          link: '/imersao/cda',
          isExternal: false
        }),
        // new Menu({
        //   name: 'Jornada Profissional Azure - JPA',
        //   link: '/curso/jpa',
        //   isExternal: false
        // }),
        // new Menu({
        //   name: 'Imersão AZ-900',
        //   link: '/imersao/az-900',
        //   isExternal: false
        // }),
      ]
    }),
    new Menu({
      name: 'Simulados',
      link: 'simulado',
      dropLinks: [
        new Menu({
          name: 'AWS Cloud Practitioner',
          link: '/imersao/ccp',
          isExternal: false
        }),
        new Menu({
          name: 'AWS Solutions Architect Associate',
          link: '/imersao/saa',
          isExternal: false
        }),
        new Menu({
          name: 'AWS Developer Associate',
          link: '/imersao/cda',
          isExternal: false
        }),
        // new Menu({
        //   name: 'AZ-900 Microsoft Azure',
        //   link: '/imersao/az-900',
        //   isExternal: false
        // }),
      ]
    }),
    new Menu({
      name: 'Laboratórios',
      link: '#lab',
      isExternal: false,
      isAnchor: true,
    }),
  ]

}