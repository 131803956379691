<div class="faq" id="faq" #faq>
  <div class="container">
    <div class="row gy-5">
      <div class="col-12 col-lg-5">
        <div class="d-flex flex-column justify-content-between h-100">
          <div class="d-flex flex-column fs-1 lh-sm text-center text-lg-start" style="position: sticky; top: 7rem; margin-bottom: 5rem;">
            <span class="text-tertiary fw-500">FAQ</span>
            <span>Perguntas e <br class="d-none d-md-block">Respostas</span>
          </div>
          <div class="d-none d-lg-flex gap-3">
            <cfa-btn-link [classes]="' rounded-3 w-70px h-70px'"
              [href]="'https://www.instagram.com/cloudfaster.academy'" [target]="'_blank'" [theme]="'05'">
              <div class="d-flex justify-content-center align-items-center w-100 h-100">
                <img src="assets/images/faq/instagram-line.png" width="32" height="32" alt="Instagram">
              </div>
            </cfa-btn-link>
            <!-- <cfa-btn-link [classes]="' rounded-3 w-70px h-70px'" [href]="'https://www.facebook.com/odanrezende/'"
              [target]="'_blank'" [theme]="'05'">
              <div class="d-flex justify-content-center align-items-center w-100 h-100">
                <img src="assets/images/faq/facebook-circle-line.png" width="32" height="32" alt="Facebook">
              </div>
            </cfa-btn-link> -->
            <!-- <cfa-btn-link [classes]="' rounded-3 w-70px h-70px'" [href]="'https://www.youtube.com/@odanrezende'"
              [target]="'_blank'" [theme]="'05'">
              <div class="d-flex justify-content-center align-items-center w-100 h-100">
                <img src="assets/images/faq/youtube.png" width="26" height="22" alt="Youtube">
              </div>
            </cfa-btn-link> -->
            <cfa-btn-link [classes]="' rounded-3 w-70px h-70px'" [href]="'https://www.linkedin.com/school/cloudfaster/'"
              [target]="'_blank'" [theme]="'05'">
              <div class="d-flex justify-content-center align-items-center w-100 h-100">
                <img src="assets/images/faq/linkedin.png" width="32" height="32" alt="Linkedin">
              </div>
            </cfa-btn-link>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-7">
        <div class="row gy-3">
          <div *ngFor="let faq of faqs">
            <div class="col-12">
              <app-faq-card [faq]="faq"></app-faq-card>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 d-lg-none">
        <div class="d-flex justify-content-center gap-3">
          <cfa-btn-link [classes]="' rounded-3 w-70px h-70px'" [href]="'https://www.instagram.com/cloudfaster.academy'"
            [target]="'_blank'" [theme]="'05'">
            <div class="d-flex justify-content-center align-items-center w-100 h-100">
              <img src="assets/images/faq/instagram-line.png" width="32" height="32" alt="Instagram">
            </div>
          </cfa-btn-link>
          <!-- <cfa-btn-link [classes]="' rounded-3 w-70px h-70px'" [href]="'https://www.facebook.com/odanrezende/'"
            [target]="'_blank'" [theme]="'05'">
            <div class="d-flex justify-content-center align-items-center w-100 h-100">
              <img src="assets/images/faq/facebook-circle-line.png" width="32" height="32" alt="Facebook">
            </div>
          </cfa-btn-link> -->
          <!-- <cfa-btn-link [classes]="' rounded-3 w-70px h-70px'" [href]="'https://www.youtube.com/@odanrezende'"
            [target]="'_blank'" [theme]="'05'">
            <div class="d-flex justify-content-center align-items-center w-100 h-100">
              <img src="assets/images/faq/youtube.png" width="26" height="22" alt="Youtube">
            </div>
          </cfa-btn-link> -->
          <cfa-btn-link [classes]="' rounded-3 w-70px h-70px'" [href]="'https://www.linkedin.com/school/cloudfaster/'"
            [target]="'_blank'" [theme]="'05'">
            <div class="d-flex justify-content-center align-items-center w-100 h-100">
              <img src="assets/images/faq/linkedin.png" width="32" height="32" alt="Linkedin">
            </div>
          </cfa-btn-link>
        </div>
      </div>
    </div>
  </div>
</div>