import { Injectable } from '@angular/core';
import { Banner, FAQ, Topic, TopicSection, VideoSection } from '@types';

@Injectable({
  providedIn: 'root'
})
export class CcpService {

  constructor() { }

  public ccp_config = {
    name: 'Imersão Cloud Practitioner',
    kiwifyLink: 'https://pay.kiwify.com.br/2V7iFIm',
    banner: new Banner({
      title: 'O primeiro passo na sua jornada para se tornar um <span class="text-tertiary-to-primary-bt fw-500">Arquiteto de Valor Cloud</span>',
      subtitle: '<span class="fs-4 text-opacity-50">Tudo o que não te contaram sobre a</span>&nbsp;<span class="fs-4 fw-500">Nova Versão do Exame</span>',
      image: 'assets/images/dan/certifications/cloud-practitioner.png',
      background: 'assets/images/ccp/background/bg-ccp.png',
      kiwifyLink: 'https://pay.kiwify.com.br/2V7iFIm'
    }),
    videoSection: new VideoSection({
      title: 'Imersão <span class="text-tertiary-to-primary-bt fw-500">Cloud Practitioner</span>',
      subtitle: 'As melhores dicas para você ser aprovado no exame de certificação',
      link: 'https://www.youtube.com/embed/sT2gcIc5V38',
      isLive: false,
    }),
    topicSection: new TopicSection({
      title: 'Nessa imersão falaremos <br class="d-none d-md-block">sobre os <span class="text-tertiary-to-primary-bt fw-500">seguintes assuntos</span>',
      subtitle: 'Foco total na preparação do Exame',
      topics: [
        new Topic({
          image: "assets/images/icons/cloud.png",
          text: "<b>Conceitos</b> da nuvem",
          select: false
        }),
        new Topic({
          image: "assets/images/icons/folder-shield-line.png",
          text: "<b>Segurança</b> e Conformidade",
          select: false
        }),
        new Topic({
          image: "assets/images/icons/copy.png",
          text: "<b>Serviços</b> básicos AWS",
          select: false
        }),
        new Topic({
          image: "assets/images/icons/headphone.png",
          text: "<b>Precificação</b> e Suporte",
          select: false
        }),
        new Topic({
          image: "assets/images/icons/terminal-box-line.png",
          text: "<b>Conhecendo</b> a console da AWS",
          select: false
        }),
        new Topic({
          image: "assets/images/icons/git-merge-line.png",
          text: "<b>Provisionando</b> o primeiro serviço na AWS",
          select: false
        }),
        new Topic({
          image: "assets/images/icons/medal.png",
          text: "<b>Como fazer</b> o agendamento presencial ou remoto do Exame",
          select: false
        }),
        new Topic({
          image: "assets/images/icons/anonymous.png",
          text: "<b>Dicas secretas</b> para à aprovação no Exame",
          select: false
        }),
        new Topic({
          image: "assets/images/icons/layer.png",
          text: "<b>Resolução detalhada</b> e comentada de Simulado do Exame",
          select: false
        })
      ]
    })
  }
}