import { Routes } from '@angular/router';

import * as Screens from './screens'

export const COURSES_ROUTES: Routes = [
  {
    path:'advc',
    component:Screens.AdvcComponent
  },
  {
    path:'advc-lista-de-espera',
    component:Screens.AdvcWaitComponent
  },
  {
    path:'jpa',
    component:Screens.JpaComponent
  },
];