import { Routes } from '@angular/router';

import { HOME_ROUTES } from '@features/home/home.routes';
import { IMMERSIONS_ROUTES } from '@features/immersions/immersions.routes';
import { COURSES_ROUTES } from '@features/courses/courses.routes';
import { PRIVACY_POLICY_ROUTES } from '@features/privacy-policy/privacy-policy.routes';

export const routes: Routes = [
  {
    path: '',
    children: HOME_ROUTES,
    canActivate: []
  },
  {
    path: 'imersao',
    children: IMMERSIONS_ROUTES,
    canActivate: []
  },
  {
    path: 'curso',
    children: COURSES_ROUTES,
    canActivate: []
  },
  {
    path: 'politica-de-privacidade',
    children: PRIVACY_POLICY_ROUTES,
    canActivate: []
  },
  {
    path: "**",
    redirectTo: '/',
    pathMatch: 'full'
  }
];