import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImmersionsService } from '@features/immersions/services/immersions.service';
import { FaqFeatureComponent, BtnComponent, BtnLinkComponent, BackComponent, FooterComponent, CertificationsComponent, DanComponent, HeaderComponent, CarouselCoursesComponent, BannerComponent } from "@components";
import { HomeService } from '@features/home/services';
import { CourseRoute } from '@app/types/courses';
import { KeenSliderOptions } from 'keen-slider';
import { SliderDirective } from '@directives';
import { FAQ, Topic } from '@types';
import { SaaService } from '@features/immersions/services/saa.service';

@Component({
  selector: 'cfa-saa',
  standalone: true,
  imports: [
    BtnComponent,
    SliderDirective,
    CommonModule,
    FooterComponent,
    FaqFeatureComponent,
    BackComponent,
    CertificationsComponent,
    BtnLinkComponent,
    HeaderComponent,
    DanComponent,
    CarouselCoursesComponent,
    BannerComponent
  ],
  templateUrl: './saa.component.html',
  styleUrl: './saa.component.scss'
})
export class SaaComponent implements OnInit {

  public config: any;
  public faqs: FAQ[] = [];
  public topic_list: Topic[] = [];
  public kiwify_link: string = 'https://pay.kiwify.com.br/NYTidxl';

  constructor(
    private SaaService: SaaService,
    private ImmersionsService: ImmersionsService,
    private HomeService: HomeService
  ) { }

  private getConfig() {
    this.config = this.SaaService.saa_config;
  }

  public getSliderCourseConfig(): KeenSliderOptions {
    return {
      mode: "free-snap",
      breakpoints: {
        "(max-width: 1200px)": {
          slides: {
            perView: 2,
            spacing: 24
          },
        },
        "(max-width: 768px)": {
          slides: {
            perView: 1,
            spacing: 24
          },
        },
        "(min-width: 1200px)": {
          slides: {
            perView: 3,
            spacing: 24
          },
        }
      }
    }
  }

  public getSliderTopicsConfig(): KeenSliderOptions {
    return {
      mode: "free",
      slides: {
        perView: 2,
        spacing: 10,
        origin: "center"
      }
    }
  }

  private getFaqs() {
    this.faqs = this.ImmersionsService.faqs;
  }

  private getTopics() {
    this.topic_list = this.ImmersionsService.topic_saa;
  }

  ngOnInit(): void {
    window?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    })
    this.getConfig();
    this.getFaqs();
    this.getTopics();
  }
}