<div class="bg-main-bright py-5 w-100">
  <div class="container">
    <div class="row gy-4 lh-sm justify-content-center justify-content-lg-between">
      <div class="d-lg-none col-5">
        <div class="d-flex justify-content-center align-items-center h-100">
          <img src="assets/images/logos/cloudfaster-academy.png" width="164" height="32" alt="CloudFaster Academy">
        </div>
      </div>
      <div class="col-12 col-lg col-xl-auto">
        <div class="d-flex justify-content-center align-items-center text-center text-md-start h-100">
          <small class="fw-300 fs-6 text-opacity-75">Copyright © {{ano}}<br class="d-none d-lg-block"> Todos os direitos
            reservados</small>
        </div>
      </div>
      <div class="col-12 col-lg-auto">
        <div class="d-flex justify-content-center align-items-center text-center text-md-start h-100">
          <small class="fw-300 fs-6 text-opacity-75">Feito com ♡ por<br class="d-none d-lg-block"> CloudFaster
            Academy</small>
        </div>
      </div>
      <div class="col-12 col-lg-auto">
        <div class="d-flex justify-content-center align-items-center text-center text-md-start h-100">
          <small class="fw-300 fs-6 text-opacity-75">CNPJ: 26.708.607/0001-14</small>
        </div>
      </div>
      <div class="col-12 col-lg-auto">
        <div class="d-flex justify-content-center align-items-center text-center text-md-start h-100">
          <a [routerLink]="'/politica-de-privacidade'" class="text-white footer-link">
            <small class="fw-300 fs-6 text-opacity-75">Política de Privacidade<br class="d-none d-lg-block">e
              Cookies</small>
          </a>
        </div>
      </div>
      <div class="col-2 d-none d-lg-block">
        <div class="d-flex justify-content-center align-items-md-center h-100">
          <img class="img-fluid" src="assets/images/logos/cloudfaster-academy.png" width="164" height="32"
            alt="CloudFaster Academy">
        </div>
      </div>
    </div>
  </div>
</div>