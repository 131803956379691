export class CourseRoute {
  name?:string;
  route?:string;
  select?:boolean;

  constructor(config?:any){
    if(config){
      this.name = config.name !== undefined ? config.name : this.name;
      this.route = config.route !== undefined ? config.route : this.route;
      this.select = config.select !== undefined ? config.select : this.select;
    }
  }
}