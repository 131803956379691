export class Menu {
  name?: string;
  link?: string;
  isExternal?: boolean;
  isAnchor?: boolean;
  dropLinks?: Menu[];

  constructor(config?:any){
    if(config){
      this.name = config.name !== undefined ? config.name : this.name;
      this.link = config.link !== undefined ? config.link : this.link;
      this.isExternal = config.isExternal !== undefined ? config.isExternal : this.isExternal;
      this.isAnchor = config.isAnchor !== undefined ? config.isAnchor : this.isAnchor;
      this.dropLinks = config.dropLinks !== undefined ? config.dropLinks : this.dropLinks;
    }
  }
}