<div>
  <form class="d-flex gap-3" #formFilter="ngForm" (ngSubmit)="onSubmit(formFilter)">
    <cfa-btn [variant]="'flat'">
      <span class="d-flex align-items-center" ngbDropdownToggle>
        <img class="img-fluid h-100 me-2" src="assets/images/icons/filter-color.png">Filtro
      </span>
    </cfa-btn>
    <div ngbDropdownMenu aria-labelledby="dropdown" class="bg-white-blur bg-opacity-10 border border-white border-opacity-50 position-absolute"
      style="margin-top: 4rem; ">
      <ng-container *ngFor="let item of filterOptions">
        <button ngbDropdownItem type="button" (click)="onClickOption(item.value)">{{item.name}}</button>
      </ng-container>
    </div>
    <div class="w-250px">
      <cfa-input-text [classes]="'bg-main-bright bg-opacity-80 rounded-3'" [name]="'search'" [ngForm]="formFilter"
        [size]="'md'" [type]="'text'" [placeholder]="'Pesquisar...'" [prefixIcon]="'fa-solid fa-magnifying-glass'"
        [(value)]="searchWord" [minlength]="0" [required]="false" (change)="onChangeSearch()">
      </cfa-input-text>
    </div>
  </form>
</div>