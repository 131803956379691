import { Topic } from "./topic";

export class TopicSection {
  title!: string;
  subtitle!: string;
  topics!: Topic[];
  
  constructor(config: any) {
    if (config) {
      this.title = config.title !== undefined ? config.title : this.title;
      this.subtitle = config.subtitle !== undefined ? config.subtitle : this.subtitle;
      this.topics = config.topics !== undefined ? config.topics : this.topics;
    }
  }
}