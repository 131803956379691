import { Injectable } from '@angular/core';
import { CourseRoute } from '@app/types/courses';
import { FAQ, Topic } from '@types';

@Injectable({
  providedIn: 'root'
})
export class ImmersionsService {

  constructor() { }

  public course_list: CourseRoute[] = [
    new CourseRoute({
      name: "Imersão Cloud Practitioner",
      route: "/imersao/ccp",
      select: false
    }),
    new CourseRoute({
      name: "Imersão Solutions Architect",
      route: "/imersao/saa",
      select: false
    }),
    new CourseRoute({
      name: "Imersão Developer Associate",
      route: "/imersao/cda",
      select: false
    }),
    new CourseRoute({
      name: "Método ADVC",
      route: "/curso/advc",
      select: false
    }),
    // new CourseRoute({
    //   name: "Jornada Profisional Azure",
    //   route: "/curso/jpa",
    //   select: false
    // }),
    // new CourseRoute({
    //   name: "Imersão AZ-900",
    //   route: "/imersao/az-900",
    //   select: false
    // }),
  ];

  public topic_ccp: Topic[] = [
    new Topic({
      image: "assets/images/icons/cloud.png",
      text: "<b>Conceitos</b> da nuvem",
      select: false
    }),
    new Topic({
      image: "assets/images/icons/folder-shield-line.png",
      text: "<b>Segurança</b> e Conformidade",
      select: false
    }),
    new Topic({
      image: "assets/images/icons/copy.png",
      text: "<b>Serviços</b> básicos AWS",
      select: false
    }),
    new Topic({
      image: "assets/images/icons/headphone.png",
      text: "<b>Precificação</b> e Suporte",
      select: false
    }),
    new Topic({
      image: "assets/images/icons/terminal-box-line.png",
      text: "<b>Conhecendo</b> a console da AWS",
      select: false
    }),
    new Topic({
      image: "assets/images/icons/git-merge-line.png",
      text: "<b>Provisionando</b> o primeiro serviço na AWS",
      select: false
    }),
    new Topic({
      image: "assets/images/icons/medal.png",
      text: "<b>Como fazer</b> o agendamento presencial ou remoto do Exame",
      select: false
    }),
    new Topic({
      image: "assets/images/icons/anonymous.png",
      text: "<b>Dicas secretas</b> para à aprovação no Exame",
      select: false
    }),
    new Topic({
      image: "assets/images/icons/layer.png",
      text: "<b>Resolução detalhada</b> e comentada de Simulado do Exame",
      select: false
    }),
  ];

  public topic_az_900: Topic[] = [
    new Topic({
      image: "assets/images/icons/cloud.png",
      text: "<b>Conceitos</b> da nuvem",
      select: false
    }),
    new Topic({
      image: "assets/images/icons/folder-shield-line.png",
      text: "<b>Segurança</b> e Conformidade",
      select: false
    }),
    new Topic({
      image: "assets/images/icons/copy.png",
      text: "<b>Serviços</b> básicos Azure",
      select: false
    }),
    new Topic({
      image: "assets/images/icons/headphone.png",
      text: "<b>Precificação</b> e Suporte",
      select: false
    }),
    new Topic({
      image: "assets/images/icons/terminal-box-line.png",
      text: "<b>Conhecendo</b> o portal Azure",
      select: false
    }),
    new Topic({
      image: "assets/images/icons/git-merge-line.png",
      text: "<b>Provisionando</b> o primeiro serviço na Azure",
      select: false
    }),
    new Topic({
      image: "assets/images/icons/medal.png",
      text: "<b>Como fazer</b> o agendamento presencial ou remoto do Exame",
      select: false
    }),
    new Topic({
      image: "assets/images/icons/anonymous.png",
      text: "<b>Dicas secretas</b> para à aprovação no Exame",
      select: false
    }),
    new Topic({
      image: "assets/images/icons/layer.png",
      text: "<b>Resolução detalhada</b> e comentada de Simulado do Exame",
      select: false
    }),
  ];

  public topic_saa: Topic[] = [
    new Topic({
      image: "assets/images/icons/medal.png",
      text: "<b>Como fazer</b> o agendamento presencial ou remoto do Exame",
      select: false
    }),
    new Topic({
      image: "assets/images/icons/anonymous.png",
      text: "<b>Dicas secretas</b> para à aprovação no Exame",
      select: false
    }),
    new Topic({
      image: "assets/images/icons/layer.png",
      text: "<b>Resolução detalhada</b> e comentada de Simulado do Exame",
      select: false
    }),
  ];

  public faqs: FAQ[] = [
    new FAQ({
      question: 'Após ter feito a compra, como eu vou receber acesso no produto?',
      answer: 'O acesso à plataforma é enviado imediatamente.'
    }),
    new FAQ({
      question: 'As aulas são online, quantas vezes posso assistir?',
      answer: 'Quantas vezes quiser.'
    }),
    new FAQ({
      question: 'Quanto tempo de acesso?',
      answer: 'Sua jornada é de 12 meses.'
    }),
    new FAQ({
      question: 'Posso dividir em dois cartões?',
      answer: 'Sim.'
    }),
    new FAQ({
      question: 'Se eu não gostar, posso cancelar a compra?',
      answer: 'Sim. Se você não gostar da jornada proposta, você pode solicitar o reembolso em até 7 dias.'
    })
  ];
}