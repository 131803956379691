import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AppModule } from 'app/core';
import { CommonService } from '@services';
import { KeenSliderOptions } from 'keen-slider';
import { SliderDirective } from '@directives';
import { RouterModule } from '@angular/router';
import { CourseRoute } from '@types';
import { ImmersionsService } from '@features/immersions/services';
import { BtnComponent } from '@components';
import { InputTextComponent } from '../input-text/input-text.component';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cfa-filter',
  standalone: true,
  imports: [
    AppModule,
    RouterModule,
    SliderDirective,
    BtnComponent,
    InputTextComponent,
    FormsModule,
    ReactiveFormsModule,
    NgbDropdownModule,
  ],
  providers: [
    CommonService,
    NgbDropdownModule,
  ],
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss'
})
export class FilterComponent implements OnInit {

  @Input() filterSelect?: any;
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() search: EventEmitter<string> = new EventEmitter<string>()
  @Input() searchWord!: string;
  @Output() searchWordChange: EventEmitter<string> = new EventEmitter<string>()
  @Input() disabled: boolean = false;

  constructor(
    private CommonService: CommonService,
  ) { }

  public filterOptions = [
    {
      name: "Aws",
      value: "aws"
    },
    {
      name: "Azure",
      value: "azure"
    },
    {
      name: "Todos",
      value: undefined
    }
  ]

  // SearchWord ↓↓
  public onSearch() {
    this.search.emit(this.searchWord);
  }
  public onChangeSearch() {
    this.searchWordChange.emit(this.searchWord);
    if (this.searchWord.length === 0) this.search.emit(this.searchWord)
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']?.currentValue !== undefined) {
      this.disabled = changes['disabled']?.currentValue;
    }
  }
  // SearchWord ↑↑

  public onClickOption(vl: any) {
    console.log(vl)
    console.log(this.onClick.emit())
    this.onClick.emit();
  }

  public onSubmit(form: NgForm) {
    console.log(form.value)
  }

  ngOnInit(): void {
  }

}