import { Injectable } from '@angular/core';
import { FAQ, Topic, Mentoring, CourseRoute } from '@types';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {

  constructor() { }

  public advc_mentoring: Mentoring[] = [
    new Mentoring({
      text: '<span class="fw-500">Primeiro</span> Emprego em Cloud',
      image: 'assets/images/advc/content/primeiro-emprego.png',
      icon: 'assets/images/icons/weather-color.png'
    }),
    new Mentoring({
      text: '<span class="fw-500">Certificações</span>',
      image: 'assets/images/advc/content/certificacao.png',
      icon: 'assets/images/icons/medal-color.png'
    }),
    new Mentoring({
      text: '<span class="fw-500">Me Destacar</span> na Empresa',
      image: 'assets/images/advc/content/destacar.png',
      icon: 'assets/images/icons/star-user-color.png'
    }),
    new Mentoring({
      text: '<span class="fw-500">Evolução</span> de Salário',
      image: 'assets/images/advc/content/salario.png',
      icon: 'assets/images/icons/graph-color.png'
    }),
    new Mentoring({
      text: '<span class="fw-500">Consultor</span> Independente',
      image: 'assets/images/advc/content/consultor.png',
      icon: 'assets/images/icons/check-color.png'
    }),
  ];

  public jpa_mentoring: Mentoring[] = [
    new Mentoring({
      text: '<span class="fw-500">Primeiro</span> Emprego em Cloud',
      image: 'assets/images/advc/content/primeiro-emprego.png',
      icon: 'assets/images/icons/weather-color.png'
    }),
    new Mentoring({
      text: '<span class="fw-500">Certificações</span>',
      image: 'assets/images/advc/content/certificacao.png',
      icon: 'assets/images/icons/medal-color.png'
    }),
    new Mentoring({
      text: '<span class="fw-500">Me Destacar</span> na Empresa',
      image: 'assets/images/advc/content/destacar.png',
      icon: 'assets/images/icons/star-user-color.png'
    }),
    new Mentoring({
      text: '<span class="fw-500">Evolução</span> de Salário',
      image: 'assets/images/advc/content/salario.png',
      icon: 'assets/images/icons/graph-color.png'
    }),
    new Mentoring({
      text: '<span class="fw-500">Consultor</span> Independente',
      image: 'assets/images/advc/content/consultor.png',
      icon: 'assets/images/icons/check-color.png'
    }),
  ];

  public advc_faqs: FAQ[] = [
    new FAQ({
      question: 'Após ter feito a compra, como eu vou receber acesso no produto?',
      answer: 'O acesso à plataforma é enviado imediatamente.'
    }),
    new FAQ({
      question: 'As aulas são online, quantas vezes posso assistir?',
      answer: 'Quantas vezes quiser.'
    }),
    new FAQ({
      question: 'Quanto tempo de acesso?',
      answer: 'Sua jornada é de 12 meses.'
    }),
    new FAQ({
      question: 'Posso dividir em dois cartões?',
      answer: 'Sim.'
    }),
    new FAQ({
      question: 'Se eu não gostar, posso cancelar a compra?',
      answer: 'Sim. Se você não gostar da jornada proposta, você pode solicitar o reembolso em até 7 dias.'
    })
  ];

  public jpa_faqs: FAQ[] = [
    new FAQ({
      question: 'Após ter feito a compra, como eu vou receber acesso no produto?',
      answer: 'O acesso à plataforma é enviado imediatamente.'
    }),
    new FAQ({
      question: 'As aulas são online, quantas vezes posso assistir?',
      answer: 'Quantas vezes quiser.'
    }),
    new FAQ({
      question: 'Quanto tempo de acesso?',
      answer: 'Sua jornada é de 12 meses.'
    }),
    new FAQ({
      question: 'Posso dividir em dois cartões?',
      answer: 'Sim.'
    }),
    new FAQ({
      question: 'Se eu não gostar, posso cancelar a compra?',
      answer: 'Sim. Se você não gostar da jornada proposta, você pode solicitar o reembolso em até 7 dias.'
    })
  ];
}