<div id="certifications" class="certifications" #certifications>
	<div class="container">
		<div class="d-flex flex-column justify-content-between align-items-center align-items-xl-start gap-5">
			<div class="text-center text-xl-start">
				<span class="fs-1 fw-500 text-center lh-sm" style="">
					Valéria atualmente possui <br class="d-none d-md-block">
					<span class="text-tertiary-to-primary-bt">
						10 certificações da Microsoft!
					</span>
				</span>
			</div>
			<div class="d-flex align-items-center gap-2 text-center fs-5">
				<span class>Arraste para o lado</span>
				<i class="text-tertiary-to-primary-bt fa-solid fa-arrow-right"></i>
			</div>
		</div>
		<div class="session-mt-sm"></div>
		<div class="carousel-certifications">
			<div cfaSlider [config]="getSliderConfig()">
				<div slide>
					<cfa-btn [classes]="'button rounded-4'" [variant]="'flat'">
						<img class="max-w-100 h-100 img-fluid" width="300" height="300"
							src="assets/images/valeria-baptista/certifications/fundamentals.png" alt="Fundamentals">
					</cfa-btn>
				</div>
				<div slide>
					<cfa-btn [classes]="'button rounded-4'" [variant]="'flat'">
						<img class="max-w-100 h-100 img-fluid" width="300" height="300"
							src="assets/images/valeria-baptista/certifications/data-fundamentals.png"
							alt="Data Fundamentals">
					</cfa-btn>
				</div>
				<div slide>
					<cfa-btn [classes]="'button rounded-4'" [variant]="'flat'">
						<img class="max-w-100 h-100 img-fluid" width="300" height="300"
							src="assets/images/valeria-baptista/certifications/security-fundamentals.png"
							alt="Security Fundamentals">
					</cfa-btn>
				</div>
				<div slide>
					<cfa-btn [classes]="'button rounded-4'" [variant]="'flat'">
						<img class="max-w-100 h-100 img-fluid" width="300" height="300"
							src="assets/images/valeria-baptista/certifications/az-900.png" alt="AZ-900">
					</cfa-btn>
				</div>
				<div slide>
					<cfa-btn [classes]="'button rounded-4'" [variant]="'flat'">
						<img class="max-w-100 h-100 img-fluid" width="300" height="300"
							src="assets/images/valeria-baptista/certifications/security-associate.png"
							alt="Security Associate">
					</cfa-btn>
				</div>
				<div slide>
					<cfa-btn [classes]="'button rounded-4'" [variant]="'flat'">
						<img class="max-w-100 h-100 img-fluid" width="300" height="300"
							src="assets/images/valeria-baptista/certifications/security-engineer-associate.png"
							alt="Security Engineer Associate">
					</cfa-btn>
				</div>
				<div slide>
					<cfa-btn [classes]="'button rounded-4'" [variant]="'flat'">
						<img class="max-w-100 h-100 img-fluid" width="300" height="300"
							src="assets/images/valeria-baptista/certifications/administrator-associate.png"
							alt="Administrator Associate">
					</cfa-btn>
				</div>
				<div slide>
					<cfa-btn [classes]="'button rounded-4'" [variant]="'flat'">
						<img class="max-w-100 h-100 img-fluid" width="300" height="300"
							src="assets/images/valeria-baptista/certifications/solutions-architect-expert.png"
							alt="Solutions Architect Expert">
					</cfa-btn>
				</div>
				<div slide>
					<cfa-btn [classes]="'button rounded-4'" [variant]="'flat'">
						<img class="max-w-100 h-100 img-fluid" width="300" height="300"
							src="assets/images/valeria-baptista/certifications/cybersecurity-architect-expert.png"
							alt="Cybersecurity Architect Expert">
					</cfa-btn>
				</div>
				<div slide>
					<cfa-btn [classes]="'button rounded-4'" [variant]="'flat'">
						<img class="max-w-100 h-100 img-fluid" width="300" height="300"
						src="assets/images/valeria-baptista/certifications/mct.png"
						alt="MCT">
					</cfa-btn>
				</div>
			</div>
		</div>
	</div>
</div>