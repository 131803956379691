import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CcpService } from '@features/immersions/services/ccp.service';
import { FaqFeatureComponent, VideoSectionComponent, TopicSectionComponent, BtnComponent, BtnLinkComponent, BackComponent, FooterComponent, CertificationsComponent, DanComponent, HeaderComponent, CarouselCoursesComponent, BannerComponent } from "@components";
import { KeenSliderOptions } from 'keen-slider';
import { SliderDirective } from '@directives';
import { RouterModule } from '@angular/router';
import { FAQ } from '@types';
import { ImmersionsService } from '@features/immersions/services';

@Component({
  selector: 'cfa-ccp',
  standalone: true,
  imports: [
    BtnComponent,
    SliderDirective,
    CommonModule,
    FooterComponent,
    FaqFeatureComponent,
    BackComponent,
    CertificationsComponent,
    BtnLinkComponent,
    HeaderComponent,
    DanComponent,
    RouterModule,
    CarouselCoursesComponent,
    BannerComponent,
    TopicSectionComponent,
    VideoSectionComponent
  ],
  templateUrl: './ccp.component.html',
  styleUrl: './ccp.component.scss'
})
export class CcpComponent implements OnInit {

  public config: any;
  public faq: FAQ[] = [];

  constructor(
    private CcpService: CcpService,
    private ImmersionsService: ImmersionsService
  ) { }

  private getConfig() {
    this.config = this.CcpService.ccp_config;
  }

  private getFaq() {
    this.faq = this.ImmersionsService.faqs;
  }

  public getSliderCourseConfig(): KeenSliderOptions {
    return {
      mode: "free-snap",
      breakpoints: {
        "(max-width: 1200px)": {
          slides: {
            perView: 2,
            spacing: 24
          },
        },
        "(max-width: 768px)": {
          slides: {
            perView: 1,
            spacing: 24
          },
        },
        "(min-width: 1200px)": {
          slides: {
            perView: 3,
            spacing: 24
          },
        }
      }
    }
  }

  public getSliderTopicsConfig(): KeenSliderOptions {
    return {
      mode: "free",
      slides: {
        perView: 2,
        spacing: 10,
        origin: "center"
      }
    }
  }

  ngOnInit(): void {
    window?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    })
    this.getConfig();
    this.getFaq();
  }
}