// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiKey: "mP3G2UmO631DEWpyxjfLA716vWlkpbdWAV3kvm47",
  apiUrl: "https://api-dev.cloudfaster.academy/sandbox/",
  productName: "Plataforma CloudFaster Academy",
  productor: "0c2586b8-7123-4a5d-b0f0-cc4b3a8b0d0b",
  productorTable: "cloudfaster"
};