<ng-container *ngIf="config">
  <div id="immersion" class="immersion" #immersion>
    <div class="d-flex flex-column gap-5">
      <div class="container">
        <div class="d-flex flex-column gap-3 text-center">
          <span class="fs-0" [innerHTML]="config.title"></span>
          <div>
            <span class="fs-4 opacity-75" [innerHTML]="config.subtitle"></span>
          </div>
        </div>
      </div>
      <div class="immersion-video">
        <div class="container">
          <div class="video bg-main border border-main rounded-2">
            <iframe [src]="config.link | safeUrl" title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-main-bright">
      <div class="container">
        <div class="session-pt-sm"></div>
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-6 row align-items-center">
            <div class="col-auto">
              <div
                class="col-auto d-flex justify-content-center align-items-center bg-secondary-bt-to-primary-bt rounded-3 min-w-100px min-h-100px">
                <ng-container *ngIf="config.isLive">
                  <img src="assets/images/icons/calendar-check-line.png" alt="Calendario" width="46" height="46">
                </ng-container>
                <ng-container *ngIf="!config.isLive">
                  <i class="fa-solid fa-video fs-2"></i>
                </ng-container>
              </div>
            </div>
            <div class="col">
              <div>
                <ng-container *ngIf="config.isLive">
                  <span class="fs-5 opacity-75" [innerHTML]="config.date"></span>
                </ng-container>
                <ng-container *ngIf="!config.isLive">
                  <span class="fs-5 fw-500">
                    Tenha acesso a gravação da imersão!
                  </span>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-10 col-lg-6 pt-3 pt-lg-0 row align-items-center">
            <div class="col-auto">
              <div
                class="col-auto d-flex justify-content-center align-items-center bg-secondary-bt-to-primary-bt rounded-3 min-w-100px min-h-100px">
                <img src="assets/images/icons/cloud-academy.png" alt="cloud" width="60" height="29">
              </div>
            </div>
            <div class="col">
              <div>
                <span class="fs-5 fw-500">Onde irá acontecer:</span>
              </div>
              <div>
                <span class="fs-5 opacity-75"
                  [innerHTML]="config.isLive ? config.local : 'Plataforma Exclusiva da CloudFaster Academy'"></span>
              </div>
            </div>
          </div>
          <div class="session-pt-sm"></div>
        </div>
      </div>
    </div>
  </div>
</ng-container>