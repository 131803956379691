<div class="bg-white-blur border border-white border-opacity-10 rounded-3 p-4 w-100 h-100">
	<div class="row gx-4 gy-4">
		<div class="col-12 col-md-5">
			<div class="d-flex justify-content-center align-items-center badge-container rounded-3 h-100">
				<div class="bg-badge"
					[style]="'background-image:url('+config.bg+')'"></div>
				<img class="max-h-100"
					[src]="config.badge"
					[alt]="'Badge'"
					width="145"
					height="145">
			</div>
		</div>
		<div class="col-12 col-md-7">
			<div class="d-flex justify-content-between flex-column gap-3 h-100">
				<div class="d-flex gap-3">
					<div class="d-flex align-items-center justify-content-center bg-secondary-bt-to-primary-bt rounded-3 py-2 py-lg-3 w-140px">
						<span>Simulado</span>
					</div>
					<div class="d-flex align-items-center justify-content-center border border-white border-opacity-10 bg-white-blur rounded-3 py-2 py-lg-3 overflow-hidden w-140px">
						<span [innerHTML]="config.cloud"></span>
					</div>
				</div>
				<div class="fs-5 fs-md-4 lh-sm">
					<span class="fw-500"
						[innerHTML]="config.title"></span>
				</div>
				<div class="row gx-1 gx-md-3">
					<div class="col-6 col-xl-5">
						<div class="d-flex align-items-center gap-1 gap-lg-3">
							<div class="info-icon d-flex justify-content-center align-items-center bg-white-blur border border-white border-opacity-10 rounded-3 ">
								<i class="fs-5 fs-md-2 text-secondary-bt-to-primary-bt fa-regular fa-clock"></i>
							</div>
							<div class="d-flex flex-column">
								<span class="fs-5 fs-lg-3">
									{{config.timeToString}}
								</span>
								<span class="fs-7 fs-lg-5 fw-300 opacity-75">Tempo</span>
							</div>
						</div>
					</div>
					<div class="col-6 col-xl-5">
						<div class="d-flex align-items-center gap-1 gap-lg-3">
							<div class="info-icon d-flex justify-content-center align-items-center bg-white-blur border border-white border-opacity-10 rounded-3 ">
								<i class="fs-5 fs-md-2 text-secondary-bt-to-primary-bt fa-solid fa-bolt"></i>
							</div>
							<div class="d-flex flex-column">
								<span class="fs-5 fs-lg-3">
									{{config.qtdQuestions}}
								</span>
								<span class="fs-7 fs-lg-5 fw-300 opacity-75">Questões</span>
							</div>
						</div>
					</div>
				</div>
				<div>
					<ng-container *ngIf="!config.external">
						<cfa-btn-route [classes]="'w-100'"
							[variant]="'outline'"
							[router]="config.route">
							<div class="fs-7 fs-md-6">Saiba Mais</div>
						</cfa-btn-route>
					</ng-container>
					<ng-container *ngIf="config.external">
						<cfa-btn-link [classes]="'w-100'"
							[variant]="'outline'"
							[target]="'_blank'"
							[href]="config.route">
							<div class="fs-7 fs-md-6">Saiba Mais</div>
						</cfa-btn-link>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>
