import { Lab } from '@app/types/training';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'cfa-labs-card',
  standalone: true,
  imports: [],
  templateUrl: './labs-card.component.html',
  styleUrl: './labs-card.component.scss'
})
export class LabsCardComponent {
  @Input() config!:Lab
}
