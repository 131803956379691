export class Topic {
  image!: string;
  text!: string;
  select?: boolean;
  
  constructor(config: any) {
    if (config) {
      this.image = config.image !== undefined ? config.image : this.image;
      this.text = config.text !== undefined ? config.text : this.text;
      this.select = config.select !== undefined ? config.select : this.select;
    }
  }
}