import { Component, Input, OnInit } from '@angular/core';
import { AppModule } from 'app/core';
import { CommonService } from '@services';
import { TopicSection, Topic } from '@types';
import { SafePipe } from '@app/pipes/safe';
import { KeenSliderOptions } from 'keen-slider';
import { SliderDirective } from '@directives';

@Component({
  selector: 'cfa-topic-section',
  standalone: true,
  imports: [
    AppModule,
    SliderDirective
  ],
  providers: [
    CommonService,
    SafePipe
  ],
  templateUrl: './topic-section.component.html',
  styleUrl: './topic-section.component.scss'
})
export class TopicSectionComponent implements OnInit {

  @Input() config?: TopicSection;
  @Input() config_carousel?: []

  public topics: any;

  constructor(
    private CommonService: CommonService,
  ) { }

  public getSliderConfig(): KeenSliderOptions {
    if (this.config_carousel) {
      return this.config_carousel;
    } else {
      return {
        mode: "free-snap",
        breakpoints: {
          "(max-width: 1200px)": {
            slides: {
              perView: 2,
              spacing: 24
            },
          },
          "(max-width: 768px)": {
            slides: {
              origin: 'center',
              perView: 1.9,
              spacing: 24
            },
          },
          "(min-width: 1200px)": {
            slides: {
              perView: 3,
              spacing: 24
            },
          }
        }
      }
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      console.log(this.config);
    }, 2000);
  }

}