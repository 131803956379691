import { Component, Input, OnInit } from '@angular/core';
import { AppModule } from 'app/core';
import { CommonService } from '@services';
import { VideoSection } from '@types';
import { SafePipe } from '@app/pipes/safe';

@Component({
  selector: 'cfa-video-section',
  standalone: true,
  imports: [
    AppModule,
  ],
  providers: [
    CommonService,
    SafePipe
  ],
  templateUrl: './video-section.component.html',
  styleUrl: './video-section.component.scss'
})
export class VideoSectionComponent implements OnInit {

  @Input() config?: VideoSection;

  constructor(
    private CommonService: CommonService,
  ) { }

  ngOnInit(): void {
  }

}