<div id="certifications" class="certifications" #certifications>
	<div class="container">
		<div class="d-flex flex-column justify-content-between align-items-center gap-5">
			<div class="text-center">
				<span class="fs-1 fw-500 text-center lh-sm">
					Dan atualmente possui todas <br class="d-none d-md-block">as
					<span class="text-tertiary-to-primary-bt">
						12 certificações da AWS!
					</span>
				</span>
			</div>
			<div class="d-flex align-items-center gap-2 text-center fs-5">
				<span class>Arraste para o lado</span>
				<i class="text-tertiary-to-primary-bt fa-solid fa-arrow-right"></i>
			</div>
		</div>
		<div class="session-mt-sm"></div>
		<div class="carousel-certifications">
			<div cfaSlider [config]="getSliderConfig()">
				<div slide>
					<cfa-btn [classes]="'button rounded-4'" [variant]="'flat'">
						<img class="max-w-100 h-100 img-fluid" width="300" height="300"
							src="assets/images/dan/certifications/cloud-practitioner.png" alt="Cloud Practitioner">
					</cfa-btn>
				</div>
				<div slide>
					<cfa-btn [classes]="'button rounded-4'" [variant]="'flat'">
						<img class="max-w-100 h-100 img-fluid" width="300" height="300"
							src="assets/images/dan/certifications/solutions-architect-associate.png"
							alt="Solucions Architect Associate">
					</cfa-btn>
				</div>
				<div slide>
					<cfa-btn [classes]="'button rounded-4'" [variant]="'flat'">
						<img class="max-w-100 h-100 img-fluid" width="300" height="300"
							src="assets/images/dan/certifications/developer-associate.png" alt="Developer Associate">
					</cfa-btn>
				</div>
				<div slide>
					<cfa-btn [classes]="'button rounded-4'" [variant]="'flat'">
						<img class="max-w-100 h-100 img-fluid" width="300" height="300"
							src="assets/images/dan/certifications/sysops-associate.png" alt="Sysops Associate">
					</cfa-btn>
				</div>
				<div slide>
					<cfa-btn [classes]="'button rounded-4'" [variant]="'flat'">
						<img class="max-w-100 h-100 img-fluid" width="300" height="300"
							src="assets/images/dan/certifications/devops-engineer-professional.png"
							alt="DevOps Engineer Professional">
					</cfa-btn>
				</div>
				<div slide>
					<cfa-btn [classes]="'button rounded-4'" [variant]="'flat'">
						<img class="max-w-100 h-100 img-fluid" width="300" height="300"
							src="assets/images/dan/certifications/soluction-architect-professional.png"
							alt="Soluctons Architect Professional">
					</cfa-btn>
				</div>
				<div slide>
					<cfa-btn [classes]="'button rounded-4'" [variant]="'flat'">
						<img class="max-w-100 h-100 img-fluid" width="300" height="300"
							src="assets/images/dan/certifications/advanced-networking-special.png"
							alt="Advanced Networking Specialty">
					</cfa-btn>
				</div>
				<div slide>
					<cfa-btn [classes]="'button rounded-4'" [variant]="'flat'">
						<img class="max-w-100 h-100 img-fluid" width="300" height="300"
							src="assets/images/dan/certifications/database-special.png" alt="DataBase Specialty">
					</cfa-btn>
				</div>
				<div slide>
					<cfa-btn [classes]="'button rounded-4'" [variant]="'flat'">
						<img class="max-w-100 h-100 img-fluid" width="300" height="300"
							src="assets/images/dan/certifications/sap-special.png" alt="SAP sepecialty">
					</cfa-btn>
				</div>
				<div slide>
					<cfa-btn [classes]="'button rounded-4'" [variant]="'flat'">
						<img class="max-w-100 h-100 img-fluid" width="300" height="300"
							src="assets/images/dan/certifications/machine-learning-special.png"
							alt="Machine Learning Specialty">
					</cfa-btn>
				</div>
				<div slide>
					<cfa-btn [classes]="'button rounded-4'" [variant]="'flat'">
						<img class="max-w-100 h-100 img-fluid" width="300" height="300"
							src="assets/images/dan/certifications/security-special.png" alt="Security Specialty">
					</cfa-btn>
				</div>
				<div slide>
					<cfa-btn [classes]="'button rounded-4'" [variant]="'flat'">
						<img class="max-w-100 h-100 img-fluid" width="300" height="300"
							src="assets/images/dan/certifications/data-analytics-special.png"
							alt="Data Analytics Specialty">
					</cfa-btn>
				</div>
			</div>
		</div>
	</div>
</div>