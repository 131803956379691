<div class="back" id="back" #back>
  <div class="container">
    <div class="d-flex justify-content-end">
      <cfa-btn [classes]="'w'" [variant]="'flat'" (click)="goToTop()">
        <div class="d-flex align-items-center gap-2">
          <i class="fa-solid fa-arrow-turn-up text-tertiary-to-primary-bt"></i>
          <span>Voltar para o topo</span>
        </div>
      </cfa-btn>
    </div>
  </div>
</div>
