import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Practice } from '@app/types/training';

import { BtnLinkComponent, BtnRouteComponent } from '@components';

@Component({
  selector: 'cfa-practice-card',
  standalone: true,
  imports: [
    BtnRouteComponent,
    BtnLinkComponent,
    CommonModule
  ],
  templateUrl: './practice-card.component.html',
  styleUrl: './practice-card.component.scss'
})
export class PracticeCardComponent {

  @Input() config!: Practice
}
