import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImmersionsService } from '@features/immersions/services/immersions.service';
import { FaqFeatureComponent, BtnComponent, BtnLinkComponent, BackComponent, FooterComponent, CertificationsValeriaComponent, ValeriaBaptistaComponent, HeaderComponent } from "@components";
import { HomeService } from '@features/home/services';
import { CourseRoute } from '@app/types/courses';
import { KeenSliderOptions } from 'keen-slider';
import { SliderDirective } from '@directives';
import { FAQ, Topic } from '@types';

@Component({
  selector: 'cfa-az-900',
  standalone: true,
  imports: [
    BtnComponent,
    SliderDirective,
    CommonModule,
    FooterComponent,
    FaqFeatureComponent,
    BackComponent,
    CertificationsValeriaComponent,
    BtnLinkComponent,
    HeaderComponent,
    ValeriaBaptistaComponent
  ],
  templateUrl: './az-900.component.html',
  styleUrl: './az-900.component.scss'
})
export class Az900Component implements OnInit {

  public course_list: CourseRoute[] = [];
  public faqs: FAQ[] = [];
  public topic_list: Topic[] = [];
  public kiwify_link: string = 'https://pay.kiwify.com.br/IZlinEY';
  public course_name: string = 'AZ-900';

  constructor(
    private ImmersionsService: ImmersionsService,
    private HomeService: HomeService
  ) { }

  public getSliderCourseConfig(): KeenSliderOptions {
    return {
      mode: "free-snap",
      breakpoints: {
        "(max-width: 1200px)": {
          slides: {
            perView: 2,
            spacing: 24
          },
        },
        "(max-width: 768px)": {
          slides: {
            perView: 1,
            spacing: 24
          },
        },
        "(min-width: 1200px)": {
          slides: {
            perView: 3,
            spacing: 24
          },
        }
      }
    }
  }

  public getSliderTopicsConfig(): KeenSliderOptions {
    return {
      mode: "free",
      slides: {
        perView: 2,
        spacing: 10,
        origin: "center"
      }
    }
  }

  getCoursesList() {
    this.course_list = this.ImmersionsService.course_list;
  }

  private getFaqs() {
    this.faqs = this.ImmersionsService.faqs;
  }

  private getTopics() {
    this.topic_list = this.ImmersionsService.topic_az_900;
  }

  ngOnInit(): void {
    window?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    })
    this.getCoursesList();
    this.getFaqs();
    this.getTopics();
  }
}