<div class="bg-white-blur border border-white border-opacity-10 w-100 py-2 py-md-3 px-3 px-lg-4 rounded-3">
  <div class="row align-items-center gx-0 gx-md-3">
    <div class="col-6">
      <div class="d-flex align-items-center gap-2">
        <i class="fs-7 fs-md-5 fa-solid fa-layer-group text-secondary-bt-to-primary-bt"></i>
        <span class="fs-7 fs-md-5">{{config.title}}</span>
      </div>
    </div>
    <div class="col-6">
      <div class="row justify-content-center align-items-center gy-2">
        <div class="col-4 d-none d-lg-block">
          <div class="d-flex align-items-center gap-2">
            <i class="fs-7 fs-md-5 fa-regular fa-clock text-secondary-bt-to-primary-bt"></i>
            <span class="fs-7 fs-md-5">{{config.timeToString}}</span>
          </div>
        </div>
        <div class="col-6 col-md-4">
          <div class="d-flex justify-content-center bg-white-blur border border-white border-opacity-10 w-100 rounded-3 py-2 py-md-3 w-140px">
            <span class="fs-7 fs-md-5">{{config.cloud}}</span>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="d-flex justify-content-center align-items-center gap-1 gap-md-2">
            <i class="fs-7 fs-md-5 fa-regular fa-circle-check text-secondary-bt-to-primary-bt"></i>
            <span class="fs-7 fs-md-5 fw-300">Disponível</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
