<ng-container *ngIf="config">
  <div id="home" class="home" #home>
    <div class="container">
      <div class="d-flex flex-column align-items-center gap-5">
        <div class="badge-img"></div>
        <div class="d-flex flex-column gap-4 w-100">
          <div class="lh-sm text-center">
            <span class="fs-0">
              O primeiro passo na sua jornada para se tornar um
              <span class="text-tertiary-to-primary-bt fw-500">
                Arquiteto de Valor Cloud
              </span>
            </span>
          </div>
          <div class="text-center">
            <span class="fs-4 text-opacity-50">
              Tudo o que não te contaram sobre a
            </span>
            &nbsp;
            <span class="fs-4 fw-500">
              Nova Versão do Exame
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="d-none d-lg-block radius-bg-primary-start"></div>
    <div class="d-none d-lg-block radius-bg-primary-end" style="bottom: 20rem;"></div>
  </div>
  <div class="container">
    <div class="row justify-content-center ">
      <div class="col-auto bg-white-blur border border-white border-opacity-10 p-4_5">
        <div class="row align-items-center gap-5">
          <div class="col-12 col-lg-auto text-center">
            <cfa-btn-link [classes]="'w-100'" [href]="config.kiwifyLink" [target]="'_blank'">
              <div class="py-3">
                <span class="fs-4 fw-300">
                  Quero Assinar Agora! >
                </span>
              </div>
            </cfa-btn-link>
          </div>
          <div class="col-auto p-0 border border-left border-white border-opacity-10 h-60px d-none d-lg-block">
          </div>
          <div class="col-12 col-lg-auto d-flex align-items-center gap-3 justify-content-center">
            <div>
              <i class="fs-3 fa-brands fa-whatsapp text-success"></i>
            </div>
            <div class="d-flex flex-column">
              <div>
                <span class="fs-6 opacity-60">Tem alguma dúvida?</span>
              </div>
              <div>
                <span class="fs-5">Fale com nosso time</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-lg-9 col-xl-8 pt-4">
        <div class="row justify-content-center align-items-center">
          <div class="col-12 col-md-6 text-center text-md-start">
            <span class="fs-5 opacity-75">
              Explore nossos Cursos e Imersões
            </span>
          </div>
          <div
            class="col-12 col-md-6 d-flex align-items-center justify-content-md-end justify-content-center gap-2 text-center text-md-end">
            <span>Arraste para o lado</span>
            <i class="text-secondary-bt-to-primary-bt fa-solid fa-arrow-right"></i>
          </div>
        </div>
      </div>
      <div class="col-12 pt-4">
        <cfa-carousel-courses></cfa-carousel-courses>
      </div>
    </div>
  </div>
</ng-container>