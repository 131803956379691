<ng-container *ngIf="config">
	<div id="topic" class="topic" #topic>
		<div class="container">
			<div class="d-flex flex-column gap-3 text-center">
				<span class="fs-1 lh-sm">
					Nessa imersão falaremos
					<br class="d-none d-md-block">
					sobre os
					<span class="text-tertiary-to-primary-bt fw-500">seguintes assuntos</span>
				</span>
				<div>
					<span class="fs-4 opacity-75">
						Foco total na preparação do Exame
					</span>
				</div>
			</div>
		</div>
		<div class="session-mt-sm"></div>
		<div class="container">
			<div class="row justify-content-center d-md-none pb-3">
				<div
					class="col-12 d-flex align-items-center justify-content-md-end justify-content-center gap-2 text-center text-md-end">
					<span>Arraste para o lado</span>
					<i class="text-secondary-bt-to-primary-bt fa-solid fa-arrow-right"></i>
				</div>
			</div>
			<ng-container *ngIf="config">
				<div class="row justify-content-center gy-4 d-none d-md-flex">
					<ng-container *ngFor="let topic of config.topics">
						<div class="col-12 col-md-4 pb-5">
							<div class="d-flex flex-column align-items-center gap-3">
								<div
									class="icon-topic d-flex justify-content-center align-items-center bg-secondary-bt-to-primary-bt rounded-3">
									<img [src]="topic.image" alt="topic" width="50" height="50">
								</div>
								<div class="text-center">
									<span class="fs-5" [innerHTML]="topic.text">
									</span>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</ng-container>
		</div>
		<div cfaSlider class="d-md-none" [config]="getSliderConfig()">
			<ng-container *ngFor="let topic of config.topics">
				<div slide>
					<ng-container *ngIf="topic.select"></ng-container>
					<ng-container *ngIf="!topic.select">
						<div class="d-flex flex-column align-items-center gap-3">
							<div
								class="icon-topic d-flex justify-content-center align-items-center bg-secondary-bt-to-primary-bt rounded-3">
								<img [src]="topic.image" alt="topic" width="50" height="50">
							</div>
							<div class="text-center">
								<span class="fs-5" [innerHTML]="topic.text">
								</span>
							</div>
						</div>
					</ng-container>
				</div>
			</ng-container>
		</div>
		<div class="d-none d-lg-block radius-bg-primary-start"></div>
	</div>
</ng-container>