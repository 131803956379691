<a #linkButton [target]="target" style="color:initial" >
  <cfa-btn
    [classes]="classes"
    [styles]="styles"
    [theme]="theme"
    [variant]="variant"
    [disabled]="disabled"
    >
    <ng-content ></ng-content>
  </cfa-btn>
</a>