import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'cfa-sign',
  standalone: true,
  imports: [],
  templateUrl: './sign.component.html',
  host: {ngSkipHydration: 'true'},
  styleUrl: './sign.component.scss'
})
export class SignComponent implements AfterViewInit, OnDestroy {
  @Input() phrases: string[] = [
    "a próxima certificação!",
    "uma nova oportunidade!",
    "o primeiro emprego!",
    "um aumento de salário!",
  ];
  @Input() delay: number = 1000;
  @Input() dalayLetters: number = 200
  @Input() dalayLettersRemove: number = 200
  @Input() dalayPharases: number = 1000
  public letters: string = "";
  public stop: boolean = false;

  /**
   ** Controlador da frase que irá aparecer no letreiro
   * @param index index da frase
   */
  private phraseController(index: number) {
    if (!this.stop) {
      if (this.phrases[index]) {
        this.letters = ""
        this.addPhrase(this.phrases[index], 0)
      } else {
        this.phraseController(0)
      }
    }
  }

  private addPhrase(phrase: string, index: number) {
    if (!this.stop) {
      setTimeout(() => {
        this.letters += phrase[index];
        if (phrase[index + 1] !== undefined) {
          this.addPhrase(phrase, (index + 1))
        }
        else {
          //! Assim que terminar de escrever a frase, chamar o metodo para remover a frase
          setTimeout(() => {
            this.removePhrase();
          }, this.dalayLettersRemove) //? Tempo de delay para remover a frase
        }

      }, this.dalayLetters)//? Tempo de delay entre as letras
    }
  }


  private removePhrase() {
    if (!this.stop) {
      setTimeout(() => {
        this.letters = this.letters.slice(0, -1)
        if (this.letters.length > 0) {
          this.removePhrase();
        }
        else if (this.letters.length <= 0) {
          //! Assim que terminar de remover a frase, chamar o metodo para adicionar a proxima frase
          setTimeout(() => {
            this.phraseController(Math.floor(Math.random() * (this.phrases.length)))
          }, this.dalayPharases) //? Tempo de delay entre as frases
        }

      }, this.dalayLetters)//? Tempo de delay entre as letras
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.phraseController(0);
    }, this.delay)
  }
  ngOnDestroy(): void {
    this.stop = true;
  }
}
