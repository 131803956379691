  <a [routerLink]="router" style="color:initial">
    <cfa-btn
    [classes]="classes"
    [styles]="styles"
    [theme]="theme"
    [variant]="variant"
    >
    <ng-content></ng-content>
  </cfa-btn>
  </a>
