<div class="training-card bg-white-blur border border-white border-opacity-10 rounded-3 p-4 w-100 h-100">
	<div class="d-flex flex-column gap-4 justify-content-between h-100">
		<div class="d-flex justify-content-between gap-3">
			<div class="d-flex align-items-center justify-content-center bg-secondary-bt-to-primary-bt rounded-3 py-2 py-lg-3 w-140px">
				<span [innerHTML]="config.type"></span>
			</div>
			<!-- <div class="d-flex align-items-center justify-content-center border border-white border-opacity-10 bg-white-blur rounded-3 py-2 py-lg-3 overflow-hidden w-140px">
				<span [innerHTML]="config.cloud"></span>
			</div> -->
		</div>
		<div class="d-flex justify-content-center align-items-center badge-container rounded-3">
			<div class="bg-badge"
				[style]="'background-image:url('+config.bg+')'"></div>
			<img [src]="config.badge"
				[alt]="'Badge'"
				width="145"
				height="145">
		</div>
		<div class>
			<span class="fs-5 fs-md-4 fw-500"
				[innerHTML]="config.title"></span>
		</div>
		<div class="d-flex gap-3">
			<div class="instructor-img border-gradient border-secondary-bt-to-primary-bt rounded-circle shadow"
				[style]="'background-image:url('+config!.instructor!.photo+')'"></div>
			<div class="d-flex flex-column">
				<span class="fs-md-5"
					[innerHTML]="config.instructor?.name"></span>
				<small class="opacity-50"
					[innerHTML]="config.instructor?.description"></small>
			</div>
		</div>
		<div>
			<ng-container *ngIf="!config.external">
				<cfa-btn-route [classes]="'w-100'"
					[variant]="'outline'"
					[router]="config.route">
					<div class="fs-7 fs-md-6">
						Conhecer {{config.type}}
					</div>
				</cfa-btn-route>
			</ng-container>
			<ng-container *ngIf="config.external">
				<cfa-btn-link [classes]="'w-100'"
					[variant]="'outline'"
					[href]="config.route"
					[target]="'_blank'">
					<div class="fs-7 fs-md-6">
						Conhecer {{config.type}}
					</div>
				</cfa-btn-link>
			</ng-container>
		</div>
	</div>
</div>
