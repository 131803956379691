<app-header></app-header>
<cfa-banner [config]="config.banner"></cfa-banner>
<div class="session-mt-sm"></div>
<cfa-video-section [config]="config.videoSection"></cfa-video-section>
<div class="session-mt-lg"></div>
<div id="cloud" class="cloud" #cloud>
	<div class="container">
		<div class="d-flex flex-column gap-5">
			<div class="d-flex flex-column gap-3 text-center">
				<span class="fs-1">
					Por que se certificar em
					<span class="text-tertiary-to-primary-bt fw-500">Cloud?</span>
				</span>
				<div>
					<span class="fs-4 opacity-75">
						A porta de entrada para o mundo de Cloud!
					</span>
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-12 col-md-9">
					<div class="border-gradient border-secondary-bt-to-primary-bt rounded-4">
						<div class="bg-white-blur rounded-4 p-5">
							<div class="d-flex flex-column gap-4">
								<div class="row gap-3">
									<div class="col-12 col-lg-auto d-flex justify-content-center">
										<div class="icon-topic d-flex justify-content-center align-items-center bg-secondary-bt-to-primary-bt rounded-3"
											style="aspect-ratio: 1/1;">
											<img src="assets/images/icons/lightcast.png" alt="lightcast" width="49"
												height="48">
										</div>
									</div>
									<div class="col-12 col-lg">
										<div class="d-flex align-items-center h-100">
											<span class="fs-3">
												<b>
													Segundo a Lightcast,
												</b>
												ofertas de emprego que exigem o AWS Certified Cloud Practitioner
												aumentaram em 84%
											</span>
										</div>
									</div>
								</div>
								<div class="d-flex flex-column gap-2">
									<p class="fs-5 opacity-75">
										A certificação em AWS amplia oportunidades de emprego e demonstra
										competência
										técnica.
									</p>
									<p class="fs-5 opacity-75">
										Amazon Web Services é uma plataforma líder em serviços de nuvem, com
										escalabilidade, flexibilidade e segurança. A certificação em AWS valida
										habilidades técnicas e impulsiona a carreira em um mercado competitivo.
									</p>
									<p class="fs-5 opacity-75">
										É um investimento valioso para se destacar como especialista em nuvem.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="d-none d-lg-block radius-bg-primary-end"></div>
</div>
<div class="session-mt-lg"></div>
<cfa-topic-section [config]="config.topicSection"></cfa-topic-section>
<!-- <div id="topic" class="topic" #topic>
	<div class="container">
		<div class="d-flex flex-column gap-3 text-center">
			<span class="fs-1 lh-sm">
				Nessa imersão falaremos
				<br class="d-none d-md-block">
				sobre os
				<span class="text-tertiary-to-primary-bt fw-500">seguintes assuntos</span>
			</span>
			<div>
				<span class="fs-4 opacity-75">
					Foco total na preparação do Exame
				</span>
			</div>
		</div>
	</div>
	<div class="session-mt-sm"></div>
	<div class="container">
		<div class="row justify-content-center d-md-none pb-3">
			<div
				class="col-12 d-flex align-items-center justify-content-md-end justify-content-center gap-2 text-center text-md-end">
				<span>Arraste para o lado</span>
				<i class="text-secondary-bt-to-primary-bt fa-solid fa-arrow-right"></i>
			</div>
		</div>
		<div cfaSlider class="d-md-none" [config]="getSliderTopicsConfig()">
			<ng-container *ngFor="let topic of config.topic">
				<div slide>
					<ng-container *ngIf="topic.select"></ng-container>
					<ng-container *ngIf="!topic.select">
						<div class="d-flex flex-column align-items-center gap-3">
							<div
								class="icon-topic d-flex justify-content-center align-items-center bg-secondary-bt-to-primary-bt rounded-3">
								<img [src]="topic.image" alt="topic" width="50" height="50">
							</div>
							<div class="text-center">
								<span class="fs-5" [innerHTML]="topic.text">
								</span>
							</div>
						</div>
					</ng-container>
				</div>
			</ng-container>
		</div>
		<div class="row justify-content-center gy-4 d-none d-md-flex">
			<ng-container *ngFor="let topic of config.topic">
				<div class="col-12 col-md-4 pb-5">
					<div class="d-flex flex-column align-items-center gap-3">
						<div
							class="icon-topic d-flex justify-content-center align-items-center bg-secondary-bt-to-primary-bt rounded-3">
							<img [src]="topic.image" alt="topic" width="50" height="50">
						</div>
						<div class="text-center">
							<span class="fs-5" [innerHTML]="topic.text">
							</span>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
	<div class="d-none d-lg-block radius-bg-primary-start"></div>
</div> -->
<div class="session-mt-md"></div>
<div id="practitioner" class="practitioner" #practitioner>
	<div class="container">
		<div class="border-gradient rounded-3 border-tertiary-to-primary-bt">
			<div class="bg-white-blur rounded-3 p-5">
				<div class="row gx-5 justify-content-center">
					<div class="col-12 col-md-4">
						<div class="d-flex justify-content-center w-100">
							<div class="badge-cert"></div>
						</div>
					</div>
					<div class="col-12 col-md-6">
						<div class="d-flex flex-column justify-content-between gap-4 h-100">
							<div class="fs-3">
								<span class="opacity-75">
									Tudo o que você precisa fazer é garantir sua vaga para a&nbsp;
									<br class="d-none d-md-block">
								</span>
								<span>
									Imersão
									<span class="text-tertiary">Cloud Practitioner</span>
								</span>
							</div>
							<div class="d-flex flex-column">
								<div>
									<span class="opacity-75">por apenas</span>
								</div>
								<div>
									<span class="fs-0">R$ 147,00</span>
								</div>
							</div>
							<div class="d-md-none">
								<cfa-btn-link [classes]="'w-100'" [href]="config.kiwifyLink" [target]="'_blank'">
									<div class="py-2">
										<span class="fs-5 fw-300">
											Inscrever-se Agora
										</span>
									</div>
								</cfa-btn-link>
							</div>
							<div class="d-none d-md-block">
								<cfa-btn-link [classes]="'w-300px'" [href]="config.kiwifyLink" [target]="'_blank'">
									<div class="py-2">
										<span class="fs-5 fw-300">
											Inscrever-se Agora
										</span>
									</div>
								</cfa-btn-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="session-mt-lg"></div>
<div id="content" class="content" #content>
	<div class="container">
		<div class="d-flex flex-column gap-3 text-center">
			<span class="fs-1 lh-sm">
				<b>Se inscrevendo agora</b> na
				<br class="d-none d-md-block">
				Imersão <span class="text-tertiary-to-primary-bt fw-500">Cloud Practitioner</span>
				<br class="d-none d-md-block">
				você terá acesso a
			</span>
		</div>
	</div>
	<div class="session-mt-sm"></div>
	<div class="d-none d-lg-block radius-bg-primary-start" style="top: 50rem;"></div>
	<div class="d-none d-lg-block radius-bg-primary-end" style="top: 100rem;"></div>
	<div class="d-none d-lg-block radius-bg-primary-start" style="top: 150rem;"></div>
	<div class="d-none d-lg-block radius-bg-primary-end" style="top: 200rem;"></div>
	<div class="d-none d-lg-block radius-bg-primary-start" style="top: 250rem;"></div>
	<div class="container">
		<div class="row justify-content-center align-items-center">
			<div class="col-12 col-md-6 d-flex flex-column gap-3">
				<div class="text-center text-md-start">
					<i class="fs-1 text-secondary-bt-to-primary-bt fa-regular fa-circle-check"></i>
				</div>
				<div class="text-center text-md-start">
					<span class="fs-3 ls-sm">
						PDF com dicas de
						<br class="d-none d-md-block">
						estudo focado no
						<br class="d-none d-md-block">
						Exame de certificação
						<br class="d-none d-md-block">
						AWS
					</span>
				</div>
				<div class="text-center text-md-start">
					<span class="fs-3 ls-sm">
						Metodologia usada por
						<br class="d-none d-md-block">
						Dan Rezende para
						<br class="d-none d-md-block">
						<span class="text-tertiary-to-primary-bt fw-500">
							alcançar as 12
							<br class="d-none d-md-block">
							certificações
						</span>
					</span>
				</div>
				<div class="mt-xxl-4 d-none d-md-block">
					<div class="d-none d-md-block arrow-down-md-start"></div>

				</div>
			</div>
			<div class="col-5 pt-4 pt-md-0">
				<img src="assets/images/ccp/content/caderno.png" alt="caderno" class="img-fluid">
				<div class="d-block d-md-none py-4">
					<div class="arrow-down-md-center"></div>
				</div>
			</div>
		</div>
		<div class="session-mt-sm"></div>
		<div class="row justify-content-center align-items-center">
			<div class="col-12 col-md-6 col-lg-5 d-flex flex-column gap-3 order-md-2">
				<div class="text-center text-md-start">
					<i class="fs-1 text-secondary-bt-to-primary-bt fa-regular fa-circle-check"></i>
				</div>
				<div class="text-center text-md-start">
					<span class="fs-3 ls-sm">
						<b>Acesso a comunidade</b>
						<br class="d-none d-md-block">
						<b>privada do Discord</b>
						<br class="d-none d-md-block">
						focada em tirar dúvidas
						<br class="d-none d-md-block">
						da certificação
					</span>
				</div>
				<div class="mt-xxl-4 d-none d-md-block">
					<div class="d-none d-md-block arrow-down-md-start"></div>
				</div>
			</div>
			<div class="col-8 col-md-6 pt-4 pt-md-0 order-md-1">
				<div class="border-gradient border-secondary-bt-to-primary-bt rounded-4">
					<div class="bg-white-blur rounded-4 p-5">
						<div class="d-none d-md-block session-mt-sm"></div>
						<div class="d-flex flex-column align-items-center gap-3">
							<div class="w-75 text-center">
								<img src="assets/images/logos/discord.png" alt="discord" class="img-fluid">
							</div>
							<div class="w-75 text-center">
								<img src="assets/images/logos/dan-rezende.png" alt="Dan Rezende" class="img-fluid">
							</div>
						</div>
						<div class="d-none d-md-block session-mt-sm"></div>
					</div>
				</div>
				<div class="d-block d-md-none py-4">
					<div class="arrow-down-md-center"></div>
				</div>
			</div>
			<div class="col-md-1 d-none d-lg-block order-md-1"></div>
		</div>
		<div class="session-mt-sm"></div>
		<div class="row justify-content-center align-items-center">
			<div class="col-12 col-md-6 col-lg-5 d-flex flex-column gap-3 order-md-2">
				<div class="text-center text-md-start">
					<i class="fs-1 text-secondary-bt-to-primary-bt fa-regular fa-circle-check"></i>
				</div>
				<div class="text-center text-md-start">
					<span class="fs-3 ls-sm">
						Simulado prático
						<br class="d-none d-md-block">
						do Exame + Revisão
						<br class="d-none d-md-block">
						comentada das
						<br class="d-none d-md-block">
						questões
					</span>
				</div>
				<div class="text-center text-md-start">
					<span class="fs-3 ls-sm">
						<span class="text-tertiary-to-primary-bt fw-700">
							Acesso por 1 ano
						</span>
					</span>
				</div>
				<div class="mt-xxl-4 d-none d-md-block">
					<div class="d-none d-md-block arrow-down-md-start"></div>
				</div>
			</div>
			<div class="col-12 col-md-6 pt-4 pt-md-0 order-md-1">
				<div class="main-content">
					<img src="assets/images/ccp/example/practice-notebook.png" alt="notebook" class="img-content">
				</div>
				<div class="d-block d-md-none py-4">
					<div class="arrow-down-md-center"></div>
				</div>
			</div>
			<div class="col-md-1 d-none d-lg-block order-md-1"></div>
		</div>
		<div class="session-mt-sm"></div>
		<div class="row justify-content-center align-items-center">
			<div class="col-12 col-md-6 d-flex flex-column gap-3">
				<div class="text-center text-md-start">
					<i class="fs-1 text-secondary-bt-to-primary-bt fa-regular fa-circle-check"></i>
				</div>
				<div class="text-center text-md-start">
					<span class="fs-3 ls-sm">
						Plataforma exclusiva
						<br class="d-none d-md-block">
						de alunos
					</span>
				</div>
				<div class="text-center text-md-start">
					<span class="fs-3 ls-sm">
						Acesso a gravação da
						<br class="d-none d-md-block">
						Imersão para rever
						<br class="d-none d-md-block">
						quando quiser por
						<br class="d-none d-md-block">
						<span class="text-tertiary-to-primary-bt fw-500">
							12 meses
						</span>
					</span>
				</div>
				<div class="mt-xxl-4 d-none d-md-block">
					<div class="d-none d-md-block arrow-down-md-start"></div>

				</div>
			</div>
			<div class="col-5 pt-4 pt-md-0">
				<img src="assets/images/ccp/example/course-phone.png" alt="phone" class="img-fluid">
				<div class="d-block d-md-none py-4">
					<div class="arrow-down-md-center"></div>
				</div>
			</div>
		</div>
		<div class="session-mt-md"></div>
		<div class="border-gradient border-secondary-bt-to-primary-bt rounded-3">
			<div class="bg-white-blur rounded-3 p-5">
				<div class="row justify-content-center align-items-center">
					<div class="col-12 col-md-5 d-flex flex-column gap-3">
						<div class="text-center">
							<img src="assets/images/ccp/badge/cloud-practitioner.png" alt="Cloud Practitioner"
								class="img-fluid">
						</div>
						<div class="text-center">
							<span class="fs-5 ls-sm fw-700">
								Formas de Pagamento
							</span>
						</div>
						<div class="text-center">
							<img src="assets/images/ccp/content/pay-options.png" alt="pagamento" class="img-fluid">
						</div>
						<div class="text-center">
							<span class="fs-5 ls-sm">
								<i class="fs-4 text-secondary-bt-to-primary-bt fa-regular fa-circle-check"></i>&nbsp;
								Compra 100% segura
							</span>
						</div>
					</div>
					<div class="col-12 col-md-7 d-flex flex-column gap-3 pt-4 pt-md-0 ps-0 ps-md-4">
						<div class="text-center text-md-start">
							<span class="fs-2 ls-sm">
								Imersão <span class="text-tertiary-to-primary-bt">
									Cloud Practitioner
								</span>
							</span>
						</div>
						<div>
							<div class="fs-6 ls-sm mb-1 d-flex align-items-center">
								<span class="text-tertiary-to-primary-bt">
									>/&nbsp;
								</span>
								Acesso à Comunidade Privada no Discord
							</div>
							<div class="fs-6 ls-sm mb-1 d-flex align-items-center">
								<span class="text-tertiary-to-primary-bt">
									>/&nbsp;
								</span>
								Liberação de Badges e Insígnias Exclusivas
							</div>

							<div class="fs-6 ls-sm mb-1 d-flex align-items-center">
								<span class="text-tertiary-to-primary-bt">
									>/&nbsp;
								</span>
								PDF com Dicas de Estudos Funcionais
							</div>
							<div class="fs-6 ls-sm mb-1 d-flex align-items-center">
								<span class="text-tertiary-to-primary-bt">
									>/&nbsp;
								</span>
								Incluso Certificado de Participação
							</div>
							<div class="fs-6 ls-sm mb-1 d-flex align-items-center">
								<span class="text-tertiary-to-primary-bt">
									>/&nbsp;
								</span>
								3 Laboratórios práticos com contas reais da AWS para praticar
							</div>
						</div>
						<div class="text-center text-md-start">
							<span class="fs-6 ls-sm opacity-60 mb-1">
								por apenas
							</span>
							<div class="text-center text-md-start">
								<span class="fs-0 ls-sm fw-300 d-none d-md-block">
									R$ 147,00
								</span>
								<span class="fs-1 ls-sm fw-300 d-md-none">
									R$ 147,00
								</span>
							</div>
						</div>
						<div class="text-center d-md-none">
							<cfa-btn-link [classes]="'w-300px'" [href]="config.kiwifyLink" [target]="'_blank'">
								<div class="py-2">
									<span class="fs-5 fw-300">
										Inscrever-se Agora
									</span>
								</div>
							</cfa-btn-link>
						</div>
						<div class="text-start d-none d-md-block">
							<cfa-btn-link [classes]="'w-300px'" [href]="config.kiwifyLink" [target]="'_blank'">
								<div class="py-2">
									<span class="fs-5 fw-300">
										Inscrever-se Agora
									</span>
								</div>
							</cfa-btn-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="session-mt-lg"></div>
<feature-dan></feature-dan>
<div class="session-mt-lg"></div>
<div class="session-mt-lg d-md-none"></div>
<feature-certifications></feature-certifications>
<div class="session-mt-lg"></div>
<cfa-faq-feature [faqs]="faq"></cfa-faq-feature>
<div class="session-mt-sm"></div>
<feature-back></feature-back>
<div class="session-mt-sm"></div>
<cfa-footer></cfa-footer>