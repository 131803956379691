<div class="w-100">
  <div cfaSlider [config]="getSliderConfig()">
    <ng-container *ngFor="let course of course_list">
      <div slide>
        <ng-container *ngIf="course.select"></ng-container>
        <ng-container *ngIf="!course.select">
          <a class="course-route" [routerLink]="course.route">
            <div
              class="course-route bg-white-blur border border-white border-opacity-10 rounded-3 p-3 text-center">
              <span class="fs-6">{{course.name}}</span>
            </div>
          </a>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>