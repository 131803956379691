import { Observable, of } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestsService {

  private productor: string = environment.productorTable;

  constructor(
    private http: HttpClient,
    private route: Router,
  ) { }


  public requestData(method: string, path?: string, body?: any, productor?: string, observe?: string): Observable<any> {
    try {
      if (path) {
        path = path.replace(':productor', productor ?? this.productor);
      }
      const URL = `${environment.apiUrl}${(path) ? path : ''}`;
      let httpHeaders: HttpHeaders;
      httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.apiKey,
      });
      const options: any = { headers: httpHeaders };
      if (observe) {
        options.observe = observe
      }
      if (body) {
        options.body = body;
      }
      return this.http.request(method, URL, options)
    } catch (err: any) {
      console.error(err);
      this.route.navigate(['/auth']);
      return of(false);
    }
  }
}
