import { Routes } from '@angular/router';

import * as Screens from './screens'

export const HOME_ROUTES: Routes = [
  {
    path:'',
    component:Screens.HomeComponent,
  }
];
