<div class="valeria" id="valeria" #valeria>
  <div class="background">
    <div class="container ">
      <div class="d-none d-lg-block pt-session-sm"></div>
      <div class="pt-session-sm"></div>
      <div class="row">
        <div class="col-12 col-md-5">
          <div class="d-flex flex-column gap-4">
            <div class="d-none d-md-flex flex-column gap-3">
              <span class="fs-1">Quem é <span class="text-tertiary-to-primary-bt fw-500">Valéria Baptista?</span></span>
              <span class="fs-5">Conheça um pouco mais sua mentora</span>
            </div>
            <div class="d-flex d-md-none flex-column gap-3 text-center" >
              <span class="lh-sm fs-1">Quem é <span class="text-tertiary-to-primary-bt fw-600">Valéria<br>Baptista?</span></span>
              <span class="fs-4">Conheça um pouco mais <br>sua mentora</span>
            </div>
            <div class="d-flex flex-column gap-3">
              <div class="bg-main-bright bg-opacity-70 rounded-2 p-4 border-gradient-hover border-secondary-bt-to-primary-bt-hover">
                <div class="d-flex align-items-center gap-2">
                  <div>
                    <img class="img-fluid" src="assets/images/dan/check.png" width="24" height="24"  alt="check">
                  </div>
                  <div class="lh-1">
                    <span>Ex <b class="fw-700">Microsoft</b></span>
                  </div>
                </div>
              </div>
              <div class="bg-main-bright bg-opacity-70 rounded-2 p-4 border-gradient-hover border-secondary-bt-to-primary-bt-hover">
                <div class="d-flex align-items-center gap-2">
                  <div>
                    <img class="img-fluid" src="assets/images/dan/check.png" width="24" height="24" alt="check">
                  </div>
                  <div class="lh-1">
                    <span><b class="fw-700">10x</b> Microsoft Certified</span>
                  </div>
                </div>
              </div>
              <div class="bg-main-bright bg-opacity-70 rounded-2 p-4 border-gradient-hover border-secondary-bt-to-primary-bt-hover">
                <div class="d-flex align-items-center gap-2">
                  <div>
                    <img class="img-fluid" src="assets/images/dan/check.png" width="24" height="24" alt="check">
                  </div>
                  <div class="lh-1">
                    <span><b class="fw-700">15 Anos</b> de Mercado</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="radius-bg-primary-end" style="z-index: -1;"></div>
  </div>
</div>