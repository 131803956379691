<div class="fixed-bottom shadow-lg cookies rounded-1 bg-dark bg-opacity-95" [ngClass]="{'disabled-cookies':isPolictAccepted}">
    <div class="py-3 px-3 d-flex flex-column flex-md-row justify-content-between align-items-center">
        <div class="d-flex flex-column text-center text-md-start">
            <small> Este site armazena dados como cookies para habilitar funcionalidades essenciais do site, bem como
                personalização e análises.<br><a class="more-info fw-bold" target="_self"
                    routerLink="/politica-de-privacidade">Conheça nossa Política de Privacidade e Cookies.</a></small>
        </div>
        <div class="mt-3 mt-md-0">
            <cfa-btn (click)="confirmCookie()">
                Entendi
            </cfa-btn>
        </div>
    </div>
</div>