<ng-template #modalForm let-modal>
	<div class="border-gradient rounded-3 bg-main-bright border-tertiary-to-primary-bt p-4 p-lg-5 modal-form w-100">
		<div class="text-center fs-5 pb-4 d-lg-none">
			Parabéns!<br>Seu cadastro na <span class="fw-500">lista de espera</span> foi concluído!
		</div>
		<div class="text-center fs-3 pb-3 d-none d-lg-block">
			Parabéns!<br>Seu cadastro na <span class="fw-500">lista de espera</span> foi concluído!
		</div>
		<div class="text-center">
			<img src="assets/images/icons/check-color.png" alt="check" class="img-fluid">
		</div>
	</div>
</ng-template>
<app-header></app-header>
<div class="home" id="home" #home>
	<div class=" radius-bg-primary-top bg-opacity-75">
		<div class="content"></div>
		<div class="d-none d-md-block radius-bg-primary-start">
			<div class="content"></div>
		</div>
		<div class="container">
			<div class="d-flex flex-column gap-5">
				<div class="d-flex flex-column gap-3">
					<div class="">
						<h1 class=" text-center mb-0">
							<span class=" fw-400">A única Mentoria responsável por <br
									class="d-none d-md-block">formar</span>
							<strong> Arquitetos de Valor Cloud <br class="d-none d-md-block">AWS do Brasil</strong>
						</h1>
					</div>
					<div class="text-center">
						<span class="fs-5"><b>Obtenha o conhecimento e experiência prática</b> para resolver qualquer
							demanda em cloud <br class="d-none d-md-block">e torne-se um profissional bem pago e
							cobiçado pelas maiores empresas do mundo</span>
					</div>
				</div>
				<div class="d-flex flex-column gap-3">
					<div class="d-flex justify-content-center">
						<div class="video d-flex justify-content-center bg-main-bright">
							<iframe src="https://www.youtube.com/embed/4pmxhG0V0ms" title="YouTube video player"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowfullscreen></iframe>
						</div>
					</div>
					<div class="d-flex flex-column gap-4 mt-3">
						<div class="text-center">
							<span class=""><b>Clique no botão abaixo</b> e efaça sua matrícula</span>
						</div>
						<div>
							<cfa-btn-link [classes]="'w-100'" [href]="link_buy" [target]="'_blank'">
								<span class="fw-light">Quero me tornar um Arquiteto de Valor Cloud</span>
							</cfa-btn-link>
						</div>
						<div class="border-gradient border-secondary-primary-bright rounded-3">
							<div class="bg-main-bright bg-opacity-70 rounded-3 p-4">
								<div class="d-flex flex-column align-items-center justify-content-center gap-4">
									<div class="text-center">
										<span class="fs-5 fw-bold">Formas de Pagamento</span>
									</div>
									<div class="">
										<img class="img-fluid" src="assets/images/common/pay-options.png" width="338"
											height="49" alt="Formas de Pagamento">
									</div>
									<div class=" fs-5">
										<i class="fa-regular fa-circle-check text-tertiary-to-primary-bt"></i>&nbsp;
										Compra 100% segura
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="d-lg-none session-mt-sm"></div>

<div class="advc" id="advc" #advc style="z-index: 2;">
	<div class="radius-bg-primary-end d-none d-md-block" style="transform: translateY(-20rem);">
		<div class="content"></div>
	</div>
	<div class="d-flex flex-column gap-5">
		<div class="container position-relative">
			<div class="d-none d-md-block dan-circle-image"></div>
			<div class="row gy-3">
				<div class="col-12 col-lg-4">
					<div class="d-flex flex-column justify-content-around h-100">
						<div class="d-flex flex-column gap-5">
							<p class="d-none d-md-inline fs-2 text">
								Um lugar que vai proporcionar tudo que você precisa para atuar como um especialista em
								<span class="text-tertiary-to-primary-bt">Cloud Computing e AWS</span>
							</p>
							<p class="d-md-none fs-0 text-center px-4">
								Um lugar que vai proporcionar tudo que você precisa para atuar como um especialista em
								<span class="text-tertiary-to-primary-bt">Cloud Computing e AWS</span>
							</p>
							<div class="d-none d-lg-block arrow-down-lg-start"></div>
						</div>
						<div></div>
					</div>
				</div>
				<div class="col-12 col-lg-8">
					<div class="dan-circle-image-mobile">
						<img src="assets/images/advc/background/dan-mobile.png" class="d-md-none" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="d-lg-none arrow-down-lg-center"></div>
		<div class="container">
			<div class="d-flex flex-column gap-4">
				<div class="text-center">
					<span class="d-none d-md-inline fs-1">O Método <span class="text-tertiary">ADVC</span> é composto
						por:</span>
					<span class="d-md-none fs-2">O Método <span class="text-tertiary">ADVC</span> é composto por:</span>
				</div>
				<div class="row gx-4 gy-4">
					<div class="col-12 col-lg-6">
						<div class="bg-main-bright bg-opacity-70 p-4 rounded-1 w-100">
							<div class="d-flex justify-content-between align-items-center">
								<div class="d-flex align-itens-center gap-3">
									<div>
										<span class="count d-none d-lg-inline text-tertiary-to-primary-bt">01</span>
										<span class="count d-lg-none text-tertiary-to-primary-bt">01</span>
									</div>
									<div class="d-flex align-items-center">
										<span class=" fw-300">Aulas Gravadas</span>
									</div>
								</div>
								<div class="d-flex aling-items-center h-100">
									<img class="img-fluid" src="assets/images/icons/video-color.png" width="32"
										height="32" alt="video">
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-6">
						<div class="bg-main-bright bg-opacity-70 p-4 rounded-1 w-100">
							<div class="d-flex justify-content-between align-items-center">
								<div class="d-flex align-itens-center gap-3">
									<div>
										<span class="count d-none d-lg-inline text-tertiary-to-primary-bt">06</span>
										<span class="count d-lg-none text-tertiary-to-primary-bt">02</span>
									</div>
									<div class="d-flex align-items-center">
										<span class=" fw-300">Trilhas para Certificações</span>
									</div>
								</div>
								<div class="d-flex aling-items-center h-100">
									<img class="img-fluid" src="assets/images/icons/award-color.png" width="32"
										height="32" alt="Medalha">
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-6">
						<div class="bg-main-bright bg-opacity-70 p-4 rounded-1 w-100">
							<div class="d-flex justify-content-between align-items-center">
								<div class="d-flex align-itens-center gap-3">
									<div>
										<span class="count d-none d-lg-inline text-tertiary-to-primary-bt">02</span>
										<span class="count d-lg-none text-tertiary-to-primary-bt">03</span>
									</div>
									<div class="d-flex align-items-center">
										<span class=" fw-300">Quiz de fixação</span>
									</div>
								</div>
								<div class="d-flex aling-items-center h-100">
									<img class="img-fluid" src="assets/images/icons/message-square-color.png" width="32"
										height="32" alt="Mensagem">
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-6">
						<div class="bg-main-bright bg-opacity-70 p-4 rounded-1 w-100">
							<div class="d-flex justify-content-between align-items-center">
								<div class="d-flex align-itens-center gap-3">
									<div>
										<span class="count d-none d-lg-inline text-tertiary-to-primary-bt">07</span>
										<span class="count d-lg-none text-tertiary-to-primary-bt">04</span>
									</div>
									<div class="d-flex align-items-center">
										<span class=" fw-300">Simulados Práticos</span>
									</div>
								</div>
								<div class="d-flex aling-items-center h-100">
									<img class="img-fluid" src="assets/images/icons/box-color.png" width="32"
										height="32" alt="Bloco">
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-6">
						<div class="bg-main-bright bg-opacity-70 p-4 rounded-1 w-100">
							<div class="d-flex justify-content-between align-items-center">
								<div class="d-flex align-itens-center gap-3">
									<div>
										<span class="count d-none d-lg-inline text-tertiary-to-primary-bt">03</span>
										<span class="count d-lg-none text-tertiary-to-primary-bt">05</span>
									</div>
									<div class="d-flex align-items-center">
										<span class=" fw-300">Demonstração na console</span>
									</div>
								</div>
								<div class="d-flex aling-items-center h-100">
									<img class="img-fluid" src="assets/images/icons/monitor-color.png" width="32"
										height="32" alt="Monitor">
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-6">
						<div class="bg-main-bright bg-opacity-70 p-4 rounded-1 w-100">
							<div class="d-flex justify-content-between align-items-center">
								<div class="d-flex align-itens-center gap-3">
									<div>
										<span class="count d-none d-lg-inline text-tertiary-to-primary-bt">08</span>
										<span class="count d-lg-none text-tertiary-to-primary-bt">06</span>
									</div>
									<div class="d-flex align-items-center">
										<span class=" fw-300">Grupo de Networking</span>
									</div>
								</div>
								<div class="d-flex aling-items-center h-100">
									<img class="img-fluid" src="assets/images/icons/briefcase-color.png" width="32"
										height="32" alt="Maleta">
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-6">
						<div class="bg-main-bright bg-opacity-70 p-4 rounded-1 w-100">
							<div class="d-flex justify-content-between align-items-center">
								<div class="d-flex align-itens-center gap-3">
									<div>
										<span class="count d-none d-lg-inline text-tertiary-to-primary-bt">04</span>
										<span class="count d-lg-none text-tertiary-to-primary-bt">07</span>
									</div>
									<div class="d-flex align-items-center">
										<span class=" fw-300">Laboratórios práticos em conta real AWS</span>
									</div>
								</div>
								<div class="d-flex aling-items-center h-100">
									<img class="img-fluid" src="assets/images/icons/command-color.png" width="32"
										height="32" alt="Comando">
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-6">
						<div class="bg-main-bright bg-opacity-70 p-4 rounded-1 w-100">
							<div class="d-flex justify-content-between align-items-center">
								<div class="d-flex align-itens-center gap-3">
									<div>
										<span class="count d-none d-lg-inline text-tertiary-to-primary-bt">09</span>
										<span class="count d-lg-none text-tertiary-to-primary-bt">08</span>
									</div>
									<div class="d-flex align-items-center">
										<span class=" fw-300">Aulas Extras Ao Vivo - Revisão</span>
									</div>
								</div>
								<div class="d-flex aling-items-center h-100">
									<img class="img-fluid" src="assets/images/icons/maximize-color.png" width="32"
										height="32" alt="Máximo">
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-6">
						<div class="bg-main-bright bg-opacity-70 p-4 rounded-1 w-100">
							<div class="d-flex justify-content-between align-items-center">
								<div class="d-flex align-itens-center gap-3">
									<div>
										<span class="count d-none d-lg-inline text-tertiary-to-primary-bt">05</span>
										<span class="count d-lg-none text-tertiary-to-primary-bt">09</span>
									</div>
									<div class="d-flex align-items-center">
										<span class=" fw-300">Desafios com cenários reais, corrigidos
											individualmente</span>
									</div>
								</div>
								<div class="d-flex aling-items-center h-100">
									<img class="img-fluid" src="assets/images/icons/flag-color.png" width="32"
										height="32" alt="Bandeira">
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-6">
						<div class="bg-main-bright bg-opacity-70 p-4 rounded-1 w-100">
							<div class="d-flex justify-content-between align-items-center gap-5">
								<div class="d-flex align-itens-center gap-3">
									<div>
										<span class="count d-none d-lg-inline text-tertiary-to-primary-bt">10</span>
										<span class="count d-lg-none text-tertiary-to-primary-bt">10</span>
									</div>
									<div class="d-flex align-items-center">
										<span class="fw-300">Quick Notes</span>
									</div>
								</div>
								<div class="d-flex aling-items-center h-100">
									<img class="img-fluid" src="assets/images/icons/clipboard-color.png" width="32"
										height="32" alt="Linkedin">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container">
			<div class="d-flex flex-column gap-4 mt-3">
				<div class="text-center">
					<span class="fs-5">Acesse a ementa completa da mentoria ADVC clicando no botão abaixo: </span>
				</div>
				<div>
					<cfa-btn-link [classes]="'w-100'"
						[href]="'https://assets-imersao.danrezende.com/download/ementa-advc-v2.pdf'"
						[target]="'_blank'">
						<span class="fw-light">Acessar Ementa</span>
					</cfa-btn-link>
				</div>
			</div>
		</div>
	</div>
	<div class="d-none d-md-block radius-bg-primary-start" style="transform: translateY(-45rem);z-index: -1;">
		<div class="content"></div>
	</div>
</div>

<div class="before" id="before" #before>
	<div class="session-mt-sm"></div>
	<div class="radius-bg-primary-end">
		<div class="content"></div>
		<div class="container">
			<div class="d-flex flex-column gap-5 text-center">
				<div>
					<span class="fs-2">Eu estava nessa situação há um <span class="text-primary-bright fw-500">tempo
							atrás:</span></span>
				</div>
				<div class="image rounded-3 overflow-hidden">
					<img src="assets/images/dan/dan-before.png" alt="" class="d-md-none h-100">
				</div>
				<div class="fs-5">
					<p>Eu sei qual é a sensação de querer crescer na carreira e mal sair do lugar, sei o que é imaginar
						trabalhar no mesmo lugar o resto da minha vida e ficar triste com aquilo. Comecei na T.I como
						suporte em 2010.</p>
					<p>Eu resetava senhas de usuários, formatava computadores… era o famoso “Severino”. Passei pela área
						da infraestrutura, era o típico cara que perguntava de tudo, buscava aprender tudo que podia, é
						uma característica minha ser curioso, sempre buscava aprender coisas novas…Em 2016 surgiu a
						necessidade de formar um Especialista em Cloud dentro da empresa, foi oferecido ao time
						participar de um projeto piloto de Cloud.</p>
					<p>Ninguém do time deu muita importância, aparentemente todos já estavam bem confortáveis em suas
						posições.</p>
					<p>Eu me propus a pensar diferente, saí da casinha da infra e aceitei o desafio de participar
						daquele projeto piloto, mesmo sem saber se eu conseguiria ter sucesso.</p>
					<p>E posso dizer, com convicção, que essa foi uma das melhores decisões que eu já tomei na vida.
						Adquirir conhecimento, certificações e experiência a ponto de me qualificar para trabalhar na
						maior empresa de cloud do mundo, a Amazon Web Services (AWS).</p>
				</div>
			</div>
		</div>
	</div>
</div>

<div id="why" class="why" #why>
	<div class="d-none d-md-block radius-bg-primary-start" style="transform: translateY(-45rem);">
		<div class="content"></div>
	</div>
	<div class="container">
		<div class="background">
			<div class="content-why d-none d-md-block"></div>
			<div class="content-why-mobile d-flex justify-content-center d-md-none">
				<img src="assets/images/dan/dan-aws.png" class="" alt="">
			</div>
		</div>
		<div class="d-flex flex-column gap-2 mb-5">
			<div class="text-center">
				<span class="fs-2 fw-semibold">
					<span class="text-tertiary fw-700">Dan,</span>
					porque você tá <br class="d-block d-md-none"> me contando isso?
				</span>
			</div>
			<div class="text-center">
				<span class="fs-5">
					Por que toda trajetória de sucesso exige tempo, dedicação e <br class="d-none d-md-block">
					compromisso. A Mentoria ADVC é uma jornada de 12 meses
					<br class="d-none d-md-block"> com o passo a passo para conquistar seu objetivo com AWS.
				</span>
			</div>
			<div class="d-flex justify-content-center align-items-center gap-2 text-center fs-5 mt-5">
				<span class="">Arraste para o lado</span>
				<i class="text-tertiary-to-primary-bt fa-solid fa-arrow-right"></i>
			</div>
		</div>
		<div class="container">
			<div class="carousel-cards">
				<div cfaSlider [config]="getSliderWhy()">
					<div class="why-card" slide>
						<div class="content bg-main-bright bg-opacity-70 rounded-2 p-4">
							<div class="d-flex gap-3">
								<div class>
									<div class="icon-body bg-tertiary-to-primary-bt rounded-2">
										<div class="d-flex justify-content-center align-items-center w-100 h-100">
											<div>
												<img class="image" src="assets/images/advc/content/cash-line.png"
													width="50" height="50" alt="Nota">
											</div>
										</div>
									</div>
								</div>
								<div class>
									<div class="d-flex align-items-center h-100">
										<span class="fw-300 small">
											Seja ganhar um <b>salário alto</b>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="why-card" slide>
						<div class="content bg-main-bright bg-opacity-70 rounded-3 p-4">
							<div class="d-flex gap-3">
								<div class>
									<div class="icon-body bg-tertiary-to-primary-bt rounded-2">
										<div class="d-flex justify-content-center align-items-center w-100 h-100">
											<div>
												<img class="image" src="assets/images/advc/content/medal-line.png"
													width="51" height="50" alt="Medalha">
											</div>
										</div>
									</div>
								</div>
								<div class>
									<div class="d-flex align-items-center h-100">
										<span class="fw-300 small">
											<b>Seja obter o reconhecimento</b>
											profissional que você almeja
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="why-card" slide>
						<div class="content bg-main-bright bg-opacity-70 rounded-3 p-4">
							<div class="d-flex gap-3">
								<div class>
									<div class="icon-body bg-tertiary-to-primary-bt rounded-2">
										<div class="d-flex justify-content-center align-items-center w-100 h-100">
											<div>
												<img class="image" src="assets/images/advc/content/aws.png" width="50"
													height="31" alt="AWS">
											</div>
										</div>
									</div>
								</div>
								<div class>
									<div class="d-flex align-items-center h-100">
										<span class="fw-300 small">
											<b>
												Seja para você poder trabalhar nas melhores empresas,
											</b>
											nas empresas parceiras das AWS ou até na própria AWS
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="why-card" slide>
						<div class="content bg-main-bright bg-opacity-70 rounded-3 p-4">
							<div class="d-flex gap-3">
								<div class>
									<div class="icon-body bg-tertiary-to-primary-bt rounded-2">
										<div class="d-flex justify-content-center align-items-center w-100 h-100">
											<div>
												<img class="image" src="assets/images/advc/content/cloud-windy-line.png"
													width="50" height="50" alt="Nuvem">
											</div>
										</div>
									</div>
								</div>
								<div class>
									<div class="d-flex align-items-center h-100">
										<span class="fw-300 small">
											<b>Migrar da área</b>
											que você está para
											<b>cloud computing</b>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="why-card" slide>
						<div class="content bg-main-bright bg-opacity-70 rounded-3 p-4">
							<div class="d-flex gap-3">
								<div class>
									<div class="icon-body bg-tertiary-to-primary-bt rounded-2">
										<div class="d-flex justify-content-center align-items-center w-100 h-100">
											<div>
												<img class="image"
													src="assets/images/advc/content/terminal-box-line.png" width="50"
													height="50" alt="Terminal">
											</div>
										</div>
									</div>
								</div>
								<div class>
									<div class="d-flex align-items-center h-100">
										<span class="fw-300 small">
											Seja para você pode <b>trabalhar fazendo o que gosta</b>
											nas melhores condições de trabalho
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="lie" id="lie" #lie>
	<div class="session-mt-sm"></div>
	<div class="container">
		<div class="background">
			<div class="content-lie"></div>
		</div>
		<div class="content">
			<div class="d-flex flex-column text-center">
				<div>
					<span class="fs-2">
						Eu não estou aqui para <br class="d-md-none">vender <br class="d-none d-md-block">sonhos, muito
						<br class="d-md-none"><span class="text-tertiary-to-primary-bt fw-semibold">menos uma
							mentira.</span>
					</span>
				</div>
				<div class="d-none d-md-block mt-5 fs-5">
					<p>Para você ser um bom profissional <span class="fw-semibold">vai exigir horas e horas de estudo e
							prática.</span></p>
					<p>Tudo que eu puder fazer para ajudar você a se tornar um arquiteto de valor, <br
							class="d-none d-md-block">pode contar comigo, agora vai depender de você ter ou não
						resultados.</p>
				</div>
				<div class="d-md-none mt-3 px-4">
					<p>Para você ser um bom profissional <span class="fw-semibold">vai exigir horas e horas de estudo e
							prática.</span> Tudo que eu puder fazer para ajudar você a se tornar um arquiteto de valor,
						<br class="d-none d-md-block">pode contar comigo, agora vai depender de você ter ou não
						resultados.
					</p>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="d-flex justify-content-center align-items-center gap-2 text-center fs-5 mt-5">
	<span class="">Arraste para o lado</span>
	<i class="text-tertiary-to-primary-bt fa-solid fa-arrow-right"></i>
</div>
<div class="session-mt-sm"></div>
<feature-class></feature-class>
<div class="session-mt-sm"></div>
<div class="container">
	<div class="d-flex flex-column gap-4">
		<div class="text-center">
			<span class="fs-5"><b>Clique no botão abaixo</b> e efaça sua matrícula</span>
		</div>
		<div>
			<cfa-btn-link [classes]="'w-100'" [href]="link_buy" [target]="'_blank'">
				<span class="fw-light">Quero me tornar um Arquiteto de Valor Cloud</span>
			</cfa-btn-link>
		</div>
	</div>
</div>

<div class="session-mt-sm"></div>

<div class="market" id="market" #market>
	<div class="container">
		<div class="row">
			<div class="col-12 col-xl-4">
				<div class="d-flex justify-content-center flex-column gap-4 text-center text-md-start h-100">
					<div class="d-block d-md-none">
						<img class="w-100 img-fluid" src="assets/images/advc/background/market-mobile.png" width="300"
							height="150" alt="Market">
					</div>
					<span class="d-none d-md-inline fs-0 lh-1">
						<span class="text-tertiary-to-primary-bt fw-semibold">O mercado de Cloud</span> está em
						constante crescimento
					</span>
					<span class="d-md-none fs-2 lh-1 px-4">
						<span class="text-tertiary-to-primary-bt fw-semibold">O mercado de Cloud</span> está em
						constante crescimento
					</span>
					<div class="px-4 px-md-0 fs-5">
						<p>Talvez não seja uma novidade que o mercado de TI esteja em alta e que existem dezenas de
							oportunidades para profissionais com bons currículos.</p>
						<p>No cenário de Cloud a escassez por bons profissionais ainda é maior.</p>
					</div>
				</div>
			</div>
			<div class="col-12 col-xl-8 d-none d-lg-block">
				<div class="image h-100">
					<div class="content-market"></div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="session-mt-sm"></div>

<div class="badge-home" id="badge" #badge>
	<div class="d-none d-md-block radius-bg-primary">
		<div class="content"></div>
	</div>
	<div class="px-4 px-md-0">
		<div class="container">
			<div class="border-gradient border-secondary-primary rounded-3">
				<div class="bg-white-blur bg-opacity-90 rounded-3 p-4">
					<div class="row gy-4 justify-content-center align-items-center">
						<div class="col-12 col-md-5">
							<span class="fs-3 fw-300" style="white-space: break-spaces;">
								<b>Se você não quer ser somente</b> um arquiteto comum, quer sair da estagnação e fazer
								sua carreira decolar, <span class="text-primary-bt-to-secondary-bt fw-500">eu te
									apresento:</span>
							</span>
						</div>
						<div class="col-12 col-md-5 h-100">
							<img class="max-w-100 img-fluid" src="assets/images/advc/badge/badge.png" width="370"
								height="309" alt="ADVC">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="session-mt-sm"></div>

<div class="know" id="know" #know>
	<div class="container" style="z-index: 1;">
		<div class="background">
			<div class="content-know"></div>
		</div>
	</div>
	<div class="container">
		<div class="content">
			<div class="d-flex flex-column text-center px-5 px-md-0">
				<div>
					<span class="d-none d-md-block fs-1">
						Conheça a Mentoria <br class="d-none d-md-block"><span
							class="text-tertiary-to-primary-bt fw-500">Arquiteto
							de Valor Cloud</span>
					</span>
					<span class="d-md-none fs-3">
						Conheça a Mentoria <br class="d-none d-md-block"><span
							class="text-tertiary-to-primary-bt fw-500">Arquiteto
							de Valor Cloud</span>
					</span>
				</div>
				<div class="mt-3 fs-5">
					<p>Gastei meses de trabalho para criar algo único <br class="d-none d-md-block">que favorecesse a
						experiência do aluno</p>
				</div>
				<div class="arrow-down-md-center"></div>
			</div>
		</div>
	</div>
</div>

<div class="session-mt-sm"></div>

<div class="content" id="content" #content>
	<div class="container">
		<div class="text-center px-5 px-md-0">
			<span class="d-none d-md-block fs-1">
				O Conteúdo da mentoria <br class="d-none d-md-block"><span class="text-tertiary-to-primary-bt fw-500">é
					composto por:</span>
			</span>
			<span class="d-md-none fs-3">
				O Conteúdo da mentoria <br class="d-none d-md-block"><span class="text-tertiary-to-primary-bt fw-500">é
					composto por:</span>
			</span>
		</div>
	</div>
	<div class="radius-bg-primary-end" style="z-index: -1;">
		<div class="content"></div>
	</div>
	<div class="session-mt-sm"></div>
	<div class="container">
		<div class="d-flex flex-column gap-4">
			<div class="text-center">
				<span class="d-none d-md-block fs-2">Curso teórico</span>
				<span class="d-md-none fs-4">Curso teórico</span>
			</div>
			<div class="border-gradient border-tertiary-primary-bright rounded-3">
				<img class="w-100 d-none d-md-block img-fluid" src="assets/images/advc/content/course.jpg" width="1739"
					height="627" alt="Curso">
				<img class="w-100 d-md-none img-fluid" src="assets/images/advc/content/course-sm.png" width="760"
					height="394" alt="Curso">
			</div>
			<div class="text-center fs-5">
				<p class="">São aulas gravadas que fornecem a base necessária para compreender os serviços de Cloud da
					AWS e como podemos utilizá-los para atender necessidades e prover soluções.</p>
			</div>
			<div class="arrow-down-md-center"></div>
		</div>
	</div>
	<div class="session-mt-sm"></div>
	<div class="radius-bg-primary-start" style="z-index: -1;">
		<div class="content"></div>
	</div>
	<div class="container">
		<div class="d-flex flex-column gap-4">
			<div class="text-center">
				<span class="d-none d-md-block fs-2">Simulados práticos</span>
				<span class="d-md-none fs-4">Simulados práticos</span>
			</div>
			<div class="border-gradient border-tertiary-primary-bright rounded-3">
				<img class="w-100 img-fluid d-none d-md-block" src="assets/images/advc/content/practice.png"
					width="1192" height="430" alt="Simulado">
				<img class="w-100 img-fluid d-md-none" src="assets/images/advc/content/practice-sm.png" width="760"
					height="349" alt="Simulado">
			</div>
			<div class="text-center fs-5">
				<p>Para os alunos que vão fazer Certificações da AWS, temos um sistema de simulados práticos com um
					variado banco de questões.</p>
				<p>Você vai poder se testar, antes de marcar o exame de certificação. Assim que você terminar de
					responder o Simulado você tem acesso a uma revisão do seu simulado, apontando as questões que você
					acertou, as que você errou, além de uma explicação geral sobre a questão para ajudar no seu
					aprendizado e a fixar o tipo de conteúdo que é cobrado no exame.</p>
			</div>
			<div class="arrow-down-md-center"></div>
		</div>
	</div>
	<div class="session-mt-sm"></div>
	<div class="radius-bg-primary-end" style="transform: translateY(10rem);z-index: -1;">
		<div class="content"></div>
	</div>
	<div class="container">
		<div class="d-flex flex-column gap-4">
			<div class="text-center">
				<span class="d-none d-md-block fs-2">Laboratórios práticos</span>
				<span class="d-md-none fs-4">Laboratórios práticos</span>
			</div>
			<div class="border-gradient border-tertiary-primary-bright rounded-3">
				<img class="w-100  img-fluid" src="assets/images/advc/content/lab.png" width="1192" height="430"
					alt="Laboratório">
			</div>
			<div class="text-center fs-5">
				<p>Laboratórios de prática, não é qualquer laboratório, nós fornecemos uma conta da AWS para você
					realizar o LAB.</p>
				<p>Você não precisa de cartão de crédito e nenhum cadastro adicional, no total o nosso método tem + de
					100 LABs práticos. Não tem desculpa, nós queremos que você vire um especialista de Cloud e por isso
					fornecemos todos estes laboratórios para que você possa praticar bastante.</p>
				<p>Você será exposto a uma conta da AWS real para praticar e implementar os serviços utilizando um passo
					a passo detalhado que preparamos para que você aprenda e coloque em prática o conteúdo das aulas.
				</p>
			</div>
			<div class="arrow-down-md-center"></div>
		</div>
	</div>
	<div class="session-mt-sm"></div>
	<div class="radius-bg-primary-start" style="transform: translateY(10rem);z-index: -1;">
		<div class="content"></div>
	</div>
	<div class="container">
		<div class="d-flex flex-column gap-4">
			<div class="text-center">
				<span class="d-none d-md-block fs-2">Desafios</span>
				<span class="d-md-none fs-4">Desafios</span>
			</div>
			<div class="border-gradient border-tertiary-primary-bright rounded-3">
				<img class="w-100  img-fluid" src="assets/images/advc/content/challenge.png" width="1216" height="530"
					alt="Desafio">
			</div>
			<div class="text-center fs-5">
				<p>Desafios técnicos, nós preparamos cenários que simulam os projetos e problemas corriqueiros no
					dia-a-dia.</p>
				<p>No desafio, você deve propor soluções e nós da CloudFaster Academy vamos corrigir o seu desafio,
					vamos acessar a conta AWS que você utilizou, que é gratuita para todos os alunos, sem a necessidade
					de inserir cartão de crédito, revisar e apontar se você foi bem ou não na resolução do desafio e
					aprovar ou não a sua conclusão. Cada desafio será corrigido de forma individual, um por um</p>
				<p>Se o seu desafio não for concluído você recebe uma avaliação com as recomendações para repetir o
					desafio. Não tem moleza, a jornada rumo a se tornar um especialista de Cloud é séria e exige
					compromisso!</p>
			</div>
			<div class="arrow-down-md-center"></div>
		</div>
	</div>
	<div class="session-mt-sm"></div>
	<div class="radius-bg-primary-end" style="transform: translateY(10rem);z-index: -1;">
		<div class="content"></div>
	</div>
	<div class="container">
		<div class="d-flex flex-column gap-4">
			<div class="text-center">
				<span class="d-none d-md-block fs-2">Trilhas de certificação</span>
				<span class="d-md-none fs-3">Trilhas de certificação</span>
			</div>
			<div class="border-gradient border-tertiary-primary-bright rounded-3">
				<img class="w-100 img-fluid" src="assets/images/advc/content/trail.jpg" width="1739" height="627"
					alt="Trilha">
			</div>
			<div class="text-center fs-5">
				<p>Trilhas para Certificações, se o seu objetivo é tirar alguma certificação da AWS, o Método ADVC
					possui 4 trilhas que podem encurtar o seu caminho rumo a Certificação.</p>
				<p>As Trilhas são playlists de aulas selecionadas que te levam direto ao ponto, foco total na preparação
					e aprovação no exame.</p>
			</div>
			<div class="arrow-down-md-center"></div>
		</div>
	</div>
</div>

<div class="d-none d-md-block session-mt-sm"></div>

<div class="d-none d-md-block trail" id="trail" #trail>
	<div class="radius-bg-primary-end" style="z-index: -1;transform: translateY(-10rem);">
		<div class="content"></div>
	</div>
	<div class="container">
		<div class="d-flex flex-column flex-md-row justify-content-between align-items-center gap-4">
			<div>
				<span class="fs-1 fw-500">
					<span class="text-tertiary">Trilhas</span> de aprendizado disponíveis:
				</span>
			</div>
			<div class="d-flex justify-content-end align-items-center gap-2 fs-5">
				<span class="">Arraste para o lado</span>
				<i class="text-tertiary-to-primary-bt fa-solid fa-arrow-right"></i>
			</div>
		</div>
	</div>
	<div class="session-mt-sm"></div>
	<div class="container carousel-container">
		<div class="carousel-trail">
			<div class="w-100" cfaSlider [config]="getSliderTrail()">
				<div class="trail-btn" slide>
					<cfa-btn [classes]="'button rounded-4 h-100'" [variant]="'outline'" [borderWidth]="2"
						[borderRadius]="12" [theme]="'05'">
						<div class="w-100 h-100">
							<div class="content rounded-2 w-100 h-100 rounded-4 p-3">
								<div class="row justify-content-center h-100">
									<div class="col-12 col-md-10">
										<div class="d-flex flex-column justify-content-center gap-2 h-100">
											<div>
												<img class="max-w-100 img-fluid"
													src="assets/images/advc/trail/cloud-practitioner-trilha.png"
													width="190" height="185" alt="Cloud Practitioner">
											</div>
											<div class="text-center">
												<span class="fs-5">AWS Certified Cloud Practitioner</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</cfa-btn>
				</div>
				<div class="trail-btn" slide>
					<cfa-btn [classes]="'button rounded-4 h-100'" [variant]="'outline'" [borderWidth]="2"
						[borderRadius]="12" [theme]="'05'">
						<div class="w-100 h-100">
							<div class="content rounded-2 w-100 h-100 rounded-4 p-3">
								<div class="row justify-content-center h-100">
									<div class="col-12 col-md-10">
										<div class="d-flex flex-column justify-content-center gap-2 h-100">
											<div>
												<img class="max-w-100 img-fluid"
													src="assets/images/advc/trail/solutions-architect-trilha.png"
													width="190" height="185" alt="Solutions Archirect">
											</div>
											<div class="text-center">
												<span class="fs-5">AWS Certified Solutions Architect - Associate </span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</cfa-btn>
				</div>
				<div class="trail-btn" slide>
					<cfa-btn [classes]="'button rounded-4 h-100'" [variant]="'outline'" [borderWidth]="2"
						[borderRadius]="12" [theme]="'05'">
						<div class="w-100 h-100">
							<div class="content rounded-2 w-100 h-100 rounded-4 p-3">
								<div class="row justify-content-center h-100">
									<div class="col-12 col-md-10">
										<div class="d-flex flex-column justify-content-center gap-2 h-100">
											<div>
												<img class="max-w-100 img-fluid"
													src="assets/images/advc/trail/developer-associate-trilha.png"
													width="190" height="185" alt="Developer">
											</div>
											<div class="text-center">
												<span class="fs-5">AWS Certified Developer - Associate </span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</cfa-btn>
				</div>
				<div class="trail-btn" slide>
					<cfa-btn [classes]="'button rounded-4 h-100'" [variant]="'outline'" [borderWidth]="2"
						[borderRadius]="12" [theme]="'05'">
						<div class="w-100 h-100">
							<div class="content rounded-2 w-100 h-100 rounded-4 p-3">
								<div class="row justify-content-center h-100">
									<div class="col-12 col-md-10">
										<div class="d-flex flex-column justify-content-center gap-2 h-100">
											<div>
												<img class="max-w-100 img-fluid"
													src="assets/images/advc/trail/sysops-associate-trilha.png"
													width="190" height="185" alt="SysOps">
											</div>
											<div class="text-center">
												<span class="fs-5">AWS Certified SysOps Administrator – Associate</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</cfa-btn>
				</div>
				<div class="d-block d-lg-none w-50px" slide> </div>
			</div>
		</div>
	</div>
	<div class="session-mt-sm"></div>
	<div class="arrow-down-md-center mt-3"></div>
</div>

<div class="session-mt-sm"></div>

<div class="networking" id="networking" #networking>
	<div class="container">
		<div class="d-flex flex-column gap-4">
			<div class="text-center">
				<span class="d-none d-md-block fs-2 fw-semibold">Grupo de <b>Networking</b></span>
				<span class="d-md-none fs-3 fw-semibold">Grupo de <b>Networking</b></span>
			</div>
			<div>
				<img class="w-100 img-fluid" src="assets/images/advc/networking/comment-1.png" width="1216" height="530"
					alt="Comentario">
			</div>
			<div class="text-center">
				<p class="fs-5">Grupo de Networking no Discord, exclusivo para os alunos da Mentoria ADVC, com suporte a
					dúvidas diárias do Dan e da equipe da CloudFaster Academy.</p>
			</div>
		</div>
	</div>
	<div class="radius-bg-primary-start d-none d-md-block" style="z-index: -1;transform: translateY(-10rem);">
		<div class="content"></div>
	</div>
	<div class="session-mt-sm"></div>
	<div class="container">
		<div class="d-flex flex-column gap-4">
			<div class="border-gradient border-tertiary-primary-bright rounded-3 overflow-hidden d-none d-md-block">
				<img src="assets/images/advc/networking/golden-jacket.jpg" class="w-100 img-fluid" width="1290"
					height="823" alt="Jaquetas Douradas">
			</div>
			<div class="w-100 d-md-none">
				<img src="assets/images/advc/networking/golden-jacket-sm.png" class="w-100 img-fluid" width="760"
					height="485" alt="Jaquetas Douradas">
			</div>
			<div class="text-center px-5 px-md-0">
				<span class="d-none d-md-block fs-1">
					<b class="fw-700">Com quem</b> você gostaria <br class="d-none d-md-block">de estar em 2024?
				</span>
				<span class="d-md-none fs-3">
					<b class="fw-700">Com quem</b> você gostaria <br class="d-none d-md-block">de estar em 2024?
				</span>
			</div>
		</div>
	</div>
</div>

<div class="session-mt-sm"></div>

<div class="conquest" id="conquest" #conquest>
	<div class="d-none d-md-block radius-bg-primary-start" style="z-index: -1;transform: translateY(10rem);">
		<div class="content"></div>
	</div>
	<div class="container">
		<div class="d-flex flex-column gap-5">
			<div class="text-center px-3 px-md-0">
				<span class="d-none d-md-block fs-2">
					Temos alunos que <span class="text-tertiary-to-primary-bt fw-semibold">conquistaram certificações
					</span> <br class="d-none d-md-block">e mais segurança para trabalhar com cloud em <br
						class="d-none d-md-block">pouco
					tempo de mentoria
				</span>
				<span class="d-md-none fs-3">
					Temos alunos que <span class="text-tertiary-to-primary-bt fw-semibold">conquistaram certificações
					</span> <br class="d-none d-md-block">e mais segurança para trabalhar com cloud em <br
						class="d-none d-md-block">pouco
					tempo de mentoria
				</span>
			</div>
			<div class="row gx-3 gy-3">
				<div class="col-12 col-sm-5">
					<img class="max-w-100 img-fluid" src="assets/images/advc/conquest/comment-2.png" width="501"
						height="413" alt="Comentario">
				</div>
				<div class="col-12 col-sm-7">
					<div class="row gy-3 gx-3">
						<div class="col-12">
							<img class="max-w-100 img-fluid" src="assets/images/advc/conquest/comment-3.png" width="705"
								height="251" alt="Comentario">
						</div>
						<div class="col-12">
							<img class="max-w-100 img-fluid" src="assets/images/advc/conquest/comment-4.png" width="704"
								height="148" alt="Comentario">
						</div>
					</div>
				</div>
				<div class="col-12">
					<img class="max-w-100 img-fluid" src="assets/images/advc/conquest/comment-5.png" width="1220"
						height="513" alt="Comentario">
				</div>
				<div class="col-12">
					<img class="max-w-100 img-fluid" src="assets/images/advc/conquest/comment-6.png" width="1220"
						height="207" alt="Comentario">
				</div>
			</div>
		</div>
	</div>
</div>

<div class="session-mt-sm"></div>

<div class="certificate" id="certificate" #certificate>
	<div class="container">
		<div class="d-flex flex-column gap-5">
			<div class="text-center">
				<span class="d-none d-md-block fs-1">
					Ao final na mentoria você recebe <br class="d-none d-md-block">um <span
						class="text-tertiary-to-primary-bt fw-500">certificado de conclusão</span> para <br
						class="d-none d-md-block">comprovar seu conhecimento
				</span>
				<span class="d-md-none fs-3">
					Ao final na mentoria você recebe <br class="d-none d-md-block">um <span
						class="text-tertiary-to-primary-bt fw-500">certificado de conclusão</span> para <br
						class="d-none d-md-block">comprovar seu conhecimento
				</span>
			</div>
			<div>
				<div class="row justify-content-center">
					<div class="col-12 col-md-10">
						<picture>
							<source srcset="assets/images/advc/certificate/certificate.png" type="image/png">
							<img class="w-100 img-fluid" width="1500" height="1076"
								src="assets/images/advc/certificate/certificate.png" alt="Certifcado">
						</picture>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="radius-bg-primary" style="z-index: -1;">
		<div class="content"></div>
	</div>
</div>

<div class="session-mt-sm"></div>

<div class="investment" id="investment" #investment>
	<div class="container">
		<div class="d-flex flex-column gap-2 gap-md-5">
			<div class="text-center px-5 px-md-0">
				<span class="d-none d-md-block fs-0">
					Qual é o investimento para <br class="d-none d-md-block">você se tornar um <span
						class="text-secondary-to-primary-bt fw-semibold">Arquiteto de <br
							class="d-none d-md-block">Valor Cloud?</span>
					<span class="d-md-none fs-3">
						Qual é o investimento para <br class="d-none d-md-block">você se tornar um <span
							class="text-secondary-to-primary-bt fw-semibold">Arquiteto de <br
								class="d-none d-md-block">Valor Cloud?</span>
					</span>
				</span>
			</div>
			<div class="text-center fs-5">
				<p>e ter acesso ao melhor conteúdo do Brasil <br class="d-none d-md-block">para se especializar em
					cloud?</p>
			</div>
		</div>
	</div>
	<div class="container">
		<div class="row gx-4 gy-4 justify-content-center mt-5">
			<div class="col-12 col-lg-8">
				<div class="bg-main-bright bg-opacity-70 rounded p-4">
					<div class="d-flex justify-content-between gap-3 align-items-center">
						<div class="d-none d-md-flex align-items-center gap-4">
							<span>Aulas Gravadas</span>
							<span
								class="text-secondary-to-primary-bt text-decoration-line-through-secondary-primary-bright fs-2">R$5.000,00</span>
						</div>
						<div class="d-flex d-md-none align-items-center gap-4">
							<span>Aulas Gravadas</span>
							<small
								class="text-secondary-to-primary-bt text-decoration-line-through-secondary-primary-bright fs-5">R$5.000,00</small>
						</div>
						<div class="d-flex aling-items-center h-100">
							<img class="img-fluid" src="assets/images/icons/video-color.png" width="32" height="32"
								alt="Video">
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-8">
				<div class="bg-main-bright bg-opacity-70 rounded p-4">
					<div class="d-flex justify-content-between gap-3 align-items-center">
						<div class="d-none d-md-flex align-items-center gap-4">
							<span>Quiz</span>
							<span
								class="text-secondary-to-primary-bt text-decoration-line-through-secondary-primary-bright fs-2">R$997,00</span>
						</div>
						<div class="d-flex d-md-none align-items-center gap-4">
							<span>Quiz</span>
							<small
								class="text-secondary-to-primary-bt text-decoration-line-through-secondary-primary-bright fs-5">R$997,00</small>
						</div>
						<div class="d-flex aling-items-center h-100">
							<img class="img-fluid" src="assets/images/icons/message-square-color.png" width="32"
								height="32" alt="Mensagem">
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-8">
				<div class="bg-main-bright bg-opacity-70 rounded p-4">
					<div class="d-flex justify-content-between gap-3 align-items-center">
						<div class="d-none d-md-flex align-items-center gap-4">
							<span>+ de 100 Laboratórios</span>
							<span
								class="text-secondary-to-primary-bt text-decoration-line-through-secondary-primary-bright fs-2">R$2.997,00</span>
						</div>
						<div class="d-flex d-md-none align-items-center gap-4">
							<span>+ de 100 Laboratórios</span>
							<small
								class="text-secondary-to-primary-bt text-decoration-line-through-secondary-primary-bright fs-5">R$2.997,00</small>
						</div>
						<div class="d-flex aling-items-center h-100">
							<img class="img-fluid" src="assets/images/icons/command-color.png" width="32" height="32"
								alt="Comando">
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-8">
				<div class="bg-main-bright bg-opacity-70 rounded p-4">
					<div class="d-flex justify-content-between gap-3 align-items-center">
						<div class="d-none d-md-flex align-items-center gap-4">
							<span>Desafios</span>
							<span
								class="text-secondary-to-primary-bt text-decoration-line-through-secondary-primary-bright fs-2">R$497,00</span>
						</div>
						<div class="d-flex d-md-none align-items-center gap-4">
							<span>Desafios</span>
							<small
								class="text-secondary-to-primary-bt text-decoration-line-through-secondary-primary-bright fs-5">R$497,00</small>
						</div>
						<div class="d-flex aling-items-center h-100">
							<img class="img-fluid" src="assets/images/icons/flag-color.png" width="32" height="32"
								alt="Bandeira">
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-8">
				<div class="bg-main-bright bg-opacity-70 rounded p-4">
					<div class="d-flex justify-content-between gap-3 align-items-center">
						<div class="d-none d-md-flex align-items-center gap-4">
							<span>Trilhas para Certificações</span>
							<span
								class="text-secondary-to-primary-bt text-decoration-line-through-secondary-primary-bright fs-2">R$397,00</span>
						</div>
						<div class="d-flex d-md-none align-items-center gap-4">
							<span>Trilhas para Certificações</span>
							<small
								class="text-secondary-to-primary-bt text-decoration-line-through-secondary-primary-bright fs-5">R$397,00</small>
						</div>
						<div class="d-flex aling-items-center h-100">
							<img class="img-fluid" src="assets/images/icons/award-color.png" width="32" height="32"
								alt="Medalha">
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-8">
				<div class="bg-main-bright bg-opacity-70 rounded p-4">
					<div class="d-flex justify-content-between gap-3 align-items-center">
						<div class="d-none d-md-flex align-items-center gap-4">
							<span>Simulados Práticos</span>
							<span
								class="text-secondary-to-primary-bt text-decoration-line-through-secondary-primary-bright fs-2">R$2.997,00</span>
						</div>
						<div class="d-flex d-md-none align-items-center gap-4">
							<small>Simulados Práticos</small>
							<span
								class="text-secondary-to-primary-bt text-decoration-line-through-secondary-primary-bright fs-5">R$2.997,00</span>
						</div>
						<div class="d-flex aling-items-center h-100">
							<img class="img-fluid" src="assets/images/icons/box-color.png" width="32" height="32"
								alt="Bloco">
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-8">
				<div class="bg-main-bright bg-opacity-70 rounded p-4">
					<div class="d-flex justify-content-between gap-3 align-items-center">
						<div class="d-none d-md-flex align-items-center gap-4">
							<span>Grupo de Networking</span>
							<span
								class="text-secondary-to-primary-bt text-decoration-line-through-secondary-primary-bright fs-2">R$297,00</span>
						</div>
						<div class="d-flex d-md-none align-items-center gap-4">
							<small>Grupo de Networking</small>
							<span
								class="text-secondary-to-primary-bt text-decoration-line-through-secondary-primary-bright fs-5">R$297,00</span>
						</div>
						<div class="d-flex aling-items-center h-100">
							<img class="img-fluid" src="assets/images/icons/briefcase-color.png" width="32" height="32"
								alt="Maleta">
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-8">
				<div class="bg-main-bright bg-opacity-70 rounded p-4">
					<div class="d-flex justify-content-between gap-3 align-items-center">
						<div class="d-none d-md-flex align-items-center gap-4">
							<span>Aulas Extras Ao Vivo - Revisão</span>
							<span
								class="text-secondary-to-primary-bt text-decoration-line-through-secondary-primary-bright fs-2">R$997,00</span>
						</div>
						<div class="d-flex d-md-none align-items-center gap-4">
							<small>Aulas Extras Ao Vivo - Revisão</small>
							<span
								class="text-secondary-to-primary-bt text-decoration-line-through-secondary-primary-bright fs-5">R$997,00</span>
						</div>
						<div class="d-flex aling-items-center h-100">
							<img class="img-fluid" src="assets/images/icons/maximize-color.png" width="32" height="32"
								alt="Maximo">
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-8">
				<div class="bg-main-bright bg-opacity-70 rounded p-4">
					<div class="d-flex justify-content-between gap-3 align-items-center">
						<div class="d-none d-md-flex align-items-center gap-4">
							<small>Quick Notes</small>
							<span
								class="text-secondary-to-primary-bt text-decoration-line-through-secondary-primary-bright fs-2">R$497,00</span>
						</div>
						<div class="d-flex d-md-none align-items-center gap-4">
							<small>
								<small>Quick Notes</small>
							</small>
							<span
								class="text-secondary-to-primary-bt text-decoration-line-through-secondary-primary-bright fs-5">R$497,00</span>
						</div>
						<div class="d-flex aling-items-center h-100">
							<img class="img-fluid" src="assets/images/icons/clipboard-color.png" width="32" height="32"
								alt="Linkedin">
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-8">
				<div class="bg-main-bright bg-opacity-70 rounded p-4">
					<div class="d-flex justify-content-between gap-3 align-items-center">
						<div class="d-none d-md-flex align-items-center gap-4">
							<span>Plataforma própria</span>
							<span class="text-secondary-to-primary-bt fs-2">Valor inestimável</span>
						</div>
						<div class="d-flex d-md-none align-items-center gap-4 ">
							<small>Plataforma própria</small>
							<span class="text-secondary-to-primary-bt fs-5">Valor inestimável</span>
						</div>
						<div class="d-flex aling-items-center h-100">
							<img class="img-fluid" src="assets/images/icons/monitor-color.png" width="32" height="32"
								alt="Monitor">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="radius-bg-primary-start" style="z-index: -1;transform: translateY(-20rem);">
		<div class="content"></div>
	</div>
	<div class="session-mt-sm"></div>
	<div class="container">
		<div class="d-flex flex-column gap-5">
			<div class="text-center">
				<p class="d-none d-md-inline fs-2 pb-3">Esses valores acima são o que eu poderia <br
						class="d-none d-md-block">cobrar separadamente por tudo o que você <br
						class="d-none d-md-block">vai receber dentro da <span
						class="text-tertiary-to-primary-bt fw-semibold">Mentoria ADVC</span>.</p>
				<p class="d-md-none fs-3">Esses valores acima são o que eu poderia <br class="d-none d-md-block">cobrar
					separadamente por tudo o que você <br class="d-none d-md-block">vai receber dentro da <span
						class="text-tertiary-to-primary-bt fw-semibold">Mentoria ADVC</span>.</p>
				<p class="fs-5">Mas <b>não é esse valor</b> que você vai precisar investir hoje</p>
			</div>
			<div class="px-3 px-md-0">
				<div class="row justify-content-center gx-5 gy-5">
					<div class="col-12 col-lg-6">
						<div class="border-gradient border-secondary-primary-bright rounded-3">
							<div class="bg-main-bright bg-opacity-70 p-4 p-md-5 rounded-3 w-100">
								<div class="d-flex flex-column justify-content-center align-items-center gap-5">
									<div>
										<img class="img-fluid" src="assets/images/advc/investment/percent.png" width="134"
											height="134" alt="Porcentagem">
									</div>
									<div class="d-none d-md-block">
										<span class="fw-semibold fs-2 ">
											Oferta especial <br class="d-none d-md-block">de <span
												class="text-tertiary-to-primary-bt text-decoration-line-through-secondary-primary-bright">R$14.179,00</span>
										</span>
									</div>
									<div class="d-md-none text-center">
										<span class="fw-semibold fs-3 ">
											Oferta especial <br class="d-none d-md-block">de <br><span
												class="text-tertiary-to-primary-bt text-decoration-line-through-secondary-primary-bright">R$14.179,00</span>
										</span>
									</div>
									<div class="d-flex flex-column gap-1 text-center">
										<span class="fs-5">Por apenas 12x de:</span>
										<span class="d-none d-md-inline fs-0 text-tertiary-to-primary-bt fw-500">12x de
											R$ 300,90</span>
										<span class="d-md-nonefs-1 text-tertiary-to-primary-bt fw-500">12x de R$
											300,90</span>
										<span class="fs-5">OU R$2.997,00 <b class="fw-700">À VISTA</b></span>
									</div>
									<div class="d-flex flex-column gap-3">
										<div>
											<span class="d-none d-md-inline">Clique no botão abaixo e faça sua
												matrícula</span>
											<small class="d-md-none">Clique no botão abaixo e faça sua matrícula</small>
										</div>
										<div>
											<cfa-btn-link [classes]="'w-100'"
												[href]="link_buy"
												[target]="'_blank'">
												<span class="fw-light">MATRICULE-SE AGORA</span>
											</cfa-btn-link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-5">
						<div class="d-flex flex-column justify-content-between h-100">
							<div class="d-flex flex-column align-items-center gap-5">
								<div class="d-flex justify-content-start gap-4 w-100">
									<div>
										<div
											class="bg-secondary-to-primary-bt rounded-3 flex-grow-1 min-w-110px w-110px min-h-110px h-110px">
											<div
												class="d-flex justify-content-center align-items-center min-w-110px w-110px min-h-110px h-110px">
												<img class="img-fluid" src="assets/images/advc/investment/shield.png"
													width="46" height="46" alt="Seguro">
											</div>
										</div>
									</div>
									<div>
										<div class="d-flex flex-column gap-3 h-100 ">
											<span class="fs-5 fw-500">Pagamento Seguro</span>
											<small>Ambiente seguro.<br> Seus dados estão protegidos e sua compra é 100%
												segura.</small>
										</div>
									</div>
								</div>
								<div class="d-flex justify-content-start gap-4 w-100">
									<div>
										<div
											class="bg-secondary-to-primary-bt rounded-3 flex-grow-1 min-w-110px w-110px min-h-110px h-110px">
											<div
												class="d-flex justify-content-center align-items-center min-w-110px w-110px min-h-110px h-110px">
												<img class="img-fluid"
													src="assets/images/advc/investment/calendar-check-line.png" width="46"
													height="46" alt="Calendario">
											</div>
										</div>
									</div>
									<div>
										<div class="d-flex flex-column gap-2 gap-md-3 h-100 ">
											<span class="fs-5 fw-500">Acesso imediato</span>
											<small>Seu login e senha serão enviados ao seu e-mail logo após o
												processamento do pagamento.</small>
										</div>
									</div>
								</div>
								<div class="d-flex justify-content-start gap-4 w-100">
									<div>
										<div
											class="bg-secondary-to-primary-bt rounded-3 flex-grow-1 min-w-110px w-110px min-h-110px h-110px">
											<div
												class="d-flex justify-content-center align-items-center min-w-110px w-110px min-h-110px h-110px">
												<img class="img-fluid"
													src="assets/images/advc/investment/secure-payment-line.png" width="46"
													height="46" alt="Calendario">
											</div>
										</div>
									</div>
									<div>
										<div class="d-flex flex-column gap-3 h-100 ">
											<span class="fs-5 fw-500">Dois Cartões:</span>
											<small>Oferecemos a flexibilidade de <b>pagamento utilizando dois
													cartões.</b></small>
										</div>
									</div>
								</div>
							</div>
							<div class="arrow-down-md-center mt-5 mt-md-0"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="radius-bg-primary-end" style="z-index: -1;transform: translateY(-20rem);">
		<div class="content"></div>
	</div>
	<div class="session-mt-sm"></div>
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-11">
				<div class="border-gradient border-secondary-primary-bright rounded-3">
					<div class="guarantee bg-main-bright bg-opacity-70 rounded-3 px-4 px-lg-5 py-5 ">
						<div class="row justify-content-center h-100">
							<div class="col-12 col-lg-9">
								<div
									class="d-flex flex-row justify-content-center align-items-center gap-3 gap-lg-5 w-100 h-100">
									<div class="">
										<img class="d-none d-lg-block img-fluid" src="assets/images/advc/investment/selo.png"
											width="270" height="270" alt="Selo">
										<img class="d-lg-none w-100px img-fluid" src="assets/images/advc/investment/selo.png"
											width="270" height="270" alt="Selo">
									</div>
									<div class="d-none d-lg-flex flex-column gap-4">
										<span class="fs-1">
											Garantia de <span class="text-tertiary-to-primary-bt">7 Dias</span>
										</span>
										<span class="fs-5 fw-300">Até 7 dias depois do pagamento, você pode cancelar por
											qualquer motivo, e devolvemos todo o valor pago.</span>
									</div>
									<div class="d-flex d-lg-none flex-column gap-1">
										<span class="fs-5">
											Garantia de <span class="text-tertiary-to-primary-bt">7 Dias</span>
										</span>
										<small class="fw-300">Até 7 dias depois do pagamento, você pode cancelar por
											qualquer motivo, e devolvemos todo o valor pago.</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="session-mt-sm"></div>

<div class="session-mt-lg"></div>
<feature-dan></feature-dan>
<div class="session-mt-lg"></div>
<div class="session-mt-lg d-md-none"></div>
<feature-certifications></feature-certifications>
<div class="session-mt-lg"></div>
<cfa-faq-feature [faqs]="faqs"></cfa-faq-feature>
<div class="session-mt-sm"></div>
<feature-back></feature-back>
<div class="session-mt-sm"></div>
<cfa-footer></cfa-footer>