import { Injectable } from '@angular/core';
import { Banner, FAQ, Topic, TopicSection, VideoSection } from '@types';

@Injectable({
  providedIn: 'root'
})
export class SaaService {

  constructor() { }

  public saa_config = {
    name: 'Imersão Solutions Architect',
    kiwifyLink: 'https://pay.kiwify.com.br/NYTidxl',
    banner: new Banner({
      title: 'Oportunidade para você que deseja se tornar um <span class="text-tertiary-to-primary-bt fw-500">Arquiteto de Valor Cloud</span>',
      subtitle: '',
      image: 'assets/images/dan/certifications/solutions-architect-associate.png',
      background: 'assets/images/saa/background/bg-saa.png',
      kiwifyLink: 'https://pay.kiwify.com.br/NYTidxl'
    }),
    videoSection: new VideoSection({
      title: 'Imersão <span class="text-tertiary-to-primary-bt fw-500">Cloud Practitioner</span>',
      subtitle: 'As melhores dicas para você ser aprovado no exame de certificação',
      link: 'https://www.youtube.com/embed/sT2gcIc5V38',
      isLive: false,
    }),
    topicSection: new TopicSection({
      title: 'Nessa imersão falaremos <br class="d-none d-md-block">sobre os <span class="text-tertiary-to-primary-bt fw-500">seguintes assuntos</span>',
      subtitle: 'Foco total na preparação do Exame',
      topics: [
        new Topic({
          image: "assets/images/icons/cloud.png",
          text: "<b>Conceitos</b> da nuvem",
          select: false
        }),
        new Topic({
          image: "assets/images/icons/folder-shield-line.png",
          text: "<b>Segurança</b> e Conformidade",
          select: false
        }),
        new Topic({
          image: "assets/images/icons/copy.png",
          text: "<b>Serviços</b> básicos AWS",
          select: false
        }),
        new Topic({
          image: "assets/images/icons/headphone.png",
          text: "<b>Precificação</b> e Suporte",
          select: false
        }),
        new Topic({
          image: "assets/images/icons/terminal-box-line.png",
          text: "<b>Conhecendo</b> a console da AWS",
          select: false
        }),
        new Topic({
          image: "assets/images/icons/git-merge-line.png",
          text: "<b>Provisionando</b> o primeiro serviço na AWS",
          select: false
        }),
        new Topic({
          image: "assets/images/icons/medal.png",
          text: "<b>Como fazer</b> o agendamento presencial ou remoto do Exame",
          select: false
        }),
        new Topic({
          image: "assets/images/icons/anonymous.png",
          text: "<b>Dicas secretas</b> para à aprovação no Exame",
          select: false
        }),
        new Topic({
          image: "assets/images/icons/layer.png",
          text: "<b>Resolução detalhada</b> e comentada de Simulado do Exame",
          select: false
        })
      ]
    })
  }
}