export class VideoSection {
  title!: string;
  subtitle!: string;
  link!: string;
  isLive?: boolean = false;
  date?: string;
  local?: string;
  
  constructor(config: any) {
    if (config) {
      this.title = config.title !== undefined ? config.title : this.title;
      this.subtitle = config.subtitle !== undefined ? config.subtitle : this.subtitle;
      this.link = config.link !== undefined ? config.link : this.link;
      this.isLive = config.isLive !== undefined ? config.isLive : this.isLive;
      this.date = config.date !== undefined ? config.date : this.date;
      this.local = config.local !== undefined ? config.local : this.local;
    }
  }
}