import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Student } from '@app/types/students';


@Component({
  selector: 'cfa-group-students',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './group-students.component.html',
  styleUrl: './group-students.component.scss'
})
export class GroupStudentsComponent {

  @Input() students: Student[] = [
    {
      name: "Estudante 01",
      image: "assets/images/home/student/st-1.png"
    },
    {
      name: "Estudante 02",
      image: "assets/images/home/student/st-2.png"
    },
    {
      name: "Estudante 03",
      image: "assets/images/home/student/st-3.png"
    },
    {
      name: "Estudante 04",
      image: "assets/images/home/student/st-4.png"
    },
    {
      name: "Verify",
      image: "assets/images/home/student/st-verified.png"
    },
  ];

}
